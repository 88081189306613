import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { PageHeader } from '../../components/PageHeader'
import { QuoteHeadline } from '../../components/QuoteHeadline'
import { PILOT_SIGNUP_URL } from '../../data'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Background, Color, Font, getFont, mediaBreakpoint } from '../../theme'

import airteam from './assets/airteam.jpg'
import approval from './assets/approval.svg'
import drone from './assets/drone.svg'
import experience from './assets/experience.svg'
import titleBackground from './assets/title-background.jpg'

const WhatYouNeedBlock = styled.div`
	display: flex;

	@media ${mediaBreakpoint.small} {
		flex-direction: column;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		flex-direction: row;
	}

	> * {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.wyn-icon {
			height: 105px;

			margin-bottom: 38px;
		}

		.wyn-title {
			${getFont(Font.H4_MEDIUM)};
			color: ${Color.AT_BLACK_TEXT};
			margin-bottom: 18px;
		}

		.wyn-text {
			${getFont(Font.B1_REGULAR)};
			color: ${Color.AT_BLACK_1};
		}

		&:not(:first-child) {
			@media ${mediaBreakpoint.small} {
				margin-top: 90px;
			}
			@media ${mediaBreakpoint.mediumPlus} {
				margin-left: 90px;
			}
		}
	}
`

const LeftRightBlocks = styled.div`
	display: flex;

	ul {
		margin: 0;
	}

	@media ${mediaBreakpoint.small} {
		flex-direction: column;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		flex-direction: row;
	}

	> * {
		flex: 1;

		.title {
			font-family: Manrope;
			font-style: normal;
			font-weight: 600;
			font-size: 42px;
			line-height: 144%;
			letter-spacing: 0.01em;
			color: ${Color.AT_BLACK_NOT_1};
			margin-bottom: 40px;

			@media ${mediaBreakpoint.small} {
				text-align: center;
			}
		}

		.content {
			font-family: Mulish;
			font-style: normal;
			font-weight: normal;
			font-size: 23px;
			line-height: 150%;
			letter-spacing: 0.01em;

			color: ${Color.AT_BLACK_NOT_1};
		}
	}

	> *:not(:first-child) {
		@media ${mediaBreakpoint.small} {
			margin-top: 80px;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			margin-left: 80px;
		}
	}
`

const CareerView = () => {
	useScrollToTop()
	document.title = 'Piloten | AIRTEAM'
	const { t } = useTranslation()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.career.title')}
			/>
			<BlockCutter bottom="in" top="in">
				<QuoteHeadline
					color={Color.AT_BLACK_NOT_1}
					lineColor={Color.AT_GREEN_1}
				>
					{t('views.career.worldwide')}
				</QuoteHeadline>
			</BlockCutter>
			<BlockCutter
				className={Background.WHITE}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.career.sections.whatYouNeed.title'),
				}}
				top="out"
			>
				<WhatYouNeedBlock>
					<div>
						<div className="wyn-icon">
							<img alt="a drone" src={drone} />
						</div>
						<div className="wyn-title">
							{t('views.career.sections.whatYouNeed.drone')}
						</div>
						<div className="wyn-text">
							{t('views.career.sections.whatYouNeed.droneDescription')}
						</div>
					</div>
					<div>
						<div className="wyn-icon">
							<img alt="drone usage experience" src={experience} />
						</div>
						<div className="wyn-title">
							{t('views.career.sections.whatYouNeed.experience')}
						</div>
						<div className="wyn-text">
							{t('views.career.sections.whatYouNeed.experienceDescription')}
						</div>
					</div>
					<div>
						<div className="wyn-icon">
							<img alt="regulatory approval" src={approval} />
						</div>
						<div className="wyn-title">
							{t('views.career.sections.whatYouNeed.approval')}
						</div>
						<div className="wyn-text">
							{t('views.career.sections.whatYouNeed.approvalDescription')}
						</div>
					</div>
				</WhatYouNeedBlock>
			</BlockCutter>
			<img alt="Airteam drone" src={airteam} style={{ width: '100%' }} />
			<BlockCutter className={Background.WHITE} title={null}>
				<LeftRightBlocks>
					<div>
						<div className="title">
							{t('views.career.sections.yourTasks.title')}
						</div>
						<div className="content">
							<ul>
								<li>{t('views.career.sections.yourTasks.entry1')}</li>
								<li>{t('views.career.sections.yourTasks.entry2')}</li>
								<li>{t('views.career.sections.yourTasks.entry3')}</li>
							</ul>
						</div>
					</div>
					<div>
						<div className="title">
							{t('views.career.sections.whatWeOffer.title')}
						</div>
						<div className="content">
							{t('views.career.sections.whatWeOffer.description')}
						</div>
					</div>
				</LeftRightBlocks>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '80px',
					}}
				>
					<Button
						label={t('views.career.sections.whatWeOffer.cta')}
						type="yellow"
						onClick={() => {
							window.location.href = PILOT_SIGNUP_URL
						}}
					/>
				</div>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const CAREER_PATH = '/career' as const

export { CareerView }
