import React from 'react'

import { HubspotRequestSubscriptionForm } from './HubspotRequestSubscriptionForm'
import { Modal } from './Modal'

const RequestSubscriptionOrderingModal = ({
	activeTab,
	onClose,
	selectedTypeValue,
	title,
}: {
	activeTab: number
	onClose(): void
	selectedTypeValue: string
	title: string
}) => {
	return (
		<Modal title={title} {...{ onClose }}>
			<HubspotRequestSubscriptionForm
				activeTab={activeTab}
				packageOption={selectedTypeValue}
			></HubspotRequestSubscriptionForm>
		</Modal>
	)
}

export { RequestSubscriptionOrderingModal }
