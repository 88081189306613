import styled from 'styled-components'

import { Font, getFont, mediaBreakpoint } from '../theme'

export const Advantages = styled.div`
	display: grid;

	@media ${mediaBreakpoint.large} {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media ${mediaBreakpoint.mediumMinus} {
		grid-template-columns: 1fr;
	}

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media ${mediaBreakpoint.mediumMinus} {
			padding: 20px;
		}
		@media ${mediaBreakpoint.large} {
			padding: 0 70px 0 70px;
		}

		> *:not(:first-child) {
			margin-top: 15px;
		}

		.img-container {
			height: 100px;

			> img {
				max-height: 100%;
			}
		}

		h4 {
			margin: 0;
			text-align: center;

			@media ${mediaBreakpoint.mediumPlus} {
				font-family: Manrope;
				font-style: normal;
				font-weight: bold;
				font-size: 27px;
				line-height: 144%;
				letter-spacing: 0.01em;
			}
			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_H3_BOLD)}
			}
		}

		p {
			margin: 0;
			text-align: center;

			@media ${mediaBreakpoint.medium} {
				width: 40%;
			}
			@media ${mediaBreakpoint.mediumPlus} {
				font-family: Mulish;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 150%;
				text-align: center;
				letter-spacing: 0.01em;
			}
			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B2_REGULAR)}
			}
		}
	}
`
