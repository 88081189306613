import useMedia from 'use-media'

import { Breakpoint, mediaBreakpoint } from '../theme'

export function useBreakpoints() {
	const isSmall = useMedia(mediaBreakpoint.small)
	const isMedium = useMedia(mediaBreakpoint.medium)
	const isLarge = useMedia(mediaBreakpoint.large)

	return {
		breakpoint: ((): Breakpoint | null => {
			if (isSmall) return Breakpoint.SMALL
			if (isMedium) return Breakpoint.MEDIUM
			if (isLarge) return Breakpoint.LARGE

			// falling-back to mobile when loading
			return Breakpoint.SMALL
		})(),
		isLarge,
		isMedium,
		isSmall,
	}
}
