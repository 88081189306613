import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Logo } from '../../assets/Logo'
import { Color } from '../../theme'
import { HOME_PATH } from '../../views/home/View'
import { TryForFree } from '../TryForFree'

import { HeaderDesktopLinks } from './DesktopLinks'
import { HeaderLocaleSwitcher } from './LocaleSwitcher'
import { LoginIcon } from './Login'
import Banner from './Banner'

const Container = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	border-bottom: 4px solid ${Color.AT_GREEN_1};
	background-color: ${Color.AT_BLACK_1};
	color: ${Color.AT_WHITE_PRIMARY};
	z-index: 9001;

	> .container > nav {
		padding: 20px 88px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left {
			display: flex;
			align-items: center;
		}

		.right {
			display: flex;
			align-items: center;

			> :first-child {
				margin-right: 15px;
			}

			> *:not(:first-child) {
				margin-left: 12px;
			}
		}
	}
`

const LogoContainer = styled.div`
	cursor: pointer;

	margin: 0 45px 0 0;

	> * {
		height: 70px;
	}
`

const HeaderDesktop = () => {
	const history = useHistory()

	return (
		<Container>
			<Banner />
			<div className="container">
				<nav>
					<div className="left">
						<LogoContainer onClick={() => history.push(HOME_PATH)}>
							<Logo color={Color.AT_GREEN_1} />
						</LogoContainer>
						<HeaderDesktopLinks />
					</div>
					<div className="right">
						<TryForFree />
						<LoginIcon />
						<HeaderLocaleSwitcher />
					</div>
				</nav>
			</div>
		</Container>
	)
}

export { HeaderDesktop }
