import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Advantages } from '../../components/Advantages'
import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { Carousel } from '../../components/Carousel'
import { FastFusionAdvantages } from '../../components/FastFusionAdvantages'
import { ImageCard, ImageCardContainer } from '../../components/ImageCard'
import { TryForFree } from '../../components/TryForFree'
import { useBreakpoints } from '../../hooks/use-breakpoints'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { useStore } from '../../store'
import { Background, Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'
import { INDUSTRY_FACADE_PATH } from '../industry/FacadeView'
import { INDUSTRY_REAL_ESTATE_PATH } from '../industry/RealEstateView'
import { INDUSTRY_ROOFING_PATH } from '../industry/RoofingView'
import { INDUSTRY_SOLAR_PATH } from '../industry/SolarView'
import { INDUSTRY_SURVEYING_PATH } from '../industry/SurveyingView'
import { PRODUCT_PATH } from '../product/View'

import advantagesMoney from './assets/advantages/money.svg'
import advantagesStair from './assets/advantages/stair.svg'
import advantagesSupport from './assets/advantages/support.svg'
import enpal from './assets/customers/enpal.svg'
import komma5 from './assets/customers/komma5.svg'
import schoenergie from './assets/customers/schoenergie.svg'
import goldbeck from './assets/customers/goldbeck_solar.svg'
import gama from './assets/customers/gama.svg'
import einhundert from './assets/customers/einhundert.svg'
import hanebutt from './assets/customers/hanebutt.svg'
import dena from './assets/customers/dena.svg'
import sma from './assets/customers/sma.svg'
import carlisle from './assets/customers/carlisle.svg'
import bmi from './assets/customers/bmi.svg'
import tecton from './assets/customers/tecton.svg'
import ennogie from './assets/customers/ennogie.svg'
import zep_team from './assets/customers/zep_team.svg'
import sowade from './assets/customers/sowade.svg'
import mann from './assets/customers/mann.svg'
import alwitra from './assets/customers/alwitra.svg'
import abs from './assets/customers/abs.svg'
import stefan_bern from './assets/customers/stefan_bern.svg'
import wienerberger from './assets/customers/wienerberger.svg'
import bennert from './assets/customers/bennert.svg'
import schaaf from './assets/customers/schaaf.svg'
import dach_pro from './assets/customers/dach_pro.svg'
import ibeko from './assets/customers/ibeko.svg'
import jacobi from './assets/customers/jacobi-bg.png'

import djiMavicPro from './assets/dji-mavic-pro.png'
import facadeImage from './assets/industries/facade.png'
import realEstateImage from './assets/industries/real-estate.jpg'
import roofingImage from './assets/industries/roofing.jpg'
import solarImage from './assets/industries/solar.jpg'
import surveyingImage from './assets/industries/surveying.jpg'
import bauSicherheit from './assets/known-from/bau-sicherheit.png'
import bfvudi from './assets/known-from/bfvudi.png'
import dachLive from './assets/known-from/dach-live.png'
import ddh from './assets/known-from/ddh.png'
import tagesspiegel from './assets/known-from/tagesspiegel.png'
import zdf from './assets/known-from/zdf.png'
import roof from './assets/roof.png'
import { BlogEntry } from './components/BlogEntry'
import { HomeViewSection1Title } from './sections/Section1Title'
import { HomeViewSectionTestimonials } from './sections/Testimonials'
import { formatLang } from '../../utilities/format-lang'

const CUSTOMERS = [
	enpal,
	komma5,
	schoenergie,
	ibeko,
	goldbeck,
	gama,
	einhundert,
	hanebutt,
	dena,
	sma,
	carlisle,
	bmi,
	tecton,
	ennogie,
	zep_team,
	sowade,
	mann,
	alwitra,
	abs,
	stefan_bern,
	wienerberger,
	bennert,
	schaaf,
	dach_pro,
	jacobi,
]

const Customers = styled.section`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@media ${mediaBreakpoint.mediumPlus} {
		margin: 0 -88px;

		> * {
			margin: 22px;
			min-width: 155px;
			min-height: 52px;
		}
	}

	@media ${mediaBreakpoint.small} {
		margin: 0 -18px;

		> * {
			margin: 10px;
			max-width: 40vw;
			max-height: 20vw;
		}
	}
`

const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
`

const LeftRightBlocks = styled.div`
	display: grid;

	@media ${mediaBreakpoint.small} {
		grid-template-columns: 1fr;
	}

	@media ${mediaBreakpoint.mediumPlus} {
		grid-template-columns: 1fr 1fr;
	}
`

const LeftRightBlock = styled.div`
	color: ${Color.AT_BLACK_NOT_1};
	align-self: center;
	justify-self: center;

	img {
		max-width: 100%;
	}

	@media ${mediaBreakpoint.small} {
		text-align: center;

		h2 {
			${getFont(Font.MOBILE_H3_BOLD)};
		}

		p {
			${getFont(Font.MOBILE_B2_REGULAR)};
		}
	}

	@media ${mediaBreakpoint.mediumPlus} {
		h2 {
			font-family: Manrope;
			font-size: 42px;
			font-style: normal;
			font-weight: 200;
			line-height: 60px;
			letter-spacing: 0.01em;
		}

		p {
			${getFont(Font.B0_REGULAR)};
		}
	}
`

const KNOWN_FROM = [zdf, ddh, bfvudi, tagesspiegel, dachLive, bauSicherheit]

const KnownFrom = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media ${mediaBreakpoint.large} {
		justify-content: space-between;
	}

	@media ${mediaBreakpoint.mediumMinus} {
		justify-content: center;

		> * {
			margin: 5px;
		}
	}
`

const BlogEntries = styled.section`
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-column-gap: 45px;
`

const ShowMoreCustomers = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: -60px;

	> a {
		cursor: pointer;
		color: ${Color.AT_GREEN_2};
		font-family: Mulish;
		font-style: normal;
		font-weight: bold;
		font-size: 11.1882px;
		line-height: 150%;
		letter-spacing: 0.01em;
	}
`
const HomeView = () => {
	useScrollToTop()
	document.title = 'AIRTEAM'
	const { i18n, t } = useTranslation()
	const history = useHistory()
	const { isLarge, isMedium, isSmall } = useBreakpoints()
	const { blogEntries } = useStore()
	const currentLanguage = i18n.language

	const [showMoreCustomers, setShowMoreCustomers] = useState(false)

	const imageCards = [
		<ImageCard
			buttonLabel={t('buttons.readMore')}
			image={roofingImage}
			key="0"
			path={INDUSTRY_ROOFING_PATH}
			text={t('views.home.sections.servicesAdaptedToYourIndustry.roofing.text')}
			title={t(
				'views.home.sections.servicesAdaptedToYourIndustry.roofing.title',
			)}
		/>,
		<ImageCard
			buttonLabel={t('buttons.readMore')}
			image={solarImage}
			key="1"
			path={INDUSTRY_SOLAR_PATH}
			text={t('views.home.sections.servicesAdaptedToYourIndustry.solar.text')}
			title={t('views.home.sections.servicesAdaptedToYourIndustry.solar.title')}
		/>,
		<ImageCard
			buttonLabel={t('buttons.readMore')}
			image={facadeImage}
			key="2"
			path={INDUSTRY_FACADE_PATH}
			text={t('views.home.sections.servicesAdaptedToYourIndustry.facade.text')}
			title={t(
				'views.home.sections.servicesAdaptedToYourIndustry.facade.title',
			)}
		/>,
		<ImageCard
			buttonLabel={t('buttons.readMore')}
			image={surveyingImage}
			key="3"
			path={INDUSTRY_SURVEYING_PATH}
			text={t(
				'views.home.sections.servicesAdaptedToYourIndustry.surveying.text',
			)}
			title={t(
				'views.home.sections.servicesAdaptedToYourIndustry.surveying.title',
			)}
		/>,
		<ImageCard
			buttonLabel={t('buttons.readMore')}
			image={realEstateImage}
			key="4"
			path={INDUSTRY_REAL_ESTATE_PATH}
			text={t(
				'views.home.sections.servicesAdaptedToYourIndustry.realEstate.text',
			)}
			title={t(
				'views.home.sections.servicesAdaptedToYourIndustry.realEstate.title',
			)}
		/>,
	]

	const blogItems = blogEntries
		.slice(0, 3)
		.map((entry, index) => <BlogEntry key={index} {...entry} />)

	return (
		<DefaultPageLayout>
			<HomeViewSection1Title />
			<BlockCutter
				style={{
					paddingLeft: isLarge ? '8px' : '0px',
					paddingRight: isLarge ? '8px' : '0px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.servicesAdaptedToYourIndustry.title'),
				}}
			>
				{!isLarge ? (
					<Carousel items={imageCards} />
				) : (
					<ImageCardContainer>{imageCards}</ImageCardContainer>
				)}
			</BlockCutter>
			<BlockCutter
				button={
					<Button
						label={t('views.home.sections.fastFusion.button')}
						type="primary"
						onClick={() =>
							openInNewTab(
								`https://ffsolar.airteam.cloud/sample?lang=${formatLang(
									currentLanguage,
								)}`,
							)
						}
					/>
				}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.ff3d.title'),
				}}
			>
				<FastFusionAdvantages type="solar" />
			</BlockCutter>
			<BlockCutter
				bottom="in"
				style={{
					marginTop: isSmall ? '-80px' : isMedium ? '-100px' : '-60px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.ourProduct.title'),
				}}
			>
				<LeftRightBlocks>
					<LeftRightBlock>
						<h2>
							{t('views.home.sections.ourProduct.dataProcessingPlatform.title')}
						</h2>
						<p>
							{t('views.home.sections.ourProduct.dataProcessingPlatform.text')}
						</p>
					</LeftRightBlock>
					<LeftRightBlock>
						<img alt="Roof" src={roof} />
					</LeftRightBlock>
					{!isSmall && (
						<LeftRightBlock>
							<img alt="DJI Mavic Pro Drone" src={djiMavicPro} />
						</LeftRightBlock>
					)}
					<LeftRightBlock>
						<h2>{t('views.home.sections.ourProduct.fullService.title')}</h2>
						<p>{t('views.home.sections.ourProduct.fullService.text')}</p>
					</LeftRightBlock>
					{isSmall && (
						<LeftRightBlock>
							<img alt="DJI Mavic Pro Drone" src={djiMavicPro} />
						</LeftRightBlock>
					)}
				</LeftRightBlocks>
				<FlexCenter style={{ marginTop: '100px' }}>
					<Button
						label={t('buttons.discoverMore')}
						type="yellow"
						onClick={() => history.push(PRODUCT_PATH)}
					/>
				</FlexCenter>
			</BlockCutter>
			<BlockCutter
				bottom="out"
				button={<TryForFree />}
				className={Background.WHITE}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.advantages.title'),
				}}
				top="out"
			>
				<Advantages>
					<div>
						<div className="img-container">
							<img alt="Money" src={advantagesMoney} />
						</div>
						<h4 style={{ whiteSpace: 'pre-wrap' }}>
							{t('views.home.sections.advantages.money.title')}
						</h4>
						<p>{t('views.home.sections.advantages.money.text')}</p>
					</div>
					<div>
						<div className="img-container">
							<img alt="Stair" src={advantagesStair} />
						</div>
						<h4>{t('views.home.sections.advantages.stair.title')}</h4>
						<p>{t('views.home.sections.advantages.stair.text')}</p>
					</div>
					<div>
						<div className="img-container">
							<img alt="Support" src={advantagesSupport} />
						</div>
						<h4>{t('views.home.sections.advantages.support.title')}</h4>
						<p>{t('views.home.sections.advantages.support.text')}</p>
					</div>
				</Advantages>
			</BlockCutter>
			<BlockCutter
				bottom="out"
				button={<TryForFree />}
				className={Background.GRAY_DOTTED}
				style={{
					paddingLeft: isLarge ? undefined : '0px',
					paddingRight: isLarge ? undefined : '0px',
				}}
				title={{
					color: Color.AT_WHITE_PRIMARY,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.satisfiedCustomers.title'),
				}}
				top="in"
			>
				<HomeViewSectionTestimonials />
			</BlockCutter>

			<BlockCutter
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.weAreWorkingWith.title'),
				}}
				top="in"
			>
				<Customers>
					{!isSmall &&
						CUSTOMERS.map((customer, index) => (
							<img alt="customer" key={index} src={customer} />
						))}
					{isSmall &&
						CUSTOMERS.filter((_, i) => showMoreCustomers || i <= 9).map(
							(customer, index) => (
								<img alt="customer" key={index} src={customer} />
							),
						)}
				</Customers>
				{isSmall && showMoreCustomers && (
					<ShowMoreCustomers>
						{/* eslint-disable-next-line */}
						<a onClick={() => setShowMoreCustomers(false)}>
							{t('views.home.dontShowMore')}
						</a>
					</ShowMoreCustomers>
				)}
				{isSmall && !showMoreCustomers && (
					<ShowMoreCustomers>
						{/* eslint-disable-next-line */}
						<a onClick={() => setShowMoreCustomers(true)}>
							{t('views.home.showMore')}
						</a>
					</ShowMoreCustomers>
				)}
			</BlockCutter>
			<BlockCutter
				bottom="in"
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.home.sections.weAreKnownFrom.title'),
				}}
			>
				<KnownFrom>
					{KNOWN_FROM.map((src, index) => (
						<img alt="Known From Logo" key={index} {...{ src }} />
					))}
				</KnownFrom>
			</BlockCutter>
			{isSmall ? (
				<Carousel items={blogItems} />
			) : (
				<BlockCutter
					className={Background.AT_GRAY_4}
					title={{
						color: Color.AT_BLACK_NOT_1,
						lineColor: Color.AT_GREEN_1,
						text: t('views.home.sections.ourBlog.title'),
					}}
					top="out"
				>
					<BlogEntries>{blogItems}</BlogEntries>
				</BlockCutter>
			)}
		</DefaultPageLayout>
	)
}

export const HOME_PATH = '/' as const

export { HomeView }
