import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

declare global {
	interface Window {
		hbspt: {
			forms: {
				create: (config: HubSpotFormConfig) => void
			}
		}
	}
}

interface HubSpotFormConfig {
	region: string
	portalId: string
	formId: string
	target: string
}

interface HubspotRequestDronePackageFormProps {
	packageOption: string
}

const getFormId = (currentLanguage: string, packageOption: string): string => {
	const formIds: Record<string, Record<string, string>> = {
		'en-US': {
			fastFusion: '85162025-ff92-4929-81f4-71e5fc39c0d7',
			proFusion: 'c0276b62-516a-4168-8db8-fe9ff0b84984',
			maxFusion: '51f63288-81bc-4603-b495-b7064e40ccba',
			thermalFusion: '6e558637-d939-47ef-bbfe-ecdc1da6bef5',
		},
		'de-DE': {
			fastFusion: 'a1d1b039-e74d-4e2d-933d-3a043af32dd7',
			proFusion: 'df81e257-d4d5-4ff0-8c19-09730ee33d5b',
			maxFusion: '4455a8c5-9a1d-4419-8b84-05e9283ab004',
			thermalFusion: '44d33f33-6cb4-4ee8-8105-d8713a597320',
		},
	}

	let formId = ''
	if (packageOption === 'Fast Fusion') {
		formId = formIds[currentLanguage]['fastFusion']
	} else if (packageOption === 'Pro Fusion') {
		formId = formIds[currentLanguage]['proFusion']
	} else if (packageOption === 'Max Fusion') {
		formId = formIds[currentLanguage]['maxFusion']
	} else if (packageOption === 'Thermal Fusion') {
		formId = formIds[currentLanguage]['thermalFusion']
	}

	return formId
}

const HubspotRequestDronePackageForm: React.FC<
	HubspotRequestDronePackageFormProps
> = ({ packageOption }) => {
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language

	useEffect(() => {
		const script: HTMLScriptElement = document.createElement('script')
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		document.body.appendChild(script)

		console.log(currentLanguage)
		const subscriptionType =
			packageOption === 'Ultra Fusion AI' ? 'ultra' : 'max'
		const formId = getFormId(currentLanguage, packageOption)

		console.log(formId)

		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					formId: formId,
					portalId: '3797785',
					region: 'na1',
					target: '#hubspotForm',
				})
			}
		})
		return () => {
			document.body.removeChild(script)
		}
	}, [packageOption, currentLanguage])

	return <div id="hubspotForm"></div>
}

export { HubspotRequestDronePackageForm }
