import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { Heading } from '../../components/Heading'
import { PageHeader } from '../../components/PageHeader'
import { QuoteHeadline } from '../../components/QuoteHeadline'
import { JOB_POSTINGS } from '../../data'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Background, Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'

import award1 from './assets/awards/1.svg'
import award2 from './assets/awards/2.svg'
import award3 from './assets/awards/3.svg'
import award4 from './assets/awards/4.svg'
import award5 from './assets/awards/5.svg'
import award6 from './assets/awards/6.svg'
import adrianDomke from './assets/people/adrian-domke.jpg'
import arjunSharma from './assets/people/arjun-sharma.png'
import carolinWilke from './assets/people/carolin-wilke.jpg'
import danieleRomio from './assets/people/daniele-romio.jpg'
import fabianSalguero from './assets/people/fabian-salguero.jpg'
import felipeMorales from './assets/people/felipe-morales.jpg'
import fernandoBergman from './assets/people/fernando-bergman.jpg'
import frankLochau from './assets/people/frank-lochau.jpg'
import hazalCelik from './assets/people/hazal-celik.jpg'
import juleHensel from './assets/people/jule-hensel.jpg'
import karimHisham from './assets/people/karim-hisham.jpg'
import kimchiAlexan from './assets/people/kimchi-alexan.jpg'
import kuersadKati from './assets/people/kuersad-kati.jpg'
import luxiLi from './assets/people/luxi-li.png'
import matthiasKoksch from './assets/people/matthias-koksch.jpg'
import maximilianKraus from './assets/people/maximilian-kraus.jpg'
import patrickMilani from './assets/people/patrick-milani.jpg'
import sebastianFlores from './assets/people/sebastian-flores.jpg'
import thomasBuecheler from './assets/people/thomas-buecheler.jpg'
import ulfGoldmann from './assets/people/ulf-goldmann.jpg'
import valeriiTikhonov from './assets/people/valerii-tikhonov.jpg'
import titleBackground from './assets/title-background.jpg'

type TeamMember = {
	image: string
	name: string
	title: string
}

const TEAM_MEMBERS: TeamMember[] = [
	{
		image: thomasBuecheler,
		name: 'Thomas Bücheler',
		title: 'Founder & CEO',
	},
	{
		image: valeriiTikhonov,
		name: 'Valerii Tikhonov',
		title: 'CTO',
	},
	{
		image: ulfGoldmann,
		name: 'Ulf Goldmann',
		title: 'CFO',
	},
	{
		image: matthiasKoksch,
		name: 'Matthias Koksch',
		title: 'CPO',
	},
	{
		image: carolinWilke,
		name: 'Carolin Wilke',
		title: 'Operations Coordinator',
	},
	{
		image: kuersadKati,
		name: 'Kürsad Kati',
		title: 'Operations Specialist',
	},
	{
		image: maximilianKraus,
		name: 'Maximilian Kraus',
		title: 'Machine Learning Engineer',
	},
	{
		image: sebastianFlores,
		name: 'Sebastian Flores',
		title: 'Product Owner',
	},
	{
		image: hazalCelik,
		name: 'Hazal Celik',
		title: 'People & Culture Manager',
	},
	{
		image: juleHensel,
		name: 'Jule Hensel',
		title: 'Finance Specialist',
	},
	{
		image: patrickMilani,
		name: 'Patrick Milani',
		title: 'UI/UX Designer',
	},
	{
		image: danieleRomio,
		name: 'Daniele Romio',
		title: 'Process Automation Engineer',
	},
	{
		image: felipeMorales,
		name: 'Felipe Morales',
		title: 'Process Automation Engineer',
	},
	{
		image: fernandoBergman,
		name: 'Fernando Bergman',
		title: 'Process Automation Engineer',
	},
	{
		image: fabianSalguero,
		name: 'Fabian Salguero',
		title: 'Computer Vision Engineer',
	},
	{
		image: adrianDomke,
		name: 'Adrian Domke',
		title: 'Entrepreneur in Residence',
	},
	{
		image: karimHisham,
		name: 'Karim Hisham',
		title: 'Full-stack Developer',
	},
	{
		image: frankLochau,
		name: 'Frank Lochau',
		title: 'Senior Operations Manager',
	},
	{
		image: arjunSharma,
		name: 'Arjun Sharma',
		title: 'Software Developer',
	},
	{
		image: luxiLi,
		name: 'Luxi Li',
		title: 'Marketing Manager (Intern)',
	},
	{
		image: kimchiAlexan,
		name: 'Kimchi',
		title: 'Chief Happiness Officer',
	},
]

const AWARDS = [award1, award2, award3, award4, award5, award6]

const Avatars = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	> * {
		display: flex;
		flex-direction: column;
		align-items: center;

		margin-bottom: 20px;

		@media ${mediaBreakpoint.mediumMinus} {
			flex: 1 0 50%;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			flex: 1 0 33.3%;
		}

		.image {
			background-size: cover;
			background-position: center center;
			border-radius: 50%;

			@media ${mediaBreakpoint.small} {
				height: 80px;
				width: 80px;
			}

			@media ${mediaBreakpoint.medium} {
				height: 128px;
				width: 128px;
			}

			@media ${mediaBreakpoint.large} {
				height: 188px;
				width: 188px;
			}
		}

		.name {
			text-align: center;

			color: ${Color.AT_WHITE_PRIMARY};

			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_H3_BOLD)};
			}

			@media ${mediaBreakpoint.medium} {
				${getFont(Font.H4_BOLD)};
			}

			@media ${mediaBreakpoint.large} {
				${getFont(Font.H4_BOLD)};
			}
		}

		.title {
			text-align: center;

			color: ${Color.AT_GRAY_3};
			max-width: 200px;

			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B1_BOLD)};
			}

			@media ${mediaBreakpoint.medium} {
				${getFont(Font.B0_REGULAR)};
			}

			@media ${mediaBreakpoint.large} {
				${getFont(Font.B0_REGULAR)};
			}
		}

		> *:not(:first-child) {
			margin-top: 10px;
		}
	}
`

const CenteredText = styled.p`
	text-align: center;

	${getFont(Font.B0_REGULAR)};

	color: ${Color.AT_WHITE_PRIMARY};
	opacity: 0.8;

	@media ${mediaBreakpoint.small} {
		margin: 0 10px;
		${getFont(Font.MOBILE_B1_REGULAR)};
	}

	@media ${mediaBreakpoint.medium} {
		margin: 0 45px;
		${getFont(Font.B0_REGULAR)};
	}

	@media ${mediaBreakpoint.large} {
		margin: 0 85px;
		${getFont(Font.B0_REGULAR)};
	}
`

const WantToJoinText = styled.div`
	margin-top: 50px;
	font-family: Manrope;
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	line-height: 144%;

	text-align: center;
	letter-spacing: 0.01em;

	color: ${Color.AT_WHITE_PRIMARY};
	opacity: 0.8;
`

const Awards = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@media ${mediaBreakpoint.small} {
		> * {
			width: 100%;
			padding: 5% 10%;
		}
	}

	@media ${mediaBreakpoint.mediumPlus} {
		> * {
			width: 25%;
			padding: 2%;
		}
	}
`

const AboutView = () => {
	useScrollToTop()
	document.title = 'Über uns | AIRTEAM'
	const { t } = useTranslation()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				text={t('views.about.title')}
			/>
			<BlockCutter
				bottom="out"
				button={
					<Button
						label={t('views.about.wantToJoinButton')}
						type="yellow"
						onClick={() => openInNewTab(JOB_POSTINGS)}
					/>
				}
				className={Background.BLACK}
				top="in"
			>
				<QuoteHeadline
					color={Color.AT_WHITE_PRIMARY}
					lineColor={Color.AT_GREEN_1}
				>
					{t('views.about.quote')}
				</QuoteHeadline>
				<Heading
					color={Color.AT_WHITE_PRIMARY}
					lineColor={Color.AT_YELLOW}
					text={t('views.about.whoAreWeTitle')}
				/>
				<CenteredText>{t('views.about.whoAreWeDescription')}</CenteredText>
				<Heading
					color={Color.AT_WHITE_PRIMARY}
					lineColor={Color.AT_YELLOW}
					text={t('views.about.ourTeamTitle')}
				/>
				<Avatars>
					{TEAM_MEMBERS.map((member, index) => (
						<div key={index}>
							<div
								className="image"
								style={{ backgroundImage: `url(${member.image})` }}
							/>
							<div className="name">{member.name}</div>
							<div className="title">{member.title}</div>
						</div>
					))}
				</Avatars>

				<WantToJoinText>{t('views.about.wantToJoin')}</WantToJoinText>
			</BlockCutter>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.about.sections.awards.title'),
				}}
				top="in"
			>
				<Awards>
					{AWARDS.map((award, index) => (
						<img alt="Some award" key={index} src={award} />
					))}
				</Awards>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const ABOUT_PATH = '/about' as const

export { AboutView }
