import React from 'react'
import styled from 'styled-components'

import { Color } from '../theme'

import { Loader } from './Loader'
import { Modal } from './Modal'

const FormFeedbackModalStyle = styled.section`
	display: flex;
	align-items: center;
	flex-direction: column;

	white-space: pre-line;

	color: ${Color.AT_BLACK_TEXT};

	> *:not(:first-child) {
		margin-top: 20px;
	}
`

const FormFeedbackModal = ({
	isLoading = false,
	message,
	onClose,
	title,
}: {
	isLoading?: boolean
	onClose(): void
	message: string
	title: string
}) => {
	// <section> usage due to extremely limiting styling approach of BlockCutter component
	return (
		<FormFeedbackModalStyle>
			{isLoading ? (
				<Modal showCloseButton={!isLoading} {...{ onClose }}>
					<Loader />
				</Modal>
			) : (
				<Modal title={title} {...{ onClose }}>
					{message}
				</Modal>
			)}
		</FormFeedbackModalStyle>
	)
}

export { FormFeedbackModal }
