import React from 'react'
import styled from 'styled-components'

import { Color } from '../../theme'

const FieldRadioGroupStyle = styled.div`
	width: 100%;

	.dot {
		height: 20px;
		width: 20px;
		min-width: 20px; /* prevents stretching when label is too long */
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: linear-gradient(180deg, #afafaf 0%, #cecece 100%);
		box-shadow: inset 2px 4px 6px rgba(0, 0, 0, 0.07);
		margin-right: 14px;

		&__check {
			background: ${Color.AT_BLACK_NOT_1};
			box-shadow: inset 1px 1px 4px rgba(255, 255, 255, 0.4);
			height: 12px;
			width: 12px;
			border-radius: 50%;
		}
	}

	label {
		display: flex;
		align-items: start;

		border: 1px solid #8c9fa7;
		border-radius: 12px;
		padding: 16px;

		&:not(:first-child) {
			margin-top: 14px;
		}
	}

	.label-text {
		margin: 0;
	}

	.label-subtext {
		margin-top: 10px;
		margin-bottom: 0;
		color: #8c9fa7;
	}
`

const FieldRadioGroup = <T extends unknown = unknown>({
	options,
	setValue,
	value,
}: {
	options: Array<{
		label: JSX.Element | string
		value: T
		subtext?: string
	}>
	setValue: (newValue: T) => void
	value: T
}) => (
	<FieldRadioGroupStyle>
		{options.map((option, index) => (
			<label key={index} onClick={() => setValue(option.value)}>
				<div className="dot">
					{value === option.value && <div className="dot__check" />}
				</div>
				<div>
					<p className="label-text">{option.label}</p>
					<p className="label-subtext">{option.subtext}</p>
				</div>
			</label>
		))}
	</FieldRadioGroupStyle>
)

export { FieldRadioGroup }
