import { Locale } from 'locale-enum'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockCutter } from '../../../components/BlockCutter'
import { Button } from '../../../components/Button'
import { Carousel } from '../../../components/Carousel'
import { RequestDronePackageModal } from '../../../components/RequestDronePackageModal'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color } from '../../../theme'
import { openInNewTab } from '../../../utilities/open-in-new-tab'
import dronePackage from '../assets/drone-package.png'
import { PackageCardsContainer } from '../components/CardsContainer'
import { CardProps, PricingCardOld } from '../components/PricingCardOld'
import { TopContent } from '../components/TopContentGrid'

const PricingViewSection3DronePackage = () => {
	const { i18n, t } = useTranslation()
	const { isLarge } = useBreakpoints()
	const currentLanguage = i18n.language

	const packageOptions = [
		{
			label: t('views.pricing.sections.dronePackage.modal.fastFusion'),
			value: 'Fast Fusion',
		},
		{
			label: t('views.pricing.sections.dronePackage.modal.proFusion'),
			value: 'Pro Fusion',
		},
		{
			label: t('views.pricing.sections.dronePackage.modal.maxFusion'),
			value: 'Max Fusion',
		},
		{
			label: t('views.pricing.sections.dronePackage.modal.thermalFusion'),
			value: 'Thermal Fusion',
		},
	]

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedDronePackage, setSelectedDronePackage] = useState(
		packageOptions[0].value,
	)

	const subscriptionPricingCards = useCallback((): CardProps[] => {
		return [
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText'),
				buttonBuy: (
					<Button
						label={t('buttons.buyStarterset')}
						type="gray"
						onClick={() => {
							openInNewTab('https://buy.stripe.com/9AQ7u62dK1LFbyo7t1')
						}}
					/>
				),
				buttonRequest: (
					<Button
						label={t('buttons.requestOfferNow')}
						type="gray"
						onClick={() => {
							setSelectedDronePackage(packageOptions[0].value)
							setIsModalOpen(true)
						}}
					/>
				),
				color: Color.AT_BLACK_1,
				includedInPrice: [
					t('views.pricing.pricingCard.includedInPrice12'),
					t('views.pricing.pricingCard.includedInPrice13'),
					t('views.pricing.pricingCard.includedInPrice7'),
					t('views.pricing.pricingCard.includedInPrice14'),
					t('views.pricing.pricingCard.includedInPrice15'),
				],
				isNew: false,
				price: {
					amount: 1950,
				},
				subtext: t('views.pricing.pricingCard.dronePackageSubtext1'),
				title: 'Fast Fusion',
			},
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText'),
				buttonBuy: (
					<Button
						label={t('buttons.buyStarterset')}
						type="yellow"
						onClick={() => {
							openInNewTab('https://buy.stripe.com/aEUeWyf0wgGz8mcaFb')
						}}
					/>
				),
				buttonRequest: (
					<Button
						label={t('buttons.requestOfferNow')}
						type="yellow"
						onClick={() => {
							setSelectedDronePackage(packageOptions[1].value)
							setIsModalOpen(true)
						}}
					/>
				),
				color: Color.AT_YELLOW,
				includedInPrice: [
					t('views.pricing.pricingCard.includedInPrice16'),
					t('views.pricing.pricingCard.includedInPrice13'),
					t('views.pricing.pricingCard.includedInPrice44'),
					t('views.pricing.pricingCard.includedInPrice17'),
					t('views.pricing.pricingCard.includedInPrice15_5'),
				],
				isNew: false,
				price: {
					amount: 3450,
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext4'),
				title: 'Pro Fusion',
			},
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText'),
				buttonBuy: (
					<Button
						label={t('buttons.buyStarterset')}
						type="darkgreen"
						onClick={() => {
							openInNewTab('https://buy.stripe.com/4gw3dQaKgaib8mc5kS')
						}}
					/>
				),
				buttonRequest: (
					<Button
						label={t('buttons.requestOfferNow')}
						type="darkgreen"
						onClick={() => {
							setSelectedDronePackage(packageOptions[2].value)
							setIsModalOpen(true)
						}}
					/>
				),
				color: Color.AT_GREEN_2,
				includedInPrice: [
					t('views.pricing.pricingCard.includedInPrice19'),
					t('views.pricing.pricingCard.includedInPrice13'),
					t('views.pricing.pricingCard.includedInPrice22'),
					t('views.pricing.pricingCard.includedInPrice17_2'),
					t('views.pricing.pricingCard.includedInPrice21'),
				],
				isNew: false,
				price: {
					amount: 5950,
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext5'),
				title: 'Max Fusion',
			},
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText'),
				buttonBuy: (
					<Button
						label={t('buttons.buyStarterset')}
						type="purple"
						onClick={() => {
							openInNewTab('https://buy.stripe.com/dR66q23hO0HBdGweVu')
						}}
					/>
				),
				buttonRequest: (
					<Button
						label={t('buttons.requestOfferNow')}
						type="purple"
						onClick={() => {
							setSelectedDronePackage(packageOptions[3].value)
							setIsModalOpen(true)
						}}
					/>
				),
				color: Color.PURPLE,
				includedInPrice: [
					t('views.pricing.pricingCard.includedInPrice19_1'),
					t('views.pricing.pricingCard.includedInPrice13'),
					t('views.pricing.pricingCard.includedInPrice22'),
					t('views.pricing.pricingCard.includedInPrice17_2'),
					t('views.pricing.pricingCard.includedInPrice18'),
				],
				isNew: true,
				price: {
					amount: 7450,
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext6'),
				title: 'Thermal Fusion',
			},
		]
	}, [t, packageOptions])
	const pricingCards = useMemo(
		() =>
			subscriptionPricingCards().map((card) => (
				<PricingCardOld {...card} key={card.title} />
			)),
		[subscriptionPricingCards],
	)

	const downloadPriceSheetButton = useCallback(() => {
		return (
			<Button
				label={t(
					'views.pricing.sections.subscriptions.downloadPriceSheetButton',
				)}
				onClick={() =>
					openInNewTab(
						currentLanguage === Locale.de_DE
							? '/Airteam Preise - Juni 2024 - Starter Sets.pdf'
							: '/Airteam Preise - Juni 2024 - Starter Sets ENG.pdf',
					)
				}
			></Button>
		)
	}, [t])

	return (
		<BlockCutter
			bottom="in"
			button={downloadPriceSheetButton()}
			style={{
				paddingLeft: isLarge ? undefined : 0,
				paddingRight: isLarge ? undefined : 0,
			}}
			title={{
				color: Color.AT_BLACK_NOT_1,
				lineColor: Color.AT_GREEN_1,
				text: t('views.pricing.sections.dronePackage.title'),
			}}
		>
			<TopContent>
				<img alt="Drone complete package" src={dronePackage} />
				<p>{t('views.pricing.sections.dronePackage.textContent')}</p>
			</TopContent>
			{isLarge && <PackageCardsContainer>{pricingCards}</PackageCardsContainer>}
			{!isLarge && <Carousel items={pricingCards} />}
			{isModalOpen && (
				<RequestDronePackageModal
					onClose={() => setIsModalOpen(false)}
					selectedTypeValue={selectedDronePackage}
					title={t('views.pricing.sections.dronePackage.modal.title')}
				/>
			)}
		</BlockCutter>
	)
}

export { PricingViewSection3DronePackage }
