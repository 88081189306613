import styled from 'styled-components'

import { mediaBreakpoint } from '../../../theme'

const CardsContainer = styled.section`
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media ${mediaBreakpoint.large} {
		> * {
			width: 17%;
		}
	}

	@media ${mediaBreakpoint.medium} {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
`

const PackageCardsContainer = styled.section`
	width: 100%;
	display: flex;
	justify-content: space-between;

	@media ${mediaBreakpoint.large} {
		> * {
			width: 25%;
		}
	}

	@media ${mediaBreakpoint.medium} {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
`

export { CardsContainer, PackageCardsContainer }

const CardsContainerRealEstate = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	column-gap: 1rem;

	@media ${mediaBreakpoint.large} {
		> * {
			width: 25%;
		}
	}

	@media ${mediaBreakpoint.medium} {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
`

export { CardsContainerRealEstate }
