import React from 'react'
import styled from 'styled-components'

import { Modal } from './Modal'

const VideoWrapper = styled.div`
	position: relative;
	height: 0;
	// 16:9
	padding-bottom: 56.25%;
	padding-top: 25px;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`
const YoutubeVideoModal = ({
	iFrameTitle,
	isPlayList,
	onClose,
	videoOrPlayListId,
}: {
	iFrameTitle: string
	isPlayList?: boolean
	onClose: () => void
	videoOrPlayListId: string
}) => {
	return (
		<Modal onClose={onClose}>
			<VideoWrapper>
				<iframe
					allow="encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen={false}
					frameBorder="0"
					height="349"
					src={
						isPlayList
							? `https://www.youtube.com/embed/videoseries?list=${videoOrPlayListId}&autoplay=1`
							: `https://www.youtube.com/embed/${videoOrPlayListId}?autoplay=1`
					}
					title={iFrameTitle}
					width="560"
				/>
			</VideoWrapper>
		</Modal>
	)
}

YoutubeVideoModal.defaultProps = {
	isPlaylist: false,
}

export { YoutubeVideoModal }
