import React from 'react'

import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'

import { IndustryHeader } from './components/Header'
import { IndustrySection1Quote2OldNew } from './components/Section1Quote2OldNew'
import { IndustrySection3Advantages } from './components/Section3Advantages'
import { IndustrySection5Results } from './components/Section5Results'
import { IndustryType } from './types'
import { IndustrySection4Options } from './components/Section4Options'

const IndustryFacadeView = () => {
	useScrollToTop()
	document.title = 'Fassade | AIRTEAM'
	return (
		<DefaultPageLayout>
			<IndustryHeader type={IndustryType.FACADE} />
			<IndustrySection1Quote2OldNew type={IndustryType.FACADE} />
			<IndustrySection3Advantages type={IndustryType.FACADE} />
			<IndustrySection4Options />
			<IndustrySection5Results type={IndustryType.FACADE} />
		</DefaultPageLayout>
	)
}

const INDUSTRY_FACADE_PATH = '/industry/facade' as const

export { IndustryFacadeView, INDUSTRY_FACADE_PATH }
