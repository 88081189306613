import React from 'react'
import styled, { css } from 'styled-components'

import { Color, Font, getFont, mediaBreakpoint } from '../theme'

type ButtonType =
	| 'primary'
	| 'secondary'
	| 'yellow'
	| 'gray'
	| 'darkgreen'
	| 'fushia'
	| 'purple'

const ButtonStyle = styled.button<{ type2: ButtonType }>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	padding: 14px 26px;

	border: none;
	border-radius: 3px;

	text-transform: uppercase;

	${({ type2 }) => {
		switch (type2) {
			case 'primary':
				return css`
					background-color: ${Color.AT_GREEN_1};
					color: ${Color.AT_BLACK_NOT_1};

					&:hover {
						background-color: ${Color.CTA_HOVER};
					}
				`

			case 'darkgreen':
				return css`
					background-color: ${Color.AT_GREEN_2};
					color: ${Color.AT_WHITE_PRIMARY};

					&:hover {
						background-color: ${Color.AT_GREEN_1};
					}
				`

			case 'gray':
				return css`
					background-color: ${Color.AT_GRAY_4};
					color: ${Color.AT_BLACK_1};

					&:hover {
						background-color: ${Color.AT_GRAY_3};
					}
				`

			case 'secondary':
				return css`
					background-color: ${Color.AT_WHITE_PRIMARY};
					color: ${Color.AT_BLACK_NOT_1};

					&:hover {
						background-color: ${Color.AT_GRAY_4};
					}
				`

			case 'yellow':
				return css`
					background-color: ${Color.AT_YELLOW};
					color: ${Color.AT_BLACK_NOT_1};

					&:hover {
						background-color: ${Color.CTA_SECONDARY_HOVER};
					}
				`
			case 'fushia':
				return css`
					background-color: ${Color.AT_YELLOW_2};
					color: ${Color.AT_WHITE_PRIMARY};

					&:hover {
						background-color: ${Color.AT_YELLOW_2_HOVER};
					}
				`
			case 'purple':
				return css`
					background-color: ${Color.PURPLE};
					color: ${Color.AT_WHITE_PRIMARY};

					&:hover {
						background-color: ${Color.LIGHT_PURPLE};
					}
				`
		}
	}}

	:disabled {
		background: #f5f5f5;
		color: #c3c3c3;
		cursor: none;
		pointer-events: none;
	}

	@media ${mediaBreakpoint.small} {
		${getFont(Font.MOBILE_BUTTON)};
	}

	@media ${mediaBreakpoint.mediumPlus} {
		${getFont(Font.BIG_BUTTON_TEXT)};
	}
`

export type ButtonProps = {
	className?: string
	disabled?: boolean
	label: string
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	type: ButtonType
	style?: React.CSSProperties
}

const Button = ({
	className,
	disabled = false,
	label,
	onClick,
	type,
	style,
}: ButtonProps) => (
	<ButtonStyle
		role="button"
		{...{ className, onClick }}
		disabled={disabled}
		type2={type}
		style={style}
	>
		{label}
	</ButtonStyle>
)

Button.defaultProps = {
	type: 'primary',
}

export { Button }
