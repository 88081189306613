import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { Locale } from 'locale-enum'
import { initReactI18next } from 'react-i18next'

export const i18n = i18next
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)

void i18n.init({
	backend: {
		crossDomain: true,
		loadPath: `/locales/{{lng}}.json`,
	},
	cleanCode: true,
	debug: process.env.REACT_APP_ENV === 'development',
	fallbackLng: Locale.en_US,
	initImmediate: false,
	interpolation: {
		/**
		 * Disabled because React already escapes strings
		 */
		escapeValue: false,
	},
	load: 'currentOnly',
	supportedLngs: [Locale.en_US, Locale.de_DE],
})
