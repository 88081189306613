import React from 'react'

import { useBreakpoints } from '../../hooks/use-breakpoints'

import { HeaderDesktop } from './Desktop'
import { HeaderMobile } from './Mobile'

const Header = () => {
	const { isLarge } = useBreakpoints()

	return isLarge ? <HeaderDesktop /> : <HeaderMobile />
}

export { Header }
