import { useState } from 'react'

export const useBanner = () => {
	const [bannerClosed, setBannerClosed] = useState(false)

	return {
		bannerClosed,
		closeBanner: () => setBannerClosed(true),
	}
}
