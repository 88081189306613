import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import fastFusionDevices from '../..//assets/fast-fusion-devices.png'
import { useBreakpoints } from '../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { TryForFree } from '../TryForFree'

import checkmark from './assets/checkmark.svg'

const Style = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	background: linear-gradient(
		0,
		rgba(24, 33, 30, 1) 0%,
		rgba(27, 31, 30, 1) 20%,
		rgba(5, 5, 5, 1) 40%,
		rgba(8, 12, 11, 1) 70%
	);
`

const TopContent = styled.div`
	display: flex;

	@media ${mediaBreakpoint.small} {
		flex-direction: column;
		align-items: center;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		padding: 80px 60px 0 60px;
	}

	img {
		@media ${mediaBreakpoint.mediumPlus} {
			width: 70%;
		}

		@media ${mediaBreakpoint.small} {
			width: 90%;
			margin-top: 10px;
		}
		@media ${mediaBreakpoint.medium} {
			margin: 20px auto 0 auto;
			max-width: 500px;
		}
	}

	> div {
		@media ${mediaBreakpoint.mediumPlus} {
			margin-top: 50px;
			margin-left: 20px;
			width: 40%;
		}
		@media ${mediaBreakpoint.small} {
			margin: auto;
			width: 80%;
		}
		color: ${Color.AT_WHITE_PRIMARY};
	}

	.content-right {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		color: ${Color.AT_WHITE_PRIMARY};
		z-index: 1;

		&__title {
			margin-bottom: 10px;

			@media ${mediaBreakpoint.mediumMinus} {
				${getFont(Font.H4_MEDIUM)};
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.H3_BOLD)}
			}
		}

		&__main {
			margin-bottom: 30px;

			@media ${mediaBreakpoint.mediumMinus} {
				${getFont(Font.BIG_BUTTON_TEXT)};
			}
			@media ${mediaBreakpoint.medium} {
				text-align: center;
				width: 75%;
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.B0_REGULAR)}
			}
		}
	}
`

const BottomContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@media ${mediaBreakpoint.mediumMinus} {
		margin-top: 15px;
	}
	@media ${mediaBreakpoint.medium} {
		width: 100%;
		justify-content: center;
	}
	@media ${mediaBreakpoint.large} {
		margin: -6% auto 50px auto;
	}

	> * {
		display: flex;
		align-items: center;

		&:not(:first-child) {
			@media ${mediaBreakpoint.small} {
				margin-top: 30px;
			}
			@media ${mediaBreakpoint.large} {
				margin-left: 30px;
			}
		}
	}

	.text-wrapper {
		@media ${mediaBreakpoint.mediumMinus} {
			margin-left: 30px;
		}

		/* no standard font size name */
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		line-height: 150%;
		letter-spacing: 0.01em;
		color: ${Color.AT_GREEN_1};

		@media ${mediaBreakpoint.mediumMinus} {
			font-size: 18px;
		}
		@media ${mediaBreakpoint.large} {
			font-size: 21.9805px;
		}
	}

	.text {
		width: 240px;
		margin-left: 20px;
		white-space: pre-wrap;
	}

	.button {
		width: 100%;
		margin: 25px 0;

		button {
			width: 85%;
			max-width: 400px;
			margin: auto;
			${getFont(Font.BIG_BUTTON_TEXT)};

			@media ${mediaBreakpoint.mediumMinus} {
				margin-bottom: 5px;
			}
		}
	}
`

const IsThePriceRight = () => {
	const { t } = useTranslation()

	const { isLarge } = useBreakpoints()

	return (
		<Style>
			<TopContent>
				<img alt="" src={fastFusionDevices} />
				<div>
					<div className="content-right__title">
						{t('views.industry.sections.footer.headline')}
					</div>
					<div className="content-right__main">
						{t('views.industry.sections.footer.main')}
					</div>
					{isLarge && <TryForFree type="yellow" />}
				</div>
			</TopContent>
			<BottomContent>
				<div className="text-wrapper">
					<img alt="" src={checkmark} />
					<div className="text">
						{t('views.industry.sections.footer.bottom1')}
					</div>
				</div>
				<div className="text-wrapper">
					<img alt="" src={checkmark} />
					<div className="text">
						{t('views.industry.sections.footer.bottom2')}
					</div>
				</div>
				<div className="text-wrapper">
					<img alt="" src={checkmark} />
					<div className="text">
						{t('views.industry.sections.footer.bottom3')}
					</div>
				</div>
				{!isLarge && (
					<div className="button">
						<TryForFree type="yellow" />
					</div>
				)}
			</BottomContent>
		</Style>
	)
}

export { IsThePriceRight }
