import React from 'react'
import styled from 'styled-components'

import { Color } from '../theme'

const LoaderStyle = styled.div`
	border: 16px solid ${Color.AT_GRAY};
	border-top: 16px solid ${Color.AT_GREEN_1};
	border-radius: 50%;
	width: 60px;
	height: 60px;
	margin: 0 auto;
	animation: spin 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

const Loader = () => {
	return <LoaderStyle></LoaderStyle>
}

export { Loader }
