import React from 'react'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import ReactDOM from 'react-dom/client'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

// import { CookieBanner } from './components/CookieBanner'
import { Loader } from './components/Loader'
import { REGISTER_SERVICE_WORKER } from './data'
import { i18n } from './i18n'
import { Routes } from './routes'
import * as serviceWorker from './serviceWorker'
import { StoreProvider } from './store'
// import { CookieState } from './store/modules/cookies'
import {
	Background,
	Breakpoint,
	Color,
	makePattern,
	mediaBreakpoint,
} from './theme'
/* eslint-disable import/no-unassigned-import */
import '@fontsource/manrope'
import '@fontsource/muli'
import '@fontsource/mulish'
/* eslint-enable import/no-unassigned-import */
import 'normalize.css'
import '@fortawesome/fontawesome-free/css/all.css'

// eslint-disable-next-line import/no-unassigned-import
import './index.css'

const BG_COLOR = Color.BRIGHT_DOTTED_BACKGROUND_GRAY

const GlobalStyle = createGlobalStyle`
	a {
		text-decoration: none;
		color: ${Color.AT_WHITE_PRIMARY};

		:active {
			color: ${Color.AT_GREEN_1};
		}

		:hover:not(:active) {
			color: ${Color.CTA_HOVER};
		}
	}

	html, body {
		overflow-x: hidden;
	}

	body {
		position: relative;
		background-color: ${Color.AT_BLACK_NOT_1};
		width: 100%;
	}

	#root {
		/* TODO: Properly resize all elements and get rid of this property */
		@media ${mediaBreakpoint.large} {
			zoom: 70%;
		}
	}

	/* TODO: Properly resize all elements and get rid of this styling */
	iframe[title='Google Maps Location Embed for Airteam'], .g-recaptcha, #__react-alert__ {
		@media ${mediaBreakpoint.large} {
			zoom: 145%;
		}
	}

	#hs-eu-cookie-confirmation {
		display: none;
	}

	main {
		background-attachment:fixed;
		background-color: ${Color.BRIGHT_DOTTED_BACKGROUND_WHITE};

		@media ${mediaBreakpoint.small} {
			background-image: url(${makePattern(BG_COLOR, 8)});
			background-size: 8px;
		}

		@media ${mediaBreakpoint.medium} {
			background-image: url(${makePattern(BG_COLOR, 15)});
			background-size: 15px;
		}

		@media ${mediaBreakpoint.large} {
			background-image: url(${makePattern(BG_COLOR, 25, 2)});
			background-size: 25px;
		}
	}

	.container {
		margin: 0 auto;
		width: 100%;

		@media ${mediaBreakpoint.large} {
			width: ${Breakpoint.LARGE}px;
		}
	}

	.${Background.AT_GRAY_4} {
		background-color: ${Color.AT_GRAY_4};
	}

	.${Background.BLACK} {
		background-color: ${Color.AT_BLACK_1};
	}

	.${Background.GRAY_DOTTED} {
		background-color: ${Color.DARK_DOTTED_BACKGROUNDS};
		background-image: url(${makePattern(Color.AT_GRAY, 10, 1)});
		background-size: 10px 10px;
	}

	.${Background.WHITE} {
		background-color: ${Color.AT_WHITE_PRIMARY};
	}
`
const LoaderContainer = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`

ReactPixel.init('989913841902594', undefined, {
	autoConfig: true,
	debug: false,
})
// ReactPixel.revokeConsent()
// if (CookieState.ACCEPTED) {
ReactPixel.grantConsent()
TagManager.initialize({
	gtmId: 'GTM-M4PXRG8',
})
ReactPixel.pageView()
// }

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
	<React.StrictMode>
		<I18nextProvider {...{ i18n }}>
			<StoreProvider>
				<AlertProvider
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					template={AlertTemplate}
					{...{
						offset: '30px',
						position: positions.BOTTOM_CENTER,
						timeout: 5000,
						transition: transitions.SCALE,
					}}
				>
					<React.Suspense
						fallback={
							<LoaderContainer>
								<Loader />
							</LoaderContainer>
						}
					>
						<GlobalStyle />
						<Router>
							<Routes />
						</Router>
						{/* <CookieBanner /> */}
					</React.Suspense>
				</AlertProvider>
			</StoreProvider>
		</I18nextProvider>
	</React.StrictMode>,
)

if (REGISTER_SERVICE_WORKER) serviceWorker.register()
else serviceWorker.unregister()
