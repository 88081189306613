import React from 'react'
import styled from 'styled-components'

import {
	ARROW_HEIGHT_SMALL,
	ARROW_HEIGHT_MEDIUM,
	ARROW_HEIGHT_LARGE,
} from '../data'
import { Color, Font, getFont, mediaBreakpoint } from '../theme'

import { TryForFree } from './TryForFree'

const PageHeaderStyle = styled.section`
	z-index: 1;
	background-size: cover;
	background-position: center center;
	background-color: ${Color.AT_YELLOW};
	display: flex;
	align-items: center;
	flex-direction: column;

	@media ${mediaBreakpoint.small} {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% calc(100% - ${ARROW_HEIGHT_SMALL}px),
			50% 100%,
			0% calc(100% - ${ARROW_HEIGHT_SMALL}px),
			0% 0%
		);
		margin-bottom: -${ARROW_HEIGHT_SMALL}px;
		min-height: 315px;
		padding: 30px 50px 100px 50px;
	}

	@media ${mediaBreakpoint.medium} {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% calc(100% - ${ARROW_HEIGHT_MEDIUM}px),
			50% 100%,
			0% calc(100% - ${ARROW_HEIGHT_MEDIUM}px),
			0% 0%
		);
		margin-bottom: -${ARROW_HEIGHT_MEDIUM}px;
		min-height: 225px;
		padding: 50px 50px 100px 50px;
	}

	@media ${mediaBreakpoint.large} {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% calc(100% - ${ARROW_HEIGHT_LARGE}px),
			50% 100%,
			0% calc(100% - ${ARROW_HEIGHT_LARGE}px),
			0% 0%
		);
		margin-bottom: -${ARROW_HEIGHT_LARGE}px;
		min-height: 225px;
		padding: 56px 88px 153px 88px;
	}

	.page-header__button {
		display: flex;
		justify-content: center;

		margin-top: 50px;
	}
`

const PageHeadline = styled.div`
	color: ${Color.AT_WHITE_PRIMARY};

	white-space: pre-wrap;
	text-align: center;
	max-width: 1100px;
	margin: 0 auto;

	flex: 1;
	display: flex;
	align-items: center;

	@media ${mediaBreakpoint.small} {
		${getFont(Font.MOBILE_H0_BOLD)};
		word-break: break-word;
		hyphens: auto;
	}

	@media ${mediaBreakpoint.mediumPlus} {
		${getFont(Font.H2_SEMI_BOLD)};
	}
`

const PageHeader = ({
	backgroundImage,
	hideCta,
	text,
}: {
	backgroundImage: string
	hideCta: boolean
	text: string
}) => (
	<PageHeaderStyle style={{ backgroundImage: `url(${backgroundImage})` }}>
		<PageHeadline>{text}</PageHeadline>
		{!hideCta && (
			<div className="page-header__button">
				<TryForFree />
			</div>
		)}
	</PageHeaderStyle>
)

PageHeader.defaultProps = {
	hideCta: false,
}

export { PageHeader }
