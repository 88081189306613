import React from 'react'
import styled from 'styled-components'

import { Color } from '../../theme'

const FieldSingleSelectStyle = styled.div`
	position: relative;
	width: 100%;

	select {
		width: 100%;
		box-sizing: border-box;
		box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.12);
		border-radius: 3px;
		border: 0;
		padding: 10px 15px;
		background-color: #fff;
		appearance: none;

		color: ${Color.AT_BLACK_1};

		:invalid {
			color: ${Color.AT_BLACK_1};
			opacity: 0.4;
		}

		:focus {
			outline: none;
		}
	}

	svg {
		pointer-events: none;
		position: absolute;
		right: 21px;
		top: 15px;
	}
`

const FieldSingleSelect = <T extends unknown>({
	options,
	placeholder,
	required,
	setValue,
	value,
}: {
	options: Array<{
		label: string
		value: string
	}>
	placeholder: string
	required: boolean | null
	setValue: (newValue: string | null) => void
	value: string | null
}) => (
	<FieldSingleSelectStyle>
		<select
			defaultValue=""
			required={required ?? undefined}
			value={value ?? undefined}
			onChange={(e) => setValue(e.target.value)}
		>
			<option disabled hidden value="">
				{placeholder}
			</option>
			{options.map((option, index) => (
				<option key={index} value={option.value}>
					{option.label}
				</option>
			))}
		</select>
		<svg fill="#1E2525" height="10" viewBox="0 0 16 10" width="16">
			<path d="M7.0921 8.7914L0.516674 2.2066C-0.1126 1.57643 0.333718 0.5 1.22428 0.5H14.3755C15.2661 0.5 15.7124 1.57646 15.0831 2.20662L8.5073 8.79142C8.11666 9.1826 7.48273 9.18259 7.0921 8.7914Z" />
		</svg>
	</FieldSingleSelectStyle>
)

FieldSingleSelect.defaultProps = {
	required: false,
}

export { FieldSingleSelect }
