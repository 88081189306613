import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockCutter } from '../../../components/BlockCutter'
import { Carousel } from '../../../components/Carousel'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color } from '../../../theme'
import { CardsContainerRealEstate } from '../components/CardsContainer'
import {
	RealEstateCardProps,
	RealEstatePricingCard,
} from '../components/RealEstatePricingCard'
import { TopContentRealEstatePricing } from '../components/TopContentGrid'

const PricingViewRealEstate = () => {
	const { t } = useTranslation()
	const { isLarge } = useBreakpoints()

	const realEstatePricingCards = useCallback((): RealEstateCardProps[] => {
		return [
			{
				bottomText1: t('views.pricing.realEstatePricingCard.bottomtext1'),
				bottomText2: t('views.pricing.realEstatePricingCard.bottomtext2'),
				color: Color.AT_BLACK_1,
				price: {
					amount: 400,
				},
				specialWishes: [
					t('views.pricing.realEstatePricingCard.specialWishes1'),
					t('views.pricing.realEstatePricingCard.specialWishes2'),
					t('views.pricing.realEstatePricingCard.specialWishes3'),
					t('views.pricing.realEstatePricingCard.specialWishes4'),
					t('views.pricing.realEstatePricingCard.specialWishes5'),
				],
				subtext1: t('views.pricing.realEstatePricingCard.subtext1'),
				subtext2: t('views.pricing.realEstatePricingCard.subtext2'),
				title: t('views.pricing.realEstatePricingCard.title1'),
			},
			{
				bottomText1: t('views.pricing.realEstatePricingCard.bottomtext1'),
				bottomText2: t('views.pricing.realEstatePricingCard.bottomtext2'),
				color: Color.AT_BLACK_1,
				price: {
					amount: 600,
				},
				specialWishes: [
					t('views.pricing.realEstatePricingCard.specialWishes6'),
					t('views.pricing.realEstatePricingCard.specialWishes7'),
					t('views.pricing.realEstatePricingCard.specialWishes5'),
				],
				subtext1: t('views.pricing.realEstatePricingCard.subtext3'),
				subtext2: t('views.pricing.realEstatePricingCard.subtext4'),
				title: t('views.pricing.realEstatePricingCard.title2'),
			},
		]
	}, [t])
	const pricingCardsRealEstate = useMemo(
		() =>
			realEstatePricingCards().map((card) => (
				<RealEstatePricingCard {...card} key={card.title} />
			)),
		[realEstatePricingCards],
	)

	return (
		<BlockCutter
			style={{
				marginTop: isLarge ? -200 : -100,
				paddingLeft: isLarge ? undefined : 0,
				paddingRight: isLarge ? undefined : 0,
			}}
			title={{
				color: Color.AT_BLACK_NOT_1,
				lineColor: Color.AT_GREEN_1,
				text: t('views.pricing.realEstatePricingCard.title'),
			}}
		>
			<TopContentRealEstatePricing>
				<p>{t('views.pricing.realEstatePricingCard.subtitle')}</p>
			</TopContentRealEstatePricing>
			{isLarge && (
				<CardsContainerRealEstate>
					{pricingCardsRealEstate}
				</CardsContainerRealEstate>
			)}
			{!isLarge && <Carousel items={pricingCardsRealEstate} />}
		</BlockCutter>
	)
}

export { PricingViewRealEstate }
