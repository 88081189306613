import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button } from '../../../components/Button'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'
import { openInNewTab } from '../../../utilities/open-in-new-tab'
import reportMockup from '../assets/report/mockup.png'

const TheReport = styled.div`
	@media ${mediaBreakpoint.medium} {
		display: flex;
	}

	p {
		margin: auto;
		color: ${Color.AT_BLACK_1};

		@media ${mediaBreakpoint.large} {
			text-align: center;
			${getFont(Font.B0_BOLD)}
			width: 80%;
		}
		@media ${mediaBreakpoint.small} {
			text-align: center;
			${getFont(Font.MOBILE_B1_BOLD)}
			width: 80%;
		}
		@media ${mediaBreakpoint.medium} {
			text-align: left;
			${getFont(Font.MOBILE_B1_BOLD)}
		}
	}
	> div {
		display: flex;
		@media ${mediaBreakpoint.large} {
			flex-direction: row;
		}
		@media ${mediaBreakpoint.medium} {
			flex-direction: column;
		}
		@media ${mediaBreakpoint.small} {
			flex-direction: column;
			justify-content: center;

			width: 100%;
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: center;

			@media ${mediaBreakpoint.large} {
				margin-top: 50px;
			}
			@media ${mediaBreakpoint.medium} {
				margin-top: 10px;
			}
			@media ${mediaBreakpoint.small} {
				margin-top: 20px;
			}
		}
	}

	img {
		@media ${mediaBreakpoint.small} {
			width: 100%;
			height: 100%;
		}
		@media ${mediaBreakpoint.medium} {
			width: 40%;
			height: 100%;
		}
	}

	ul {
		text-align: left;
		color: #1e2525;

		@media ${mediaBreakpoint.large} {
			font-family: Manrope;
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: 40px;
			letter-spacing: 0.01em;
		}
		@media ${mediaBreakpoint.medium} {
			font-family: Manrope;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 26px;
			letter-spacing: 0.01em;

			padding-inline-start: 18px;
			margin-bottom: 65px;
		}
		@media ${mediaBreakpoint.small} {
			font-family: Manrope;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 26px;
			letter-spacing: 0.01em;

			margin-bottom: 40px;
		}
	}

	button {
		margin-bottom: 20px;
		color: ${Color.AT_BLACK_1};

		@media ${mediaBreakpoint.large} {
			width: 320px;
		}
		@media ${mediaBreakpoint.medium} {
			width: 317px;
		}
		@media ${mediaBreakpoint.small} {
			width: 100%;
		}
	}
`

const Report = () => {
	const { t } = useTranslation()
	const { isMedium } = useBreakpoints()

	return (
		<TheReport>
			{!isMedium && <p>{t('views.product.sections.theReport.content')}</p>}
			{isMedium && <img alt="Report" src={reportMockup} />}
			<div>
				{isMedium && <p>{t('views.product.sections.theReport.content')}</p>}
				{!isMedium && <img alt="Report" src={reportMockup} />}
				<div>
					<ul>
						<li>{t('views.product.sections.theReport.listItem1')}</li>
						<li>{t('views.product.sections.theReport.listItem2')}</li>
						<li>{t('views.product.sections.theReport.listItem3')}</li>
						<li>{t('views.product.sections.theReport.listItem4')}</li>
						<li>{t('views.product.sections.theReport.listItem5')}</li>
					</ul>
					<Button
						label={t(
							'views.product.sections.theReport.flatRoofSampleReportButton',
						)}
						type="yellow"
						onClick={() =>
							openInNewTab(
								'/Airteam_Muster_Vermessungsbericht_Flachdach_Pro_Dachvermessung_mit_Drohne.pdf',
							)
						}
					/>
					<Button
						label={t(
							'views.product.sections.theReport.steepRoofSampleReportButton',
						)}
						type="yellow"
						onClick={() =>
							openInNewTab(
								'/Airteam_Muster_Vermessungsbericht_Standard_Dachvermessung_mit_Drohne.pdf',
							)
						}
					/>
					<Button
						label={t(
							'views.product.sections.theReport.facadeSampleReportButton',
						)}
						type="yellow"
						onClick={() => openInNewTab('/Sample_Fassade_Projekt.pdf')}
					/>
				</div>
			</div>
		</TheReport>
	)
}

export { Report }
