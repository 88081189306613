import React from 'react'
import { useTranslation } from 'react-i18next'

import { PageHeader } from '../../../components/PageHeader'
import titleBackgroundRoofing from '../assets/title-backgrounds/roofing.jpg'
import titleBackgroundSolar from '../assets/title-backgrounds/solar.jpg'
import titleBackgroundSurveying from '../assets/title-backgrounds/surveying.jpg'
import titleBackgroundRealEstate from '../assets/title-backgrounds/real_estate.jpg'
import titleBackgroundFacade from '../assets/title-backgrounds/facade.jpg'
import { IndustryType } from '../types'

const IndustryHeader = ({ type }: { type: IndustryType }) => {
	const { t } = useTranslation()

	return (
		<>
			<PageHeader
				backgroundImage={
					{
						[IndustryType.ROOFING]: titleBackgroundRoofing,
						[IndustryType.SOLAR]: titleBackgroundSolar,
						[IndustryType.SURVEYING]: titleBackgroundSurveying,
						[IndustryType.REAL_ESTATE]: titleBackgroundRealEstate,
						[IndustryType.FACADE]: titleBackgroundFacade,
					}[type]
				}
				text={t(`views.industry.${type}.title`)}
			/>
		</>
	)
}

export { IndustryHeader }
