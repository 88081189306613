import React from 'react'
import { PARTNERSHIP_URL } from '../../data'
import { createGlobalStyle } from 'styled-components'

const PartnerGlobalStyles = createGlobalStyle`
  #root {
		zoom: 100% !important;
		overflow: hidden;
	}

`
const PartnerView = () => {
	return (
		<>
			<PartnerGlobalStyles />
			<div style={{ height: '100vh', width: '100%' }}>
				<iframe
					src={PARTNERSHIP_URL}
					style={{ border: 'none', width: '100%', height: '100%' }}
					title="Partner Page"
				/>
			</div>
		</>
	)
}

export const PARTNER_PATH = '/partner' as const

export { PartnerView }
