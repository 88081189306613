import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Locale } from 'locale-enum'

import { REFERRAL_URL_DE, REFERRAL_URL_EN } from '../../data'

const ReferralGlobalStyles = createGlobalStyle`
  #root {
		zoom: 100% !important;
		overflow: hidden;
	}

`
const ReferralView = () => {
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language

	return (
		<>
			<ReferralGlobalStyles />
			<div style={{ height: '100vh', width: '100%' }}>
				<iframe
					src={
						currentLanguage === Locale.de_DE ? REFERRAL_URL_DE : REFERRAL_URL_EN
					}
					style={{ border: 'none', width: '100%', height: '100%' }}
					title="Partner Page"
				/>
			</div>
		</>
	)
}

export const REFERRAL_PATH_DE = '/helden-des-fortschritts' as const
export const REFERRAL_PATH_EN = `/en${REFERRAL_PATH_DE}` as const

export { ReferralView }
