import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../../components/BlockCutter'
import { Button } from '../../../components/Button'
import { YoutubeVideoModal } from '../../../components/YoutubeVideoModal'
import { YoutubeVideoPlayIcon } from '../../../components/YoutubeVideoPlayIcon'
import { Color, mediaBreakpoint } from '../../../theme'
import { openInNewTab } from '../../../utilities/open-in-new-tab'
import lockbookThumbnail from '../assets/advantages/roofing/lockbook-thumbnail.svg'
import lockbook from '../assets/advantages/roofing/lockbook.svg'

const LockBook = styled.div`
	display: flex;
	flex-direction: row;

	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
		align-items: center;
	}

	.lockbook-div {
		width: 40%;
	}

	.lockbox-video-div {
		width: 60%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.lockbook-description {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 23px;
		line-height: 150%;
		letter-spacing: 0.01em;
		color: #1e2525;

		@media ${mediaBreakpoint.mediumMinus} {
			font-size: 17px;
		}
	}

	.lockbook-btn {
		width: 40%;
		margin-bottom: 30px;
		justify-content: center;
		align-items: center;

		:hover {
			cursor: pointer;
		}

		@media ${mediaBreakpoint.medium} {
			width: 225px;
			height: 64px;
		}

		@media ${mediaBreakpoint.small} {
			width: 170px;
			height: 50px;
		}
	}

	.lockbox-thumbnail {
		width: 80%;
		margin-bottom: 30px;

		@media ${mediaBreakpoint.small} {
			width: 100%;
		}
	}

	.video-description {
		font-style: normal;
		font-weight: 400;
		font-size: 23px;
		line-height: 150%;
		text-align: center;
		letter-spacing: 0.01em;

		@media ${mediaBreakpoint.mediumMinus} {
			font-size: 17px;
		}
	}

	button {
		width: 313px;
		height: 51px;
		background: #f6ae1e;
		border: 2px solid #f6ae1e;
		border-radius: 3px;
		flex: none;
		order: 0;
		flex-grow: 0;
		font-style: normal;
		font-weight: 700;
		font-size: 17px;
		line-height: 23px;
		letter-spacing: 0.05em;
		text-transform: uppercase;

		@media ${mediaBreakpoint.small} {
			width: 170px;
		}
	}

	.lockbook-paragraph {
		display: inline;
		margin: 0;
		padding: 0;
		border: none;
	}

	.link-paragraph {
		color: blue;

		:hover {
			cursor: pointer;
		}
	}

	.video-button {
		cursor: pointer;
		z-index: 999;
	}
`

const AbsLockBoxSection = () => {
	const { t } = useTranslation()
	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

	return (
		<BlockCutter
			title={{
				color: Color.AT_BLACK_1,
				lineColor: Color.AT_GREEN_1,
				text: t('views.industry.absLockBook.title'),
			}}
		>
			<LockBook>
				<div className="lockbook-div">
					<img
						alt="lockbook"
						className="lockbook-btn"
						src={lockbook}
						onClick={() => openInNewTab('https://en.info.lock-book.com/')}
					/>
					<div className="lockbook-description">
						<p className="lockbook-paragraph">
							{t('views.industry.absLockBook.content11')}
						</p>
						<p
							className="lockbook-paragraph link-paragraph"
							onClick={() => openInNewTab('https://en.info.lock-book.com/')}
						>
							{t('views.industry.absLockBook.content12')}
						</p>
						<p className="lockbook-paragraph">
							{t('views.industry.absLockBook.content13')}
						</p>
						<br />
						<br />
						<p className="lockbook-paragraph">
							{t('views.industry.absLockBook.content21')}
						</p>
						<p
							className="lockbook-paragraph link-paragraph"
							onClick={() =>
								openInNewTab('https://aktion.lock-book.com/airteam-und-draw/')
							}
						>
							{t('views.industry.absLockBook.content22')}
						</p>
						<p className="lockbook-paragraph">
							{t('views.industry.absLockBook.content23')}
						</p>
					</div>
				</div>
				<div className="lockbox-video-div">
					<p className="video-description">
						{t('views.industry.absLockBook.videoDescription')}
					</p>
					<img
						alt="lockbook-thumbnail"
						className="lockbox-thumbnail"
						src={lockbookThumbnail}
					/>
					<YoutubeVideoPlayIcon onClick={() => setIsVideoModalOpen(true)} />
					<Button
						className="video-button"
						label={t('views.industry.sections.sunnyProIntegration.button')}
						type="yellow"
						onClick={() =>
							openInNewTab(
								'/Anleitung - Airteam Daten in ABS LockBook verwenden.pdf',
							)
						}
					/>
				</div>
			</LockBook>
			{isVideoModalOpen && (
				<YoutubeVideoModal
					iFrameTitle="ABS Lock Book and Airteam Youtube iFrame"
					videoOrPlayListId="tGWxpQOjUzM"
					onClose={() => setIsVideoModalOpen(false)}
				/>
			)}
		</BlockCutter>
	)
}

export { AbsLockBoxSection }
