import React from 'react'

import { HubspotRequestDronePackageForm } from './HubspotRequestDronePackageForm'
import { Modal } from './Modal'

const RequestDronePackageModal = ({
	onClose,
	selectedTypeValue,
	title,
}: {
	onClose(): void
	selectedTypeValue: string
	title: string
}) => {
	return (
		<Modal title={title} {...{ onClose }}>
			<HubspotRequestDronePackageForm
				packageOption={selectedTypeValue}
			></HubspotRequestDronePackageForm>
		</Modal>
	)
}

export { RequestDronePackageModal }
