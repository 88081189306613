import { useEffect, useState } from 'react'

import { blogsApi } from '../../utilities/axios-api'

const URL = '/api/blogs/' as const

/* eslint-disable @typescript-eslint/naming-convention */
export type BlogEntryType = {
	/**
	 * image caption
	 */
	bild_caption: string | null
	/**
	 * image
	 */
	bild: string
	created_at: string
	/**
	 * introduction
	 */
	einleitung: string
	id: number
	paragraphs: Array<{
		/**
		 * image caption
		 */
		bild_caption: string | null
		/**
		 * image
		 */
		bild: string | null
		id: number
		text: string
		/**
		 * title
		 */
		titel: string | null
	}>
	seo_meta_data: string | null
	slug: string
	/**
	 * title
	 */
	titel: string
}
/* eslint-enable @typescript-eslint/naming-convention */

export const useBlogs = () => {
	const [isBlogEntriesLoading, setIsBlogEntriesLoading] = useState(true)
	const [blogEntries, setBlogEntries] = useState<BlogEntryType[]>([])

	useEffect(() => {
		const request = blogsApi.get<BlogEntryType[]>(URL)

		request
			.then(({ data }) => setBlogEntries(data))
			.catch((error) => console.error('failed to load blogs', error))
			.finally(() => setIsBlogEntriesLoading(false))
	}, [])

	return {
		blogEntries,
		isBlogEntriesLoading,
		async refreshBlogEntries() {
			setBlogEntries((await blogsApi.get<BlogEntryType[]>(URL)).data)
		},
	}
}
