import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Reaptcha from 'reaptcha'

import { useFieldValidators } from '../../hooks/use-field-validators'
import { webappApi } from '../../utilities/axios-api'

import { RegistrationOptionsResponse } from './types'

enum Operator {
	YES = 'YES',
	NO = 'NO',
	NULL = 'NULL',
}

type Signup = {
	email: string
	/**
	 * company
	 */
	firma: string
	industry: string
	language: string
	/**
	 * last name
	 */
	nachname: string
	recaptcha: string
	/**
	 * first name
	 */
	vorname: string
	phone: string
	clientHasDrone: Operator
	subscribeToNewsletter: boolean
}

export enum RegistrationServerResponseErrorCodes {
	EMAIL_ALREADY_EXISTS = 419,
	COMPANY_ALREADY_EXISTS = 420,
}

export const useRegistrationForm = () => {
	const { i18n } = useTranslation()

	const [email, setEmail] = useState<string | null>(null)
	const [firstName, setFirstName] = useState<string | null>(null)
	const [googleRecaptchaResponse, setGoogleRecaptchaResponse] = useState<
		string | null
	>(null)
	const [industry, setIndustry] = useState<string | null>(null)
	const [industryOptions, setIndustryOptions] = useState<
		Array<{
			label: string
			value: string
		}>
	>([])
	const [lastName, setLastName] = useState<string | null>(null)
	const [phone, setPhone] = useState<string | null>(null)
	const [company, setCompany] = useState<string | null>(null)
	const [hasDrone, setHasDrone] = useState<Operator>(Operator.NULL)
	const [subscribeToNewsletter, setSubscribeToNewsletter] =
		useState<boolean>(false)
	const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
	const reaptchaRef = useRef<Reaptcha>(null)

	const { validateE164PhoneNumber, validateEmail } = useFieldValidators()

	useEffect(() => {
		async function fetchIndustryOptions() {
			const res = await webappApi.options<RegistrationOptionsResponse>(
				'/api/clients-signups',
			)
			setIndustryOptions(
				res.data.industryOptions.map((industryOption) => {
					return {
						label: industryOption[1],
						value: industryOption[0],
					}
				}),
			)
		}

		void fetchIndustryOptions()
	}, [i18n.language])

	const resetForm = useCallback(async () => {
		setCompany(null)
		setEmail(null)
		setFirstName(null)
		setGoogleRecaptchaResponse(null)
		setIndustry(null)
		setLastName(null)
		setPhone(null)
		setHasDrone(Operator.NULL)
		setSubscribeToNewsletter(false)
		setAgreeToTerms(false)
		if (reaptchaRef.current) await reaptchaRef.current.reset()
	}, [
		setAgreeToTerms,
		setCompany,
		setEmail,
		setFirstName,
		setGoogleRecaptchaResponse,
		setIndustry,
		setLastName,
		setPhone,
		setHasDrone,
		setSubscribeToNewsletter,
		reaptchaRef,
	])

	const isFormValid = useCallback(() => {
		return (
			email !== null &&
			validateEmail(email) &&
			firstName !== null &&
			googleRecaptchaResponse !== null &&
			industry !== null &&
			lastName !== null &&
			company !== null &&
			phone !== null &&
			hasDrone !== Operator.NULL &&
			validateE164PhoneNumber(phone) &&
			agreeToTerms
		)
	}, [
		agreeToTerms,
		email,
		firstName,
		googleRecaptchaResponse,
		industry,
		lastName,
		company,
		phone,
		hasDrone,
		validateEmail,
		validateE164PhoneNumber,
	])

	const submit = useCallback(async () => {
		if (
			email === null ||
			firstName === null ||
			googleRecaptchaResponse === null ||
			industry === null ||
			lastName === null ||
			company === null ||
			phone === null ||
			hasDrone === Operator.NULL ||
			!agreeToTerms
		)
			throw new Error('form not filled')

		const data: Signup = {
			email,
			firma: company,
			industry,
			language: i18n.language,
			nachname: lastName,
			phone,
			recaptcha: googleRecaptchaResponse,
			subscribeToNewsletter,
			clientHasDrone: hasDrone,
			vorname: firstName,
		}

		await webappApi.post('/api/clients-signups/', data)
	}, [
		agreeToTerms,
		company,
		email,
		firstName,
		googleRecaptchaResponse,
		industry,
		lastName,
		phone,
		hasDrone,
		subscribeToNewsletter,
		i18n.language,
	])

	return {
		agreeToTerms,
		company,
		email,
		firstName,
		googleRecaptchaResponse,
		industry,
		industryOptions,
		isFormValid,
		lastName,
		phone,
		reaptchaRef,
		hasDrone,
		resetForm,
		setAgreeToTerms,
		setCompany,
		setEmail,
		setFirstName,
		setGoogleRecaptchaResponse,
		setIndustry,
		setLastName,
		setPhone,
		setSubscribeToNewsletter,
		setHasDrone,
		submit,
		subscribeToNewsletter,
		validateE164PhoneNumber,
		validateEmail,
	}
}
