import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Carousel } from '../../../components/Carousel'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import alexanderAdam from '../assets/testimonials/alexander-adam.jpg'
import andreRosinska from '../assets/testimonials/andre-rosinska.jpg'
import christianKautz from '../assets/testimonials/christian-kautz.png'
import danielMoreno from '../assets/testimonials/daniel-moreno.jpg'
import dirkMarien from '../assets/testimonials/dirk-marien.jpg'
import felixWisbrich from '../assets/testimonials/felix-weisbrich.jpeg'
import heikoSowade from '../assets/testimonials/heiko-sowade.jpg'
import jochenSchafer from '../assets/testimonials/jochen-schafer.jpeg'
import marioCuk from '../assets/testimonials/mario-cuk.jpeg'
import schaafHanna from '../assets/testimonials/schaaf-hanna.jpg'
import thiloEllerkamp from '../assets/testimonials/thilo-ellerkamp.jpg'
import yannikZimmermann from '../assets/testimonials/yannik-zimmermann.jpg'
import { TestimonialCard } from '../components/TestimonialCard'

const TestimonialCardsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	> * {
		width: 17%;
		margin: 20px auto;
	}
`

const HomeViewSectionTestimonials = () => {
	const { t } = useTranslation()
	const { isLarge } = useBreakpoints()

	const testimonials = [
		{
			image: felixWisbrich,
			name: 'Felix Weisbrich',
			rating: 5,
			text: t('views.home.sections.testimonials.felixWeisbrich.text'),
			title: t('views.home.sections.testimonials.felixWeisbrich.title'),
		},
		{
			image: alexanderAdam,
			name: 'Alexander Adam',
			rating: 5,
			text: t('views.home.sections.testimonials.alexanderAdam.text'),
			title: t('views.home.sections.testimonials.alexanderAdam.title'),
		},
		{
			image: thiloEllerkamp,
			name: 'Thilo Ellerkamp',
			rating: 5,
			text: t('views.home.sections.testimonials.thiloEllerkamp.text'),
			title: t('views.home.sections.testimonials.thiloEllerkamp.title'),
		},
		{
			image: schaafHanna,
			name: 'Hanna',
			rating: 5,
			text: t('views.home.sections.testimonials.schaafHanna.text'),
			title: t('views.home.sections.testimonials.schaafHanna.title'),
		},
		{
			image: yannikZimmermann,
			name: 'Yannik Zimmermann',
			rating: 5,
			text: t('views.home.sections.testimonials.yannikZimmermann.text'),
			title: t('views.home.sections.testimonials.yannikZimmermann.title'),
		},
		{
			image: heikoSowade,
			name: 'Heiko Sowade',
			rating: 5,
			text: t('views.home.sections.testimonials.heikoSowade.text'),
			title: t('views.home.sections.testimonials.heikoSowade.title'),
		},
		{
			image: marioCuk,
			name: 'Mario Cuk',
			rating: 5,
			text: t('views.home.sections.testimonials.marioCuk.text'),
			title: t('views.home.sections.testimonials.marioCuk.title'),
		},
		{
			image: jochenSchafer,
			name: 'Jochen Schäfer',
			rating: 5,
			text: t('views.home.sections.testimonials.jochenSchafer.text'),
			title: t('views.home.sections.testimonials.jochenSchafer.title'),
		},
		{
			image: dirkMarien,
			name: 'Dirk Marien',
			rating: 5,
			text: t('views.home.sections.testimonials.dirkMarien.text'),
			title: t('views.home.sections.testimonials.dirkMarien.title'),
		},
		{
			image: danielMoreno,
			name: 'Daniel Moreno',
			rating: 5,
			text: t('views.home.sections.testimonials.danielMoreno.text'),
			title: t('views.home.sections.testimonials.danielMoreno.title'),
		},
		{
			image: christianKautz,
			name: 'Christian Kautz',
			rating: 5,
			text: t('views.home.sections.testimonials.christianKautz.text'),
			title: t('views.home.sections.testimonials.christianKautz.title'),
		},
		{
			image: andreRosinska,
			name: 'André Rosinska',
			rating: 5,
			text: t('views.home.sections.testimonials.andreRosinska.text'),
			title: t('views.home.sections.testimonials.andreRosinska.title'),
		},
	]

	const testimonialCards = testimonials.map((testimonial, i) => (
		<TestimonialCard
			image={testimonial.image}
			key={i}
			name={testimonial.name}
			rating={testimonial.rating}
			text={testimonial.text}
			title={testimonial.title}
		/>
	))

	return isLarge ? (
		<TestimonialCardsContainer>
			{testimonialCards.slice(0, 5)}
		</TestimonialCardsContainer>
	) : (
		<Carousel items={testimonialCards} />
	)
}

export { HomeViewSectionTestimonials }
