import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BlockCutter } from '../../components/BlockCutter'
import { PageHeader } from '../../components/PageHeader'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'

import titleBackground from './assets/title-background.jpg'

import { TextBlock } from './index'

const AGBView = () => {
	useScrollToTop()
	document.title = 'AGB | AIRTEAM'
	const { t } = useTranslation()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.legal.agb.title')}
			/>
			<BlockCutter top="in">
				<TextBlock>
					<Trans
						components={{
							bold: <strong />,
						}}
						i18nKey="views.legal.agb.text"
					/>
				</TextBlock>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const AGB_PATH = '/agb' as const

export { AGBView }
