import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { Color, Font, getFont, mediaBreakpoint } from '../theme'

const ModalStyle = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: rgba(33, 52, 53, 0.95); /* Color.AT_BLACK_2 */
	z-index: 9001;
	transform: translateZ(0);
	will-change: transform;
	backdrop-filter: blur(3px);

	display: flex;
	align-items: center;
	justify-content: center;

	.modal {
		&__container {
			box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			background-color: white;

			position: relative;

			@media ${mediaBreakpoint.small} {
				width: 90%;
				padding: 35px 30px;
			}

			@media ${mediaBreakpoint.medium} {
				width: 50%;
				padding: 40px 75px;
			}

			@media ${mediaBreakpoint.large} {
				width: 800px;
				padding: 25px 70px;
			}
		}

		&__close {
			position: absolute;

			@media ${mediaBreakpoint.small} {
				top: 5px;
				right: 5px;
			}

			@media ${mediaBreakpoint.mediumPlus} {
				top: 15px;
				right: 15px;
			}

			cursor: pointer;

			height: 40px;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			border-radius: 20px;
			@supports (clip-path: circle(20px at center)) {
				clip-path: circle(20px at center);
				border-radius: 0px;
			}

			@media (hover: hover) {
				&:hover {
					background-color: ${Color.AT_GRAY_3};
				}
			}
		}

		&__title {
			margin-bottom: 20px;

			${getFont(Font.B0_REGULAR)};

			@media ${mediaBreakpoint.large} {
				${getFont(Font.H4_MEDIUM)};
			}
		}
	}
`

const Modal = ({
	children,
	onClose,
	showCloseButton = true,
	title = null,
}: React.PropsWithChildren<{
	onClose(): void
	title?: string | null
	showCloseButton?: boolean
}>) => {
	const Modal = (
		<ModalStyle onClick={onClose}>
			<div className="modal__container" onClick={(e) => e.stopPropagation()}>
				{title && <div className="modal__title">{title}</div>}
				<div className="modal__content">{children}</div>
				{showCloseButton && (
					<div className="modal__close" onClick={onClose}>
						<svg fill="black" height="20" viewBox="0 0 20 20" width="20">
							<path d="M0.951096 17.7948C0.560572 18.1854 0.560572 18.8185 0.951096 19.2091C1.34162 19.5996 1.97479 19.5996 2.36531 19.2091L0.951096 17.7948ZM0.951096 2.1407L9.48528 10.6749L10.8995 9.26067L2.36531 0.726487L0.951096 2.1407ZM9.48528 10.6749L18.0195 19.2091L19.4337 17.7948L10.8995 9.26067L9.48528 10.6749ZM10.8995 10.6749L19.4337 2.1407L18.0195 0.726487L9.48528 9.26067L10.8995 10.6749ZM18.0195 0.726487L0.951096 17.7948L2.36531 19.2091L19.4337 2.1407L18.0195 0.726487Z" />
						</svg>
					</div>
				)}
			</div>
		</ModalStyle>
	)

	return ReactDOM.createPortal(
		Modal,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		document.getElementById('portal')!,
	)
}

export { Modal }
