import styled from 'styled-components'

import { mediaBreakpoint } from '../../../theme'

export const BlogEntries = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media ${mediaBreakpoint.mediumMinus} {
		> * {
			width: 100%;
		}
	}

	@media ${mediaBreakpoint.large} {
		> * {
			width: 800px;
		}
	}

	> *:not(:first-child) {
		margin-top: 40px;
	}
`
