import React, { useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Locale } from 'locale-enum'

import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { FieldNumber } from '../../components/fields/Number'
import { FieldRadioGroup } from '../../components/fields/RadioGroup'
import { RangeSlider } from '../../components/fields/RangeSlider'
import { useBreakpoints } from '../../hooks/use-breakpoints'
import { useStore } from '../../store'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'

import backgroundImage from './assets/background.jpg'
import { IndividualPricingContainer } from '../../views/pricing/components/IndividualPricingContainer'

const TextContent = styled.p`
	text-align: center;
	width: 90%;
	margin: 0 auto 50px auto;

	@media ${mediaBreakpoint.mediumMinus} {
		${getFont(Font.MOBILE_B1_BOLD)}
	}
	@media ${mediaBreakpoint.mediumPlus} {
		${getFont(Font.B0_BOLD)}
	}

	.highlight {
		color: #0d69d4;
		cursor: pointer;
	}
`
const CalculatorCards = styled.section`
	display: flex;

	@media ${mediaBreakpoint.mediumMinus} {
		background: ${Color.AT_WHITE_PRIMARY};
		box-shadow: 10px 10px 29px rgba(0, 0, 0, 0.07), inset 3px 3px 4px #ffffff;
		border-radius: 10px;
		margin: 10px;
		flex-direction: column;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		> div {
			background: ${Color.AT_WHITE_PRIMARY};
			box-shadow: 10px 10px 29px rgba(0, 0, 0, 0.07), inset 3px 3px 4px #ffffff;
			border-radius: 10px;
			margin: 10px;
			min-height: 450px;
		}
	}

	.calculator {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		flex: 2;

		@media ${mediaBreakpoint.mediumMinus} {
			padding: 25px;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			padding: 60px;
		}

		.radio-groups {
			display: grid;
			width: 100%;

			@media ${mediaBreakpoint.small} {
				grid-template-columns: 1fr;
			}
			@media ${mediaBreakpoint.mediumPlus} {
				grid-template-columns: 1fr 1fr;
			}
		}

		.radio-group-container {
			display: flex;
			flex-direction: column;

			label {
				color: ${Color.AT_BLACK_TEXT};
				min-width: 0px;

				@media ${mediaBreakpoint.mediumMinus} {
					${getFont(Font.MOBILE_B2_REGULAR)};
				}
				@media ${mediaBreakpoint.mediumPlus} {
					${getFont(Font.B2_REGULAR)};
				}
			}

			&__title {
				color: ${Color.AT_BLACK_TEXT};
				margin-bottom: 16px;

				@media ${mediaBreakpoint.mediumMinus} {
					${getFont(Font.MOBILE_B1_BOLD)};
				}
				@media ${mediaBreakpoint.mediumPlus} {
					${getFont(Font.B0_BOLD)};
				}

				&__help-icon {
					display: inline-flex;
					position: relative;
					top: -10px;
					left: 4px;
					font-family: 'Courier New', Courier, monospace;
					justify-content: center;
					align-items: center;
					font-size: 15px;
					color: ${Color.AT_WHITE_PRIMARY};
					background-color: #ebb144;
					border-radius: 100%;
					width: 15px;
					height: 15px;
					padding: 2px;
					text-decoration: none;
					text-align: center;
					text-decoration: none;
					box-shadow: inset -1px -1px 1px 0px rgba(0, 0, 0, 0.25);
					cursor: pointer;
				}
			}
		}

		.area-and-price {
			display: grid;
			width: 100%;
			margin-top: 50px;
			@media ${mediaBreakpoint.small} {
				margin-top: 0;
			}

			@media ${mediaBreakpoint.mediumMinus} {
				grid-template-columns: 1fr;
			}
			@media ${mediaBreakpoint.large} {
				grid-template-columns: 1fr 1fr;
			}
		}

		.area {
			display: flex;
			align-items: flex-start;
			margin-bottom: 25px;

			@media ${mediaBreakpoint.mediumMinus} {
				justify-content: space-between;
			}

			@media ${mediaBreakpoint.small} {
				padding: 10px 25px;
				background: ${Color.AT_GRAY_6};
			}

			&__title {
				color: ${Color.AT_BLACK_TEXT};
				margin-right: 10px;

				@media ${mediaBreakpoint.mediumMinus} {
					${getFont(Font.MOBILE_B1_BOLD)};
				}
				@media ${mediaBreakpoint.mediumPlus} {
					${getFont(Font.B0_BOLD)};
				}
			}

			&__input {
				align-items: center;
				width: 125px;
			}
		}

		.project-classification {
			display: flex;
			align-items: flex-start;
			margin: 25px 0 50px;

			@media ${mediaBreakpoint.small} {
				margin: 0;
			}

			@media ${mediaBreakpoint.mediumMinus} {
				width: 100%;
				justify-content: space-between;
			}

			&__title {
				color: ${Color.AT_BLACK_TEXT};
				margin-right: 10px;

				@media ${mediaBreakpoint.mediumMinus} {
					${getFont(Font.MOBILE_B1_BOLD)};
				}
				@media ${mediaBreakpoint.mediumPlus} {
					${getFont(Font.B0_BOLD)};
				}
			}
		}

		.total-price {
			display: flex;
			flex-direction: column;
			text-align: right;
			margin-bottom: 25px;

			@media ${mediaBreakpoint.mediumMinus} {
				width: 100%;
			}

			.title-and-price {
				display: flex;
				justify-content: space-between;

				@media ${mediaBreakpoint.small} {
					flex-direction: column;
				}

				@media ${mediaBreakpoint.mediumMinus} {
					width: 100%;
				}

				&__title {
					color: ${Color.AT_BLACK_TEXT};
					margin-right: 10px;
					text-align: left;

					@media ${mediaBreakpoint.mediumMinus} {
						${getFont(Font.MOBILE_B1_BOLD)};
					}
					@media ${mediaBreakpoint.mediumPlus} {
						${getFont(Font.B0_BOLD)};
					}
				}

				&__price {
					color: ${Color.AT_YELLOW};
					display: flex;

					@media ${mediaBreakpoint.mediumMinus} {
						${getFont(Font.MOBILE_H0_BOLD)};
						text-align: left;
						margin: 10px 0;
					}

					@media ${mediaBreakpoint.mediumPlus} {
						${getFont(Font.H2_SEMI_BOLD)};
						margin-top: -15px;
					}

					&__currency {
						margin-left: 8px;
					}
				}
			}

			&__disclaimer {
				color: ${Color.AT_BLACK_TEXT};
				text-align: left;

				@media ${mediaBreakpoint.small} {
					${getFont(Font.MOBILE_CAPTION_REGULAR)};
				}
				@media ${mediaBreakpoint.mediumPlus} {
					${getFont(Font.CAPTION_BIG_14)};
				}
			}
		}
	}

	.slider {
		width: 100%;

		&__text {
			display: flex;
			justify-content: space-between;

			margin-left: 10px;
			${getFont(Font.B1_BOLD)};

			&__right {
				display: flex;
				flex-direction: column;
				text-align: right;

				&_sub {
					${getFont(Font.CAPTION_BIG_14)};
				}
			}
		}
	}

	.classificationContainer {
		margin-top: 5px;
		display: flex;

		@media ${mediaBreakpoint.small} {
			margin: 15px 0;
		}
	}

	.project-class {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		padding: 10px 5px;
		margin-right: 5px;
		text-align: center;
		border: 1px solid #ccc;

		@media ${mediaBreakpoint.small} {
			margin-right: 2px;
		}

		@media ${mediaBreakpoint.mediumMinus} {
			padding: 3px;
			font-weight: 500;
		}
	}

	.mobileHorizontalDivider {
		border: none;
		width: 70%;
		background-color: ${Color.AT_GRAY_4};
		height: 1px;
	}

	.link {
		${getFont(Font.CAPTION_BIG_14)};
		color: ${Color.LINK};
	}

	.included {
		display: flex;
		flex-direction: column;
		flex: 1;
		@media ${mediaBreakpoint.mediumMinus} {
			padding: 25px;
			min-height: 450px;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			padding: 60px;
		}

		.title {
			color: ${Color.AT_BLACK_TEXT};
			margin-bottom: 35px;

			@media ${mediaBreakpoint.mediumMinus} {
				${getFont(Font.MOBILE_B1_BOLD)};
			}
			@media ${mediaBreakpoint.mediumPlus} {
				${getFont(Font.B0_BOLD)};
			}
		}

		.list {
			display: flex;
			flex-direction: column;

			> *:not(:first-child) {
				margin-top: 30px;
			}

			> * {
				display: flex;
				align-items: center;

				color: ${Color.AT_BLACK_TEXT};

				@media ${mediaBreakpoint.mediumMinus} {
					${getFont(Font.MOBILE_B2_REGULAR)};
				}
				@media ${mediaBreakpoint.mediumPlus} {
					${getFont(Font.B1_REGULAR)};
				}

				.check {
					height: 27px;
					width: 27px;
				}

				> *:not(:first-child) {
					margin-left: 20px;
				}
			}
			margin-bottom: 35px;
		}

		.disclaimer {
			${getFont(Font.CAPTION_BIG_14)};
			color: ${Color.AT_BLACK_TEXT};
		}

		.disclaimer-down {
			${getFont(Font.CAPTION_BIG_14)};
			color: ${Color.AT_BLACK_TEXT};
			margin-top: 30px;
		}
	}
`

enum Operator {
	AIRTEAM = 'AIRTEAM',
	CUSTOMER = 'CUSTOMER',
}

type ColorKey = keyof typeof Color

const SpecialProject = 'special'

const PriceCalculator = () => {
	const { i18n, t } = useTranslation()
	const { isLarge, isSmall } = useBreakpoints()
	const history = useHistory()
	const currentLanguage = i18n.language

	const [operator, setOperator] = useState<Operator>(Operator.AIRTEAM)
	const [area, setArea] = useState<number>(100)
	const { projectClasses } = useStore()

	const navigateToContact = () => {
		history.push(`/contact`)
	}

	const included = useMemo(() => {
		const baseIncludedList = [
			t('views.industry.sections.priceCalculator.included2'),

			t('views.industry.sections.priceCalculator.included3'),

			t('views.industry.sections.priceCalculator.included4'),
		]

		if (operator === Operator.AIRTEAM)
			return [
				...[
					t('views.industry.sections.priceCalculator.included1'),

					t('views.industry.sections.priceCalculator.included5'),
				],

				...baseIncludedList,
			]

		return baseIncludedList
	}, [t, operator])

	const downloadPriceSheetButton = useCallback(() => {
		return (
			<Button
				label={t(
					'views.pricing.sections.subscriptions.downloadPriceSheetButton',
				)}
				onClick={() =>
					openInNewTab(
						currentLanguage === Locale.de_DE
							? '/Airteam Preise - Einzelpreise Sep 2024.pdf'
							: '/Airteam Preise - Einzelpreise Sep 2024 ENG.pdf',
					)
				}
			></Button>
		)
	}, [t])

	// Calculate the project classes based on operator
	const projectClassesForOperator = useMemo(() => {
		return projectClasses.filter((projectClass) => {
			if (operator === Operator.CUSTOMER) {
				return projectClass.customer_piloted
			} else {
				return !projectClass.customer_piloted
			}
		})
	}, [operator, projectClasses])

	// Calculate the class based on the area
	const currentClass = useMemo(() => {
		return projectClassesForOperator.find(
			(projectClass) => area <= projectClass.max_surface,
		)
	}, [area, projectClassesForOperator])

	const classFeatures = useMemo(() => {
		let includedInPrice: string[] = []
		if (currentClass) {
			includedInPrice = [
				t('views.industry.sections.priceCalculator.included3'),
				t('views.industry.sections.priceCalculator.included4'),
				t(
					`views.industry.sections.priceCalculator.included5-${currentClass.name}`,
				),
			]

			if (operator === Operator.AIRTEAM)
				includedInPrice = [
					...[
						t('views.industry.sections.priceCalculator.included1'),
						t('views.industry.sections.priceCalculator.included2'),
					],
					...includedInPrice,
				]
		}

		return includedInPrice
	}, [t, operator, currentClass])

	// Render the disclaimers based on the current class of the project
	const renderDisclaimers = useMemo(
		() =>
			currentClass && (
				<div>
					<div className="total-price__disclaimer">
						{t(
							`views.industry.sections.priceCalculator.deliverables-${currentClass.name}`,
						)}
					</div>
					{currentClass.name === SpecialProject ? (
						<div className="total-price__disclaimer">
							<a className="link" href="/contact">
								{t(
									`views.industry.sections.priceCalculator.priceDisclaimer1-${currentClass.name}`,
								)}
							</a>
							{t(
								`views.industry.sections.priceCalculator.priceDisclaimer2-${currentClass.name}`,
							)}
						</div>
					) : (
						<div className="total-price__disclaimer">
							{t('views.industry.sections.priceCalculator.priceDisclaimer')}
						</div>
					)}
				</div>
			),
		[currentClass, i18n.language],
	)

	return (
		<>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_1,

					lineColor: Color.AT_GREEN_1,

					text: t('views.pricing.sections.singlePrices.title'),
				}}
				button={downloadPriceSheetButton()}
			>
				<TextContent>
					{t('views.pricing.sections.singlePrices.content')}
				</TextContent>

				<TextContent>
					{t('views.pricing.sections.singlePrices.content2')}
				</TextContent>

				<IndividualPricingContainer />
				<TextContent>
					{t('views.pricing.pricingCard.vatDisclaimer')}
				</TextContent>
				{isLarge && (
					<TextContent>
						{t('views.pricing.sections.singlePrices.content3')}

						<br />

						<span className="highlight" onClick={navigateToContact}>
							{t('views.pricing.sections.singlePrices.here')}
						</span>

						{t('views.pricing.sections.singlePrices.content4')}
					</TextContent>
				)}
			</BlockCutter>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.industry.sections.priceCalculator.title'),
				}}
			>
				<CalculatorCards>
					<div className="calculator">
						<div className="radio-groups">
							<div className="radio-group-container">
								<div
									style={{
										background: isSmall ? Color.AT_GRAY_6 : undefined,
										padding: isSmall ? '27px 18px 27px 18px' : undefined,
									}}
								>
									<div className="radio-group-container__title">
										{t(
											'views.industry.sections.priceCalculator.whoFlies.title',
										)}
									</div>
									<FieldRadioGroup<Operator>
										options={[
											{
												label: t(
													'views.industry.sections.priceCalculator.whoFlies.airteam',
												),
												value: Operator.AIRTEAM,
											},
											{
												label: t(
													'views.industry.sections.priceCalculator.whoFlies.self',
												),
												value: Operator.CUSTOMER,
											},
										]}
										setValue={setOperator}
										value={operator}
									/>
								</div>
							</div>
						</div>
						<div className="area-and-price">
							<div className="area">
								<div className="area__title">
									{t('views.industry.sections.priceCalculator.area')} (m²)
								</div>
								<div className="area__input">
									<FieldNumber
										minValue={0}
										setValue={setArea}
										size={6}
										value={area}
									/>
								</div>
							</div>
							<div className="total-price">
								<div className="title-and-price">
									<div className="title-and-price__title">
										{t('views.industry.sections.priceCalculator.totalPrice')}
									</div>
									{currentClass && (
										<div className="title-and-price__price">
											{currentClass.name === SpecialProject
												? t('views.industry.sections.priceCalculator.quote')
												: Math.floor(
														parseInt(
															currentClass?.price ? currentClass.price : '',
														),
												  )}
											{currentClass.name !== SpecialProject && (
												<span className="title-and-price__price__currency">
													€
												</span>
											)}
										</div>
									)}
								</div>
								{isLarge && renderDisclaimers}
							</div>
						</div>
						<div className="slider">
							<div className="project-classification">
								<div className="project-classification__title">
									{t(
										'views.industry.sections.priceCalculator.projectClassification',
									)}
								</div>
							</div>
							{isLarge && (
								<RangeSlider
									max={10000}
									min={100}
									setValue={setArea}
									value={area}
								/>
							)}
							<div className="classificationContainer">
								{projectClassesForOperator.map((projectClass) => (
									<span
										className="project-class"
										key={projectClass.name}
										style={{
											color:
												projectClass.name === SpecialProject
													? 'black'
													: 'white',
											background:
												Color[
													`CLASS_${projectClass.name.toUpperCase()}` as ColorKey
												],
											...(isLarge
												? {
														transform:
															projectClass.name === currentClass?.name
																? 'scaleX(1.05) scaleY(1.3)'
																: 'none',
												  }
												: {
														opacity:
															projectClass.name === currentClass?.name
																? '1'
																: '0.2',
												  }),
										}}
									>
										{t(
											`views.industry.sections.priceCalculator.${projectClass.name}`,
										)}
									</span>
								))}
							</div>
							{isSmall && renderDisclaimers}
						</div>
					</div>
					{isSmall && <hr className="mobileHorizontalDivider" />}
					<div className="included">
						<div className="title-and-list">
							<div className="title">
								{t('views.industry.sections.priceCalculator.includedText')}
							</div>
							<div className="list">
								{classFeatures.map((feature) => (
									<div key={feature}>
										<div className="check">
											<svg height="28" viewBox="0 0 28 28" width="28">
												<path
													d="M14.0449 27.2305C21.5326 27.2305 27.6026 21.1605 27.6026 13.6729C27.6026 6.18519 21.5326 0.115234 14.0449 0.115234C6.55726 0.115234 0.487305 6.18519 0.487305 13.6729C0.487305 21.1605 6.55726 27.2305 14.0449 27.2305Z"
													fill={Color.AT_YELLOW}
												/>
												<path
													d="M20.3719 5.53857L10.8816 15.3001L7.71813 12.0462L4.55469 15.3001L10.8816 21.8077L23.5354 8.7924L20.3719 5.53857Z"
													fill={Color.AT_WHITE_PRIMARY}
												/>
											</svg>
										</div>
										<div className="label">{feature}</div>
									</div>
								))}
							</div>
						</div>
						<div className="disclaimer">
							*
							<a
								className="link"
								href="https://airteam.ai/blog/69/der-ultimative-guide-diese-15-planungsformate-liefert-die-airteam-k-i"
							>
								{t(
									'views.industry.sections.priceCalculator.includedDisclaimer1',
								)}
							</a>
							{t('views.industry.sections.priceCalculator.includedDisclaimer2')}
						</div>
					</div>
				</CalculatorCards>
			</BlockCutter>
		</>
	)
}

export { PriceCalculator }
