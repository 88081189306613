import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, mediaBreakpoint } from '../../../theme'

import ruler from '../../../assets/icons/ruler.svg'
import deliverablesIcon from '../../../assets/icons/deliverables.svg'
import drone from '../../../assets/icons/drone.svg'
import droneGreen from '../../../assets/icons/drone_green.svg'
import { formatArea } from '../../../formatters/currency'

export interface CardProps {
	classification: string
	maximumArea: number
	deliverables: number
	price: number
	priceWithDrone: number
	color:
		| Color.AT_YELLOW
		| Color.AT_BLACK_1
		| Color.AT_GREEN_2
		| Color.AT_YELLOW_2
		| Color.AT_GRAY_2
}

const Card = styled.div<Pick<CardProps, 'color'>>`
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #f2f2f2;
	padding: 20px;
	margin: 0 4px;
	border-radius: 5px;

	@media ${mediaBreakpoint.largeMinus} {
		margin: 16px;
	}

	p {
		border-radius: 5px;
		font-family: Manrope;
		font-style: normal;
		line-height: 17px;
		padding: 8px;
	}

	.classification {
		&__title {
			background-color: ${(props) => props.color};
			text-align: center;
			color: #fff;
			font-size: 24px;
			font-weight: 700;
		}

		&__content {
			background-color: ${Color.AT_WHITE_PRIMARY};
			padding-left: 16px;
			color: #2f313e;
			font-size: 20px;
			font-weight: 400;

			&--dark {
				background-color: #2f313e;
				color: ${Color.AT_WHITE_PRIMARY};
				text-align: center;
				padding-left: 0;
			}
		}
	}
`

const IndividualPricingCard = ({
	classification,
	color,
	maximumArea,
	deliverables,
	price,
	priceWithDrone,
}: CardProps) => {
	const { i18n, t } = useTranslation()
	const { isLarge, isMedium, isSmall } = useBreakpoints()

	return (
		<Card color={color}>
			<p className="classification__title">{classification}</p>
			<p className="classification__content">
				<img
					alt="Ruler"
					src={ruler}
					style={{
						marginRight: '10px',
					}}
				/>
				<span>
					{t('views.pricing.sections.singlePrices.maximumArea', {
						maximumArea: formatArea(maximumArea),
					})}
				</span>
			</p>
			<p className="classification__content">
				<img
					alt="Deliverables"
					src={deliverablesIcon}
					style={{
						marginRight: '10px',
					}}
				/>
				{deliverables > 1
					? t('views.pricing.sections.singlePrices.deliverables', {
							deliverables: deliverables,
					  })
					: 1}
			</p>
			<p className="classification__content classification__content--dark">
				<img
					alt="Drone"
					src={drone}
					style={{
						marginRight: '10px',
					}}
				/>
				<span>{price}€</span>
			</p>
			<p className="classification__content classification__content--dark">
				<img
					alt="Drone"
					src={droneGreen}
					style={{
						marginRight: '10px',
					}}
				/>
				<span>{formatArea(priceWithDrone)}€</span>
			</p>
		</Card>
	)
}

export { IndividualPricingCard }
