import { useCallback, useEffect, useRef, useState } from 'react'
import Reaptcha from 'reaptcha'

import { useFieldValidators } from '../../../hooks/use-field-validators'
import { blogsApi } from '../../../utilities/axios-api'

type NewsletterData = {
	email: string
	recaptcha: string
}

export const useNewsletterForm = () => {
	const [email, setEmail] = useState<string | null>(null)
	const [googleRecaptchaResponse, setGoogleRecaptchaResponse] = useState<
		string | null
	>(null)
	const [showGoogleRecaptcha, setShowGoogleRecaptcha] = useState(false)
	const [isSignupSuccessful, setIsSignUpSuccessful] = useState<boolean | null>(
		null,
	)
	const [isWaitingOnServer, setIsWaitingOnServer] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const reaptchaRef = useRef<Reaptcha>(null)

	const { validateEmail } = useFieldValidators()

	const isFormValid = useCallback(() => {
		return email !== null && validateEmail(email)
	}, [email, validateEmail])

	const resetForm = useCallback(async () => {
		setEmail(null)
		setGoogleRecaptchaResponse(null)
		if (!reaptchaRef.current) throw new Error('Reaptcha Ref Missing')
		await reaptchaRef.current.reset()
		setShowGoogleRecaptcha(false)
	}, [setEmail, setGoogleRecaptchaResponse])

	const submit = useCallback(async () => {
		if (email === null || googleRecaptchaResponse === null)
			throw Error('form not filled')

		const data: NewsletterData = {
			email,
			recaptcha: googleRecaptchaResponse,
		}
		await blogsApi.post('/api/newsletter-registration/', data)
	}, [email, googleRecaptchaResponse])

	// Form should submit automatically when recaptcha is set
	useEffect(() => {
		if (googleRecaptchaResponse !== null && !isWaitingOnServer) {
			setIsWaitingOnServer(true)
			setShowModal(true)
			submit()
				.then(() => {
					setIsSignUpSuccessful(true)
				})
				.catch(() => {
					setIsSignUpSuccessful(false)
				})
				.finally(() => {
					resetForm().finally(() => setIsWaitingOnServer(false))
				})
		}
	}, [googleRecaptchaResponse, isWaitingOnServer, submit, resetForm])

	return {
		email,
		isFormValid,
		isSignupSuccessful,
		isWaitingOnServer,
		reaptchaRef,
		resetForm,
		setEmail,
		setGoogleRecaptchaResponse,
		setShowGoogleRecaptcha,
		setShowModal,
		showGoogleRecaptcha,
		showModal,
		submit,
	}
}
