import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { FastFusionAdvantages } from '../../components/FastFusionAdvantages'
import { FastFusionSolarAdvantages } from '../../components/FastFusionSolarAdvantages'
import { useBreakpoints } from '../../hooks/use-breakpoints'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'

import { IndustryHeader } from './components/Header'
import { IndustrySection1Quote2OldNew } from './components/Section1Quote2OldNew'
import { IndustrySection3Advantages } from './components/Section3Advantages'
import { IndustrySection4Options } from './components/Section4Options'
import { IndustrySection5Results } from './components/Section5Results'
import { IndustryType } from './types'
import { formatLang } from '../../utilities/format-lang'

const FastFusion = styled.div`
	p {
		color: ${Color.AT_BLACK_1};
		text-align: center;
		margin: 0 auto 40px auto;

		@media ${mediaBreakpoint.large} {
			${getFont(Font.B0_BOLD)}
			width: 80%;
		}
		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B1_BOLD)}
			width: 100%;
		}
	}
`

const IndustrySolarView = () => {
	useScrollToTop()
	document.title = 'Solar | AIRTEAM'
	const { isSmall } = useBreakpoints()
	const { i18n, t } = useTranslation()
	const currentLanguage = i18n.language

	return (
		<DefaultPageLayout>
			<IndustryHeader type={IndustryType.SOLAR} />
			<IndustrySection1Quote2OldNew type={IndustryType.SOLAR} />
			<BlockCutter
				button={
					<Button
						label={t('views.home.sections.fastFusion.button')}
						type="primary"
						onClick={() =>
							openInNewTab(
								`https://ffsolar.airteam.cloud/sample?lang=${formatLang(
									currentLanguage,
								)}`,
							)
						}
					/>
				}
				style={{
					marginTop: isSmall ? undefined : '60px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.fastFusionSolar.title'),
				}}
			>
				<FastFusion>
					<FastFusionSolarAdvantages />
				</FastFusion>
			</BlockCutter>
			<BlockCutter
				button={
					<Button
						label={t('views.home.sections.fastFusion.button')}
						type="primary"
						onClick={() =>
							openInNewTab('https://airteam.cloud/fast-fusion-thermal-preview')
						}
					/>
				}
				style={{
					marginTop: isSmall ? undefined : '60px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.thermal.title'),
				}}
			>
				<FastFusion>
					<FastFusionAdvantages type="thermal" />
				</FastFusion>
			</BlockCutter>
			<IndustrySection3Advantages type={IndustryType.SOLAR} />
			<IndustrySection4Options />
			<IndustrySection5Results type={IndustryType.SOLAR} />
		</DefaultPageLayout>
	)
}

export const INDUSTRY_SOLAR_PATH = '/industry/solar' as const

export { IndustrySolarView }
