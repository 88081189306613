import React from 'react'
import styled from 'styled-components'

import { Color, mediaBreakpoint } from '../../../theme'
import starRatingIcon from '../assets/star-rating.svg'

export type TestimonialCardProps = {
	image: string
	name: string
	rating: number
	text: string
	title: string
}

const TestimonialCardStyle = styled.div`
	display: flex;
	flex-direction: column;

	height: 100%;
	background: ${Color.AT_WHITE_PRIMARY};
	box-sizing: border-box;
	padding: 23px;
	border-radius: 3px;

	margin: 0 40px;
	width: 100%;

	.image-and-rating {
		display: flex;
		justify-content: space-between;

		&__image {
			align-self: center;
			border-radius: 3px;
			margin-bottom: 6px;

			@media ${mediaBreakpoint.small} {
				width: 50%;
			}
			@media ${mediaBreakpoint.medium} {
				width: 20%;
			}
			@media ${mediaBreakpoint.large} {
				width: 50%;
			}
		}
		&__rating-wrapper {
			img {
				@media ${mediaBreakpoint.mediumMinus} {
					width: 15px;
					height: 15px;
					margin-left: 5px;
				}
				@media ${mediaBreakpoint.mediumPlus} {
					width: 10px;
					height: 10px;
					margin-left: 3px;
				}
			}
		}
	}

	.name {
		margin-top: 6px;

		color: ${Color.AT_BLACK_NOT_1};

		/* no standard font size name */
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 1%;
	}

	.title {
		margin-top: 2px;

		color: ${Color.AT_BLACK_NOT_1};

		/* no standard font size name */
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 1%;
	}

	.text {
		margin: 12px 0;

		color: ${Color.AT_GRAY_2};

		/* no standard font size name */
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 1%;
	}
`

const TestimonialCard = ({
	image,
	name,
	rating,
	text,
	title,
}: TestimonialCardProps) => {
	return (
		<TestimonialCardStyle>
			<div className="image-and-rating">
				<img
					alt="Testimonial client"
					className="image-and-rating__image"
					src={image}
				/>
				<span className="image-and-rating__rating-wrapper">
					{[...Array<unknown>(rating)].map((_, i) => (
						<img alt="Star rating" key={i} src={starRatingIcon} />
					))}
				</span>
			</div>
			<div className="name">{name}</div>
			<div className="title">{title}</div>
			<div className="text">“{text}”</div>
		</TestimonialCardStyle>
	)
}

export { TestimonialCard }
