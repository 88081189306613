import React from 'react'
import styled from 'styled-components'

import { LOGIN_URL } from '../../data'
import { Color } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'

const LoginIconStyle = styled.svg`
	cursor: pointer;
	fill: ${Color.AT_WHITE_PRIMARY};

	align-self: flex-end;

	:hover {
		fill: ${Color.AT_GREEN_1};
	}
`

const LoginIcon = () => (
	<LoginIconStyle
		height="40"
		role="button"
		viewBox="0 0 40 40"
		width="40"
		onClick={() => openInNewTab(LOGIN_URL)}
	>
		<path d="M23.3125 23.4375H7.9375C7.5231 23.4375 7.12567 23.2729 6.83265 22.9799C6.53962 22.6868 6.375 22.2894 6.375 21.875V19.5312H7.9375V21.875H23.3125V3.125H7.9375V5.46875H6.375V3.125C6.375 2.7106 6.53962 2.31317 6.83265 2.02015C7.12567 1.72712 7.5231 1.5625 7.9375 1.5625H23.3125C23.7269 1.5625 24.1243 1.72712 24.4174 2.02015C24.7104 2.31317 24.875 2.7106 24.875 3.125V21.875C24.875 22.2894 24.7104 22.6868 24.4174 22.9799C24.1243 23.2729 23.7269 23.4375 23.3125 23.4375Z" />
		<path d="M12.3984 16.0859L15.1953 13.2812H0.125V11.7188H15.1953L12.3984 8.91406L13.5 7.8125L18.1875 12.5L13.5 17.1875L12.3984 16.0859Z" />
	</LoginIconStyle>
)

export { LoginIcon }
