import { Locale } from 'locale-enum'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import checkMark from '../../assets/icons/green-check.svg'
import { Advantages } from '../../components/Advantages'
import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { FastFusionAdvantages } from '../../components/FastFusionAdvantages'
import { FastFusionSolarAdvantages } from '../../components/FastFusionSolarAdvantages'
import { PageHeader } from '../../components/PageHeader'
import Ticker from '../../components/Ticker'
import { TryForFree } from '../../components/TryForFree'
import { useBreakpoints } from '../../hooks/use-breakpoints'
import { useScrollToElementId } from '../../hooks/use-scroll-to-element-id'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Background, Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'
import { PRICING_PATH, DRONE_PACKAGE_ELEMENT_ID } from '../pricing/View'

import advantagesFast from './assets/advantages/fast.svg'
import advantagesFormat from './assets/advantages/format.svg'
import advantagesWorryFree from './assets/advantages/worry-free.svg'
import droneKit from './assets/drone-kit.svg'
import titleBackground from './assets/title-background.jpg'
import { Report } from './components/Report'
import { WorkflowDesktop } from './components/workflow/desktop'
import { WorkflowMobile } from './components/workflow/mobile'
import { formatLang } from '../../utilities/format-lang'

const FastFusion = styled.div`
	p {
		color: ${Color.AT_BLACK_1};
		text-align: center;
		margin: 0 auto 40px auto;

		@media ${mediaBreakpoint.large} {
			${getFont(Font.B0_BOLD)}
			width: 80%;
		}
		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B1_BOLD)}
			width: 100%;
		}
	}
`

const HowItWorks = styled.section`
	text-align: center;

	@media ${mediaBreakpoint.medium} {
		margin-bottom: 40px;
	}

	@media ${mediaBreakpoint.large} {
		margin-bottom: 100px;
	}

	svg {
		max-width: 100%;
	}

	p {
		width: 75%;
		margin-inline: auto;

		color: ${Color.AT_WHITE_PRIMARY};

		@media ${mediaBreakpoint.mediumMinus} {
			/* no standard font size name  */
			font-family: Manrope;
			font-style: normal;
			font-weight: bold;
			font-size: 19px;
			line-height: 28.5px;
			letter-spacing: 0.01em;

			margin-top: 15px;
		}

		@media ${mediaBreakpoint.large} {
			/* no standard font size name  */
			font-family: Manrope;
			font-style: normal;
			font-weight: normal;
			font-size: 32px;
			line-height: 48px;
			letter-spacing: 0.01em;

			margin-top: 60px;
		}
	}
`

const YouFly = styled.section`
	display: flex;

	@media ${mediaBreakpoint.large} {
		margin: 0 69px;
	}

	> * {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@media ${mediaBreakpoint.mediumMinus} {
			padding-inline: 10px;
		}
		@media ${mediaBreakpoint.large} {
			padding-inline: 30px;
		}

		img {
			@media ${mediaBreakpoint.mediumMinus} {
				display: block;
				width: 70%;
				max-width: 400px;
				margin: 20px auto 20px auto;
			}
			@media ${mediaBreakpoint.large} {
				width: 67%;
				margin-bottom: 50px;
			}
		}

		.bold {
			color: ${Color.AT_BLACK_1};

			@media ${mediaBreakpoint.mediumMinus} {
				${getFont(Font.MOBILE_H3_BOLD)}
				margin-bottom: 10px;
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.H4_BOLD)}
				margin-bottom: 32px;
			}
		}

		.bolder {
			color: ${Color.AT_GREEN_1};

			@media ${mediaBreakpoint.mediumMinus} {
				text-align: center;
				${getFont(Font.MOBILE_H1_BOLD)}
				margin-bottom: 10px;
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.H4_BOLD)}
				margin-bottom: 32px;
			}
		}

		.text {
			color: ${Color.AT_BLACK_1};

			@media ${mediaBreakpoint.mediumMinus} {
				${getFont(Font.MOBILE_B2_REGULAR)}
			}
			@media ${mediaBreakpoint.large} {
				/* no standard font size name */
				font-family: Manrope;
				font-style: normal;
				font-weight: normal;
				font-size: 23px;
				line-height: 34.5px;
				letter-spacing: 0.01em;
			}
		}

		.white-card {
			background-color: ${Color.AT_WHITE_PRIMARY};
			box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			margin-top: 10px;

			@media ${mediaBreakpoint.mediumMinus} {
				display: flex;
				flex-direction: column;
				align-items: center;

				padding: 20px;
				margin-bottom: 20px;
			}
			@media ${mediaBreakpoint.large} {
				padding: 50px;
				margin-bottom: 58px;
			}

			.bold {
				@media ${mediaBreakpoint.mediumMinus} {
					text-align: center;
				}
			}

			ul {
				padding: 0;

				/* no standard font size name */
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				line-height: 144%;
				letter-spacing: 0.01em;

				@media ${mediaBreakpoint.mediumMinus} {
					font-size: 17px;
					margin-top: 25px;
				}
				@media ${mediaBreakpoint.large} {
					font-size: 21px;
				}

				li {
					margin: 3px;
					padding: 0 0 0 50px;
					list-style: none;
					background-image: url(${checkMark});
					background-repeat: no-repeat;
					background-position: left center;
					background-size: 25px;
				}
			}
		}

		.button {
			@media ${mediaBreakpoint.mediumMinus} {
				margin: 20px auto 0 auto;
			}
			@media ${mediaBreakpoint.large} {
				margin-top: 40px;
			}
		}
	}

	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
		text-align: left;

		> *:not(:first-child) {
			margin-top: 50px;
		}
	}

	@media ${mediaBreakpoint.large} {
		flex-direction: row;

		> *:not(:first-child) {
			margin-left: 75px;
		}
	}
`

const TrustUs = styled.section`
	display: flex;

	@media ${mediaBreakpoint.large} {
		margin-bottom: 120px;
	}

	> * {
		flex: 1;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 30px;

		.number {
			text-align: center;
			color: ${Color.AT_YELLOW};

			@media ${mediaBreakpoint.small} {
				font-family: Manrope;
				font-size: 33px;
				font-style: normal;
				font-weight: 600;
				line-height: 49px;
				letter-spacing: 0.01em;
			}

			@media ${mediaBreakpoint.mediumPlus} {
				${getFont(Font.H0)};
			}
		}

		.text {
			text-align: center;
			color: ${Color.AT_BLACK_1};

			@media ${mediaBreakpoint.small} {
				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.01em;
			}

			@media ${mediaBreakpoint.mediumPlus} {
				${getFont(Font.H3_MEDIUM)};
			}
		}
	}

	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
	}
`

const WeFlyText = styled.p`
	text-align: center;

	color: ${Color.AT_BLACK_1};

	@media ${mediaBreakpoint.small} {
		/* no standard font size name  */
		font-family: Mulish;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0.01em;

		margin: 0 25px;
	}

	@media ${mediaBreakpoint.mediumPlus} {
		/* no standard font size name  */
		font-family: Manrope;
		font-size: 23px;
		font-style: normal;
		font-weight: 400;
		line-height: 35px;
		letter-spacing: 0.01em;

		margin: 0 69px 40px 69px;
	}
`

const ProductView = () => {
	useScrollToTop()
	useScrollToElementId()
	document.title = 'Produkt | AIRTEAM'
	const { i18n, t } = useTranslation()
	const { isLarge, isSmall } = useBreakpoints()

	const currentLanguage = i18n.language

	const tickerSeparator = currentLanguage === Locale.de_DE ? '.' : ','

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				text={t('views.product.title')}
			/>
			<BlockCutter
				button={
					<Button
						label={t('views.home.sections.fastFusion.button')}
						type="primary"
						onClick={() =>
							openInNewTab(
								`https://ffsolar.airteam.cloud/sample?lang=${formatLang(
									currentLanguage,
								)}`,
							)
						}
					/>
				}
				style={{
					marginTop: isSmall ? undefined : '60px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.fastFusionSolar.title'),
				}}
			>
				<FastFusion>
					<FastFusionSolarAdvantages />
				</FastFusion>
			</BlockCutter>
			<BlockCutter
				button={
					<Button
						label={t('views.home.sections.fastFusion.button')}
						type="primary"
						onClick={() =>
							openInNewTab('https://airteam.cloud/fast-fusion-thermal-preview')
						}
					/>
				}
				style={{
					marginTop: isSmall ? undefined : '60px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.thermal.title'),
				}}
			>
				<FastFusion>
					<FastFusionAdvantages type="thermal" />
				</FastFusion>
			</BlockCutter>
			<BlockCutter
				button={
					<Button
						label={t('views.home.sections.fastFusion.button')}
						type="primary"
						onClick={() =>
							openInNewTab('https://airteam.cloud/fast-fusion-preview')
						}
					/>
				}
				style={{
					marginTop: isSmall ? undefined : '60px',
				}}
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.fastFusion.title'),
				}}
			>
				<FastFusion>
					<p>{t('views.product.sections.fastFusion.content')}</p>
					<FastFusionAdvantages type="ff3d" />
				</FastFusion>
			</BlockCutter>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.theReport.title'),
				}}
			>
				<Report />
			</BlockCutter>
			<BlockCutter
				bottom="out"
				className={Background.BLACK}
				style={{
					paddingLeft: '0',
					paddingRight: '0',
				}}
				title={{
					color: Color.AT_WHITE_PRIMARY,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.simple.title'),
				}}
				top="out"
			>
				<HowItWorks>
					{isSmall ? <WorkflowMobile /> : <WorkflowDesktop />}
					<p>{t('views.product.sections.simple.content1')}</p>
				</HowItWorks>
			</BlockCutter>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.product.sections.youFly.title'),
				}}
				top="out"
			>
				<YouFly>
					<div>
						{!isLarge || <img alt="drone kit" src={droneKit} />}
						<div className="bold">
							{t('views.product.sections.youFly.left.title')}
						</div>
						<div
							className="text"
							dangerouslySetInnerHTML={{
								// eslint-disable-next-line @typescript-eslint/naming-convention
								__html: t('views.product.sections.youFly.left.content'),
							}}
						/>
						<div className="button">
							<TryForFree type="primary" />
						</div>
					</div>
					<div>
						<div className="bold">
							{t('views.product.sections.youFly.right.title')}
						</div>
						<div className="white-card">
							<div className="bolder">
								{t('views.product.sections.youFly.right.content')}
							</div>
							{!isLarge && <img alt="drone kit" src={droneKit} />}
							<ul>
								<li>{t('views.product.sections.youFly.right.item1')}</li>
								<li>{t('views.product.sections.youFly.right.item2')}</li>
								<li>{t('views.product.sections.youFly.right.item3')}</li>
								<li>{t('views.product.sections.youFly.right.item4')}</li>
							</ul>
						</div>
						<div className="button">
							<Button
								label={t('buttons.discoverPricing')}
								type="primary"
								onClick={() =>
									openInNewTab(`${PRICING_PATH}#${DRONE_PACKAGE_ELEMENT_ID}`)
								}
							/>
						</div>
					</div>
				</YouFly>
			</BlockCutter>
			<BlockCutter
				bottom="out"
				button={<TryForFree type="yellow" />}
				buttonContainerStyle={{ marginTop: '40px' }}
				className={Background.WHITE}
				style={{
					paddingBottom: isSmall ? '80px' : undefined,
				}}
				title={{
					color: Color.AT_BLACK_1,
					lineColor: Color.AT_YELLOW,
					text: t('views.product.sections.weFly.title'),
				}}
				top="out"
			>
				<WeFlyText
					dangerouslySetInnerHTML={{
						// eslint-disable-next-line @typescript-eslint/naming-convention
						__html: t('views.product.sections.weFly.description'),
					}}
				/>
				<Advantages>
					<div>
						<div className="img-container">
							<img alt="Money" src={advantagesWorryFree} />
						</div>
						<h4>{t('views.product.sections.advantages.worryFree.title')}</h4>
						<p>{t('views.product.sections.advantages.worryFree.text')}</p>
					</div>
					<div>
						<div className="img-container">
							<img alt="Stair" src={advantagesFast} />
						</div>
						<h4>{t('views.product.sections.advantages.fast.title')}</h4>
						<p>{t('views.product.sections.advantages.fast.text')}</p>
					</div>
					<div>
						<div className="img-container">
							<img alt="Support" src={advantagesFormat} />
						</div>
						<h4>{t('views.product.sections.advantages.format.title')}</h4>
						<p>{t('views.product.sections.advantages.format.text')}</p>
					</div>
				</Advantages>
			</BlockCutter>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_1,
					lineColor: Color.AT_YELLOW,
					text: t('views.product.sections.trustUs.title'),
				}}
				top="in"
			>
				<TrustUs>
					<div>
						<div className="number">
							<Ticker
								duration={5}
								end={1000}
								separator={tickerSeparator}
								suffix="+"
							/>
						</div>
						<div className="text">
							{t('views.product.sections.trustUs.content1')}
						</div>
					</div>
					<div>
						<div className="number">
							<Ticker
								duration={5}
								end={20000}
								separator={tickerSeparator}
								suffix="+"
							/>
						</div>
						<div className="text">
							{t('views.product.sections.trustUs.content2')}
						</div>
					</div>
					<div>
						<div className="number">
							<Ticker
								duration={5}
								end={5000000}
								separator={tickerSeparator}
								suffix="+ m²"
							/>
						</div>
						<div className="text">
							{t('views.product.sections.trustUs.content3')}
						</div>
					</div>
				</TrustUs>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const PRODUCT_PATH = '/product' as const

export { ProductView }
