import React from 'react'
import styled from 'styled-components'

import { Modal } from './Modal'

const VideoWrapper = styled.div`
	position: relative;
	height: 0;
	// 16:9
	padding-bottom: 56.25%;
	padding-top: 25px;

	img {
		position: absolute;
		top: 0%;
		left: 0%;
		width: 100%;
		height: 100%;
	}
`
const VideoModal = ({
	iFrameTitle,
	onClose,
	video,
}: {
	iFrameTitle: string
	isPlayList?: boolean
	onClose: () => void
	video: string
}) => {
	return (
		<Modal onClose={onClose}>
			<VideoWrapper>
				<img id="sunny_gif" src={video} />
			</VideoWrapper>
		</Modal>
	)
}

export { VideoModal }
