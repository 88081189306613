import { CACHE_VERSION } from '../data'

export const getCacheKey = (key: string) =>
	`airteam-state-${CACHE_VERSION}-${key}`

export const saveState = <T>(
	key: string,
	value: T,
	serializer: (value: T) => string,
) => {
	try {
		window.localStorage.setItem(getCacheKey(key), serializer(value))
	} catch (error) {
		// localStorage unavailable, do nothing
	}
}

export const loadState = <T>(
	key: string,
	defaultValue: T,
	parser: (value: string) => T,
) => {
	try {
		const value = window.localStorage.getItem(getCacheKey(key))

		if (value === null) return defaultValue

		return parser(value)
	} catch (error) {
		// localStorage unavailable, fall-back
		return defaultValue
	}
}
