import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BlockCutter } from '../../components/BlockCutter'
import { PageHeader } from '../../components/PageHeader'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'

import titleBackground from './assets/title-background.jpg'

import { TextBlock } from './index'

const ImprintView = () => {
	useScrollToTop()
	document.title = 'Impressum | AIRTEAM'
	const { t } = useTranslation()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.legal.imprint.title')}
			/>
			<BlockCutter top="in">
				<TextBlock>
					<Trans
						components={{
							bold: <strong />,
						}}
						i18nKey="views.legal.imprint.text"
					/>
				</TextBlock>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const IMPRINT_PATH = '/imprint' as const

export { ImprintView }
