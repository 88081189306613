import { Locale } from 'locale-enum'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../../components/BlockCutter'
import { Heading } from '../../../components/Heading'
import { QuoteHeadline } from '../../../components/QuoteHeadline'
import { YoutubeVideoModal } from '../../../components/YoutubeVideoModal'
import { YoutubeVideoPlayIcon } from '../../../components/YoutubeVideoPlayIcon'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'
import pvSolIntegrationImage from '../assets/advantages/solar/solar-video.png'
import sunnyIntegrationImage from '../assets/advantages/solar/sunny_integration_img.png'
import sunnyImage from '../assets/advantages/solar/sunny.svg'
import error from '../assets/old-new/icons/error.svg'
import fast from '../assets/old-new/icons/fast.svg'
import hourglass from '../assets/old-new/icons/hourglass.svg'
import secure from '../assets/old-new/icons/secure.svg'
import standardized from '../assets/old-new/icons/standardized.svg'
import fall from '../assets/old-new/icons/fall.svg'
import thumbdown from '../assets/old-new/icons/thumbdown.svg'
import realEstateLeft from '../assets/old-new/illustrations/real-estate-left.png'
import realEstateRight from '../assets/old-new/illustrations/real-estate-right.png'
import roofingSolarLeftDe from '../assets/old-new/illustrations/roofing-solar-left-de.png'
import roofingSolarLeftEn from '../assets/old-new/illustrations/roofing-solar-left-en.svg'
import roofingSolarRightDe from '../assets/old-new/illustrations/roofing-solar-right-de.png'
import roofingSolarRightEn from '../assets/old-new/illustrations/roofing-solar-right-en.svg'
import surveyingLeft from '../assets/old-new/illustrations/surveying-left.svg'
import surveyingRight from '../assets/old-new/illustrations/surveying-right.svg'
import facadeLeft from '../assets/old-new/illustrations/facade-left.svg'
import facadeRight from '../assets/old-new/illustrations/facade-right.svg'
import { IndustryType } from '../types'
import { Button } from '../../../components/Button'
import { openInNewTab } from '../../../utilities/open-in-new-tab'
import { VideoModal } from '../../../components/VideoModal'

const Card = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;

	background: #ffffff;

	box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
	position: relative;
	padding-top: 30px;

	.img {
		position: absolute;
		left: -50px;
		right: -50px;
		bottom: -200px;
		height: 400px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		@media ${mediaBreakpoint.mediumMinus} {
			margin-bottom: 20px;
		}

		@media ${mediaBreakpoint.small} {
			margin-bottom: 10px;
		}
	}

	.title {
		text-align: center;
		flex: 1;
	}

	@media ${mediaBreakpoint.mediumMinus} {
		margin: 0 10px;
		padding-bottom: 200px;

		.title {
			${getFont(Font.MOBILE_H3_BOLD)};
		}
	}

	@media ${mediaBreakpoint.large} {
		margin: 0 80px 250px 80px;
		padding-bottom: 250px;

		.title {
			${getFont(Font.H4_BOLD)};
		}
	}
`

const Twos = styled.div`
	display: flex;

	> * {
		flex: 1;
	}

	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
		margin-bottom: 170px;

		> *:nth-child(1) {
			z-index: 42;
		}

		> *:not(:first-child) {
			margin-top: 170px;
		}
	}

	@media ${mediaBreakpoint.large} {
		flex-direction: row;
	}
`

const Three = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	> * {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;

		> .img-container {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			> img {
				max-width: 100%;
			}
		}

		> *:not(:first-child) {
			margin-top: 10px;
		}
	}

	@media ${mediaBreakpoint.mediumMinus} {
		${getFont(Font.MOBILE_B3_BOLD)};
		margin: 15px;
	}

	@media ${mediaBreakpoint.large} {
		${getFont(Font.B1_BOLD)};
		margin: 0 120px;
	}

	.description {
		white-space: nowrap;

		@media ${mediaBreakpoint.large} {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
`

const PVSolIntegration = styled.div`
	margin-top: 120px;

	@media ${mediaBreakpoint.small} {
		margin-top: 210px;
	}
`

const PVSolIntegrationContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
	}

	.text {
		@media ${mediaBreakpoint.mediumMinus} {
			${getFont(Font.MOBILE_B1_REGULAR)};
			margin-bottom: 25px;
			text-align: center;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B0_REGULAR)};
			box-sizing: border-box;
			padding: 0 5%;

			width: 60%;
		}
	}
	.video-image {
		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media ${mediaBreakpoint.mediumPlus} {
			width: 40%;
		}

		> img {
			width: 100%;
		}
	}
	.flex-end {
		display: flex;
		flex-direction: row-reverse;
	}
	.video-hint {
		margin-bottom: 16px;
		color: black;
		font-weight: 400;
		font-size: 23px;
	}
	.video-button {
		margin-top: 16px;
		cursor: pointer;
		z-index: 999;
	}
	.sunny-image {
		cursor: pointer;
	}
`

const IndustrySection1Quote2OldNew = ({ type }: { type: IndustryType }) => {
	const { i18n, t } = useTranslation()
	const { isLarge } = useBreakpoints()

	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
	const [isSunnyVideoModalOpen, setIsSunnyVideoModalOpen] = useState(false)

	const firstThree = (
		<Three>
			<div>
				<div className="img-container">
					<img alt="hourglass" src={hourglass} />
				</div>
				<div className="description">
					{t('views.industry.sections.oldNew.timeConsuming')}
				</div>
			</div>
			<div>
				<div className="img-container">
					<img
						alt="fall"
						src={type === IndustryType.REAL_ESTATE ? thumbdown : fall}
					/>
				</div>
				<div className="description">
					{type === IndustryType.REAL_ESTATE
						? t('views.industry.sections.oldNew.inconsistentQuality')
						: t('views.industry.sections.oldNew.unsafe')}
				</div>
			</div>
			<div>
				<div className="img-container">
					<img alt="error" src={error} />
				</div>
				<div className="description">
					{t('views.industry.sections.oldNew.errorProne')}
				</div>
			</div>
		</Three>
	)

	const secondThree = (
		<Three>
			<div>
				<div className="img-container">
					<img alt="fast" src={fast} />
				</div>
				<div className="description">
					{t('views.industry.sections.oldNew.fast')}
				</div>
			</div>
			<div>
				<div className="img-container">
					<img alt="secure" src={secure} />
				</div>
				<div className="description">
					{t('views.industry.sections.oldNew.safe')}
				</div>
			</div>
			<div>
				<div className="img-container">
					<img alt="standardized" src={standardized} />
				</div>
				<div className="description">
					{t('views.industry.sections.oldNew.standardized')}
				</div>
			</div>
		</Three>
	)

	return (
		<BlockCutter bottom="in" top="in">
			<QuoteHeadline color={Color.AT_BLACK_NOT_1} lineColor={Color.AT_GREEN_1}>
				{t(`views.industry.${type}.quote`)}
			</QuoteHeadline>
			<Twos>
				<div>
					<Card>
						<div className="title">
							{t('views.industry.sections.oldNew.leftTitle')}
						</div>
						{!isLarge && firstThree}
						<div
							className="img"
							style={{
								backgroundImage: `url(${
									type === IndustryType.FACADE
										? facadeLeft
										: type === IndustryType.SURVEYING
										? surveyingLeft
										: type === IndustryType.REAL_ESTATE
										? realEstateLeft
										: i18n.language === Locale.en_US
										? roofingSolarLeftEn
										: roofingSolarLeftDe
								})`,
							}}
						/>
					</Card>
					{isLarge && firstThree}
				</div>
				<div>
					<Card>
						<div className="title">
							{t('views.industry.sections.oldNew.rightTitle')}
						</div>
						{!isLarge && secondThree}
						<div
							className="img"
							style={{
								backgroundImage: `url(${
									type === IndustryType.FACADE
										? facadeRight
										: type === IndustryType.SURVEYING
										? surveyingRight
										: type === IndustryType.REAL_ESTATE
										? realEstateRight
										: i18n.language === Locale.en_US
										? roofingSolarRightEn
										: roofingSolarRightDe
								})`,
							}}
						/>
					</Card>
					{isLarge && secondThree}
				</div>
			</Twos>
			{type === IndustryType.SOLAR && (
				<>
					<PVSolIntegration>
						<Heading
							color={Color.AT_BLACK_1}
							lineColor={Color.AT_GREEN_1}
							text={t('views.industry.sections.sunnyProIntegration.title')}
						/>
						<PVSolIntegrationContent>
							<div className="video-image">
								<div className="video-hint">
									<Trans
										i18nKey={
											'views.industry.sections.sunnyProIntegration.video'
										}
									/>
								</div>
								<img
									alt="fast fusion 3d"
									className="fast-fusion-devices"
									src={sunnyIntegrationImage}
								/>
								<YoutubeVideoPlayIcon
									onClick={() => setIsSunnyVideoModalOpen(true)}
								/>
								<Button
									className="video-button"
									label={t(
										'views.industry.sections.sunnyProIntegration.button',
									)}
									type="yellow"
									onClick={() => openInNewTab('/sunny.pdf')}
								/>
							</div>
							<div className="text">
								<div className="flex-end">
									<a
										href="https://www.sma.de/en/products/apps-software/sunny-design"
										target="__blank"
									>
										<img
											alt="fast fusion 3d"
											className="sunny-image"
											src={sunnyImage}
										/>
									</a>
								</div>
								<Trans
									i18nKey={
										'views.industry.sections.sunnyProIntegration.content1'
									}
								/>
								<br />
								<br />
								<Trans
									i18nKey={
										'views.industry.sections.sunnyProIntegration.content2'
									}
								/>
							</div>
						</PVSolIntegrationContent>
					</PVSolIntegration>
					<PVSolIntegration>
						<Heading
							color={Color.AT_BLACK_1}
							lineColor={Color.AT_GREEN_1}
							text={t('views.industry.sections.pvSolIntegration.title')}
						/>
						<PVSolIntegrationContent>
							<div className="text">
								<Trans
									i18nKey={'views.industry.sections.pvSolIntegration.content'}
								/>
							</div>
							<div className="video-image">
								<img
									alt="fast fusion 3d"
									className="fast-fusion-devices"
									src={pvSolIntegrationImage}
								/>
								<YoutubeVideoPlayIcon
									onClick={() => setIsVideoModalOpen(true)}
								/>
							</div>
						</PVSolIntegrationContent>
					</PVSolIntegration>
				</>
			)}
			{isVideoModalOpen && (
				<YoutubeVideoModal
					iFrameTitle="FastFusion3D Youtube iFrame"
					videoOrPlayListId="pRhMwvg30NE"
					onClose={() => setIsVideoModalOpen(false)}
				/>
			)}
			{isSunnyVideoModalOpen && (
				<VideoModal
					iFrameTitle="Sunny iFrame"
					video="/sunny_gif.gif"
					onClose={() => setIsSunnyVideoModalOpen(false)}
				/>
			)}
		</BlockCutter>
	)
}

export { IndustrySection1Quote2OldNew }
