import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function useScrollToElementId() {
	const location = useLocation()

	useLayoutEffect(() => {
		if (location.hash) {
			const elem = document.getElementById(location.hash.slice(1))
			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}, [location.hash])
}
