import React from 'react'
import { useTranslation } from 'react-i18next'

import { Advantages } from '../../../components/Advantages'
import { BlockCutter } from '../../../components/BlockCutter'
import { TryForFree } from '../../../components/TryForFree'
import { Background, Color } from '../../../theme'
import advantageRoofing1 from '../assets/advantages/roofing/1.svg'
import advantageRoofing2 from '../assets/advantages/roofing/2.svg'
import advantageRoofing3 from '../assets/advantages/roofing/3.svg'
import advantageSolar1 from '../assets/advantages/solar/1.svg'
import advantageSolar2 from '../assets/advantages/solar/2.svg'
import advantageSolar3 from '../assets/advantages/solar/3.svg'
import advantageSurveying1 from '../assets/advantages/surveying/1.svg'
import advantageSurveying2 from '../assets/advantages/surveying/2.svg'
import advantageSurveying3 from '../assets/advantages/surveying/3.svg'
import advantageRealEstate1 from '../assets/advantages/real_estate/1.svg'
import advantageRealEstate2 from '../assets/advantages/real_estate/2.svg'
import advantageRealEstate3 from '../assets/advantages/real_estate/3.svg'
import advantageFacade1 from '../assets/advantages/facade/1.svg'
import advantageFacade2 from '../assets/advantages/facade/2.svg'
import advantageFacade3 from '../assets/advantages/facade/3.svg'
import { IndustryType } from '../types'

const IndustrySection3Advantages = ({ type }: { type: IndustryType }) => {
	const { t } = useTranslation()

	return (
		<BlockCutter
			bottom="out"
			button={<TryForFree />}
			className={Background.WHITE}
			title={{
				color: Color.AT_BLACK_1,
				lineColor: Color.AT_GREEN_1,
				text: t('views.industry.advantagesTitle'),
			}}
			top="out"
		>
			<Advantages>
				<div>
					<div className="img-container">
						<img
							alt="Money"
							src={
								{
									[IndustryType.ROOFING]: advantageRoofing1,
									[IndustryType.SOLAR]: advantageSolar1,
									[IndustryType.SURVEYING]: advantageSurveying1,
									[IndustryType.REAL_ESTATE]: advantageRealEstate1,
									[IndustryType.FACADE]: advantageFacade1,
								}[type]
							}
						/>
					</div>
					<h4>
						{
							{
								[IndustryType.ROOFING]: t(
									'views.industry.roofing.advantage1.title',
								),
								[IndustryType.SOLAR]: t(
									'views.industry.solar.advantage1.title',
								),
								[IndustryType.SURVEYING]: t(
									'views.industry.surveying.advantage1.title',
								),
								[IndustryType.REAL_ESTATE]: t(
									'views.industry.realEstate.advantage1.title',
								),
								[IndustryType.FACADE]: t(
									'views.industry.facade.advantage1.title',
								),
							}[type]
						}
					</h4>
					<p>
						{
							{
								[IndustryType.ROOFING]: t(
									'views.industry.roofing.advantage1.text',
								),
								[IndustryType.SOLAR]: t('views.industry.solar.advantage1.text'),
								[IndustryType.SURVEYING]: t(
									'views.industry.surveying.advantage1.text',
								),
								[IndustryType.REAL_ESTATE]: t(
									'views.industry.realEstate.advantage1.text',
								),
								[IndustryType.FACADE]: t(
									'views.industry.facade.advantage1.text',
								),
							}[type]
						}
					</p>
				</div>
				<div>
					<div className="img-container">
						<img
							alt="Stair"
							src={
								{
									[IndustryType.ROOFING]: advantageRoofing2,
									[IndustryType.SOLAR]: advantageSolar2,
									[IndustryType.SURVEYING]: advantageSurveying2,
									[IndustryType.REAL_ESTATE]: advantageRealEstate2,
									[IndustryType.FACADE]: advantageFacade2,
								}[type]
							}
						/>
					</div>
					<h4>
						{
							{
								[IndustryType.ROOFING]: t(
									'views.industry.roofing.advantage2.title',
								),
								[IndustryType.SOLAR]: t(
									'views.industry.solar.advantage2.title',
								),
								[IndustryType.SURVEYING]: t(
									'views.industry.surveying.advantage2.title',
								),
								[IndustryType.REAL_ESTATE]: t(
									'views.industry.realEstate.advantage2.title',
								),
								[IndustryType.FACADE]: t(
									'views.industry.facade.advantage2.title',
								),
							}[type]
						}
					</h4>
					<p>
						{
							{
								[IndustryType.ROOFING]: t(
									'views.industry.roofing.advantage2.text',
								),
								[IndustryType.SOLAR]: t('views.industry.solar.advantage2.text'),
								[IndustryType.SURVEYING]: t(
									'views.industry.surveying.advantage2.text',
								),
								[IndustryType.REAL_ESTATE]: t(
									'views.industry.realEstate.advantage2.text',
								),
								[IndustryType.FACADE]: t(
									'views.industry.facade.advantage2.text',
								),
							}[type]
						}
					</p>
				</div>
				<div>
					<div className="img-container">
						<img
							alt="Support"
							src={
								{
									[IndustryType.ROOFING]: advantageRoofing3,
									[IndustryType.SOLAR]: advantageSolar3,
									[IndustryType.SURVEYING]: advantageSurveying3,
									[IndustryType.REAL_ESTATE]: advantageRealEstate3,
									[IndustryType.FACADE]: advantageFacade3,
								}[type]
							}
						/>
					</div>
					<h4>
						{
							{
								[IndustryType.ROOFING]: t(
									'views.industry.roofing.advantage3.title',
								),
								[IndustryType.SOLAR]: t(
									'views.industry.solar.advantage3.title',
								),
								[IndustryType.SURVEYING]: t(
									'views.industry.surveying.advantage3.title',
								),
								[IndustryType.REAL_ESTATE]: t(
									'views.industry.realEstate.advantage3.title',
								),
								[IndustryType.FACADE]: t(
									'views.industry.facade.advantage3.title',
								),
							}[type]
						}
					</h4>
					<p>
						{
							{
								[IndustryType.ROOFING]: t(
									'views.industry.roofing.advantage3.text',
								),
								[IndustryType.SOLAR]: t('views.industry.solar.advantage3.text'),
								[IndustryType.SURVEYING]: t(
									'views.industry.surveying.advantage3.text',
								),
								[IndustryType.REAL_ESTATE]: t(
									'views.industry.realEstate.advantage3.text',
								),
								[IndustryType.FACADE]: t(
									'views.industry.facade.advantage3.text',
								),
							}[type]
						}
					</p>
				</div>
			</Advantages>
		</BlockCutter>
	)
}

export { IndustrySection3Advantages }
