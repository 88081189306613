import React from 'react'
import styled from 'styled-components'

import { Color, mediaBreakpoint } from '../../theme'

/**
 * @see {@link https://fontawesome.com/icons?m=free} for icon names
 */
const LINKS: Array<{ icon: string; label: string; link: string }> = [
	{
		icon: 'fab fa-facebook-f',
		label: 'Facebook',
		link: 'https://www.facebook.com/Airteam.Aerial.Intelligence',
	},
	{
		icon: 'fab fa-twitter',
		label: 'Twitter',
		link: 'https://twitter.com/Airteam_ai',
	},
	{
		icon: 'fab fa-instagram',
		label: 'Instagram',
		link: 'https://www.instagram.com/airteam.ai/',
	},
	{
		icon: 'fab fa-linkedin-in',
		label: 'LinkedIn',
		link: 'https://www.linkedin.com/company/airteamaerialintelligence/',
	},
	{
		icon: 'fab fa-youtube',
		label: 'YouTube',
		link: 'https://www.youtube.com/channel/UC0vw-vN521JWFNKGOpIBouQ?sub_confirmation=1',
	},
]

const SocialLinksStyle = styled.div`
	display: inline-flex;
	align-items: center;

	@media ${mediaBreakpoint.mediumMinus} {
		margin: 0 auto 5px auto;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		/**
		 * @see {@link https://github.com/airteam-aerial-intelligence/web-2.0/issues/1}
		 */
		margin-left: -12px;
	}

	> a {
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 12px; /* use padding instead of margin to increase tap area for mobile users */

		cursor: pointer;
		font-size: 24px;
		color: inherit;
		text-decoration: none;

		:active {
			color: ${Color.AT_GREEN_1};
		}

		:hover:not(:active) {
			color: ${Color.CTA_HOVER};
		}
	}
`

const FooterSocialLinks = () => (
	<SocialLinksStyle>
		{LINKS.map((link, index) => (
			<a
				href={link.link}
				key={index}
				rel="noopener noreferrer"
				target="_blank"
				onClick={(event) => event.currentTarget.blur()}
			>
				<i className={link.icon} title={link.label} />
			</a>
		))}
	</SocialLinksStyle>
)

export { FooterSocialLinks }
