import { i18n } from '../i18n'

export enum Currency {
	EUR = 'EUR',
}

export const formatCurrency = (
	amount: number,
	currency: Currency = Currency.EUR, // FIXME: Remove default when other currencies are supported
) => {
	const formatter = new Intl.NumberFormat(i18n.language, {
		currency,
		style: 'currency',
	})
	return formatter.format(amount)
}

export const formatCurrencyInteger = (
	amount: number,
	currency: Currency = Currency.EUR, // FIXME: Remove default when other currencies are supported
) => {
	if (Math.round(amount) !== amount)
		throw new Error('encountered non-integer currency amount')

	const formatter = new Intl.NumberFormat(i18n.language, {
		currency,
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
		style: 'currency',
	})
	return formatter.format(amount)
}

export const formatArea = (amount: number) => {
	if (Math.round(amount) !== amount)
		throw new Error('encountered non-integer currency amount')

	const formatter = new Intl.NumberFormat(i18n.language, {
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})
	return formatter.format(amount)
}
