import { useEffect, useState } from 'react'

import { webappApi } from '../../utilities/axios-api'

const URL = '/project/classification' as const

/* eslint-disable @typescript-eslint/naming-convention */
export type ProjectClassificationType = {
	name: string
	customer_piloted: boolean | null
	max_surface: number
	max_num_of_deliverables: number
	price?: string
}

export const useProjectClassifications = () => {
	const [isProjectClassificationsLoading, setIsProjectClassificationsLoading] =
		useState(true)
	const [projectClasses, setProjectClasses] = useState<
		ProjectClassificationType[]
	>([])

	useEffect(() => {
		const request = webappApi.get<ProjectClassificationType[]>(URL)

		request
			.then(({ data }) => setProjectClasses(data))
			.catch((error) => console.error('failed to load project classes', error))
			.finally(() => setIsProjectClassificationsLoading(false))
	}, [])

	return {
		projectClasses,
		isProjectClassificationsLoading,
	}
}
