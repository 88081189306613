import { css } from 'styled-components'

export enum Background {
	AT_GRAY_4 = 'background-at-gray-4',
	BLACK = 'background-black',
	GRAY_DOTTED = 'background-gray-dotted',
	WHITE = 'background-white',
}

export enum Breakpoint {
	SMALL = 360,
	MEDIUM = 768,
	LARGE = 1440,
}

/* eslint-disable sort-keys */
export const mediaBreakpoint = {
	large: `(min-width: ${Breakpoint.LARGE}px)`,
	largeMinus: `(max-width: ${Breakpoint.LARGE - 1}px)`,
	medium: `(min-width: ${Breakpoint.MEDIUM}px) and (max-width: ${
		Breakpoint.LARGE - 1
	}px)`,
	mediumMinus: `(max-width: ${Breakpoint.LARGE - 1}px)`,
	mediumPlus: `(min-width: ${Breakpoint.MEDIUM}px)`,
	small: `(max-width: ${Breakpoint.MEDIUM - 1}px)`,
}
/* eslint-enable sort-keys */

export enum Color {
	/**
	 * Body text and headlines
	 */
	AT_BLACK_NOT_1 = '#161303',

	AT_BLACK_TEXT = '#212722',

	AT_BLACK_1 = '#1E2525',

	AT_BLACK_2 = '#213435',

	AT_BLACK_3 = '#2f313e',

	/**
	 * gray for dots on dark dotted backgrounds
	 */
	AT_GRAY = '#3D4949',

	NOT_AT_GRAY = '#F6F6F6',

	AT_GRAY_2 = '#61696B',

	AT_GRAY_3 = '#C5C7C8',

	AT_GRAY_4 = '#E2DFDF',

	AT_GRAY_5 = '#D1D1D1',

	AT_GRAY_6 = '#F2F2F2',

	AT_YELLOW = '#F6AE1E',

	AT_YELLOW_2 = '#BA0070',
	AT_YELLOW_2_HOVER = '#ff7dcb',

	AT_YELLOW_3 = '#DAA520',

	AT_YELLOW_4 = '#F6AE1E',

	AT_OCHRE = '#B07C13',

	/**
	 * white backgrounds and text on dark
	 */
	AT_WHITE_PRIMARY = '#FBFAFD',

	/**
	 * bright dotted backgrounds
	 */
	BRIGHT_DOTTED_BACKGROUND_WHITE = '#F0F0F0',

	/**
	 * gray for dots on bright dotted backgrounds and for grey elements
	 */
	BRIGHT_DOTTED_BACKGROUND_GRAY = '#C5C7C8',

	/**
	 * Dark Dotted Backgrounds
	 */
	DARK_DOTTED_BACKGROUNDS = '#1E2525',

	// Aka. CTA_PRIMARY aka. Airteam-Green
	AT_GREEN_1 = '#23E7A5',
	AT_GREEN_2 = '#099483',
	PURPLE = '#674FA1',
	LIGHT_PURPLE = '#c1b7db',

	CTA_HOVER = '#78FFD2',

	CTA_SECONDARY = '#F6AE1E',

	CTA_SECONDARY_HOVER = '#FFD770',

	/**
	 * links within body text and success messages
	 */
	SUCCESS = '#099483',

	TRANSPARENT = 'transparent',

	WARNING_RED = '#CE3131',

	WHATEVER_GREEN = '#5C8A7A',

	CLASS_S = '#808080',
	CLASS_M = '#EAB144',
	CLASS_L = '#429283',
	CLASS_XL = '#AA236E',
	CLASS_SPECIAL = '#23E7A5',

	LINK = '#0D69D4',
}

export enum Font {
	B0_BOLD = 'B0_BOLD',
	B0_QUOTATION = 'B0_QUOTATION',
	B0_REGULAR = 'B0_REGULAR',
	B1_BOLD = 'B1_BOLD',
	B1_REGULAR = 'B1_REGULAR',
	B2_REGULAR = 'B2_REGULAR',
	BIG_BUTTON_TEXT = 'BIG_BUTTON_TEXT',
	CAPTION_BIG_14 = 'CAPTION_BIG_14',
	CAPTION_SMALL_12 = 'CAPTION_SMALL_12',
	H0 = 'H0',
	H1 = 'H1',
	H2_SEMI_BOLD = 'H2_SEMI_BOLD',
	H3_BOLD = 'H3_BOLD',
	H3_MEDIUM = 'H3_MEDIUM',
	H4_BOLD = 'H4_BOLD',
	H4_MEDIUM = 'H4_MEDIUM',
	MOBILE_B1_BOLD = 'MOBILE_B1_BOLD',
	MOBILE_B1_REGULAR = 'MOBILE_B1_REGULAR',
	MOBILE_B2_BOLD = 'MOBILE_B2_BOLD',
	MOBILE_B2_REGULAR = 'MOBILE_B2_REGULAR',
	MOBILE_B3_BOLD = 'MOBILE_B3_BOLD',
	MOBILE_B3_REGULAR = 'MOBILE_B3_REGULAR',
	MOBILE_BUTTON = 'MOBILE_BUTTON',
	MOBILE_CAPTION_REGULAR = 'MOBILE_CAPTION_REGULAR',
	MOBILE_H0_BOLD = 'MOBILE_H0_BOLD',
	MOBILE_H1_BOLD = 'MOBILE_H1_BOLD',
	MOBILE_H2_BOLD = 'MOBILE_H2_BOLD',
	MOBILE_H3_BOLD = 'MOBILE_H3_BOLD',
	MOBILE_H4_MEDIUM = 'MOBILE_H4_MEDIUM',
	MOBILE_H4_BOLD = 'MOBILE_H4_BOLD',
	MOBILE_QUOTATION = 'MOBILE_QUOTATION',
}

export const getFont = (font: Font): ReturnType<typeof css> => {
	switch (font) {
		case Font.B0_BOLD:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: bold;
				font-size: 23px;
				line-height: 143%;
				letter-spacing: 0.01em;
			`

		case Font.B0_QUOTATION:
			return css`
				font-family: Manrope;
				font-size: 23px;
				font-style: italic;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.01em;
			`

		case Font.B0_REGULAR:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: normal;
				font-size: 23px;
				line-height: 150%;
				letter-spacing: 0.01em;
			`

		case Font.B1_BOLD:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: bold;
				font-size: 17px;
				line-height: 150%;
				letter-spacing: 0.01em;
			`

		case Font.B1_REGULAR:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 150%;
				letter-spacing: 0.01em;
			`

		case Font.B2_REGULAR:
			return css`
				font-family: Manrope;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 25px;
				letter-spacing: 0.02em;
			`

		case Font.BIG_BUTTON_TEXT:
			return css`
				font-family: Manrope;
				font-size: 17px;
				font-style: normal;
				font-weight: 700;
				line-height: 23px;
				letter-spacing: 0.06em;
			`

		case Font.CAPTION_BIG_14:
			return css`
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0.01em;
			`

		case Font.CAPTION_SMALL_12:
			return css`
				font-family: Manrope;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: 0.02em;
			`

		case Font.H0:
			return css`
				font-family: Manrope;
				font-size: 50px;
				font-style: normal;
				font-weight: 600;
				line-height: 75px;
				letter-spacing: 0.01em;
			`

		case Font.H1:
			return css`
				font-family: Manrope;
				font-size: 48px;
				font-style: normal;
				font-weight: 600;
				line-height: 69px;
				letter-spacing: 0.01em;
			`

		case Font.H2_SEMI_BOLD:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				font-size: 42px;
				line-height: 144%;
				letter-spacing: 0.01em;
			`

		case Font.H3_BOLD:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				font-size: 34px;
				line-height: 144%;
				letter-spacing: 0.01em;
			`

		case Font.H3_MEDIUM:
			return css`
				font-family: Manrope;
				font-size: 34px;
				font-style: normal;
				font-weight: 200;
				line-height: 49px;
				letter-spacing: 0.01em;
			`

		case Font.H4_BOLD:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: bold;
				font-size: 27px;
				line-height: 144%;
				letter-spacing: 0.01em;
			`

		case Font.H4_MEDIUM:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				font-size: 27px;
				line-height: 144%;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_B1_BOLD:
			return css`
				font-family: Manrope;
				font-size: 17px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_B1_REGULAR:
			return css`
				font-family: Manrope;
				font-size: 17px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_B2_BOLD:
			return css`
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: 20px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_B2_REGULAR:
			return css`
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_B3_BOLD:
			return css`
				font-family: Manrope;
				font-size: 13px;
				font-style: normal;
				font-weight: 700;
				line-height: 18px;
				letter-spacing: 0.01em;
			`
		case Font.MOBILE_B3_REGULAR:
			return css`
				font-family: Manrope;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_BUTTON:
			return css`
				font-family: Manrope;
				font-size: 13px;
				font-style: normal;
				font-weight: 700;
				line-height: 18px;
				letter-spacing: 0.06em;
			`

		case Font.MOBILE_CAPTION_REGULAR:
			return css`
				font-family: Manrope;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_H0_BOLD:
			return css`
				font-family: Manrope;
				font-size: 31px;
				font-style: normal;
				font-weight: 600;
				line-height: 45px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_H1_BOLD:
			return css`
				font-family: Manrope;
				font-size: 25px;
				font-style: normal;
				font-weight: 600;
				line-height: 36px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_H2_BOLD:
			return css`
				font-family: Manrope;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 29px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_H3_BOLD:
			return css`
				font-family: Manrope;
				font-size: 19px;
				font-style: normal;
				font-weight: 600;
				line-height: 29px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_H4_BOLD:
			return css`
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 144%;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_H4_MEDIUM:
			return css`
				font-family: Manrope;
				font-size: 18px;
				font-style: normal;
				font-weight: 200;
				line-height: 26px;
				letter-spacing: 0.01em;
			`

		case Font.MOBILE_QUOTATION:
			return css`
				font-family: Manrope;
				font-size: 18px;
				font-style: italic;
				font-weight: 400;
				line-height: 27px;
				letter-spacing: 0.01em;
			`
	}
}

export const makePattern = (color: Color, distance: number, size = 1) => {
	const PREFIX = 'data:image/svg+xml;base64,'
	const content = [
		`<svg viewBox="0 0 ${distance} ${distance}" xmlns="http://www.w3.org/2000/svg">`,
		`<path fill="${color}" d="M0 0h${size}v${size}H0z" />`,
		`</svg>`,
	]

	return `${PREFIX}${btoa(content.join(''))}`
}
