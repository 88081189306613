import React from 'react'

import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { PricingViewRealEstate } from '../pricing/sections/Section2PricesRealEstate'

import { IndustryHeader } from './components/Header'
import { IndustrySection1Quote2OldNew } from './components/Section1Quote2OldNew'
import { IndustrySection3Advantages } from './components/Section3Advantages'
import { IndustrySection5Results } from './components/Section5Results'
import { IndustryType } from './types'

const IndustryRealEstateView = () => {
	useScrollToTop()
	document.title = 'Immobilien | AIRTEAM'
	return (
		<DefaultPageLayout>
			<IndustryHeader type={IndustryType.REAL_ESTATE} />
			<IndustrySection1Quote2OldNew type={IndustryType.REAL_ESTATE} />
			<IndustrySection3Advantages type={IndustryType.REAL_ESTATE} />
			<IndustrySection5Results type={IndustryType.REAL_ESTATE} />
			<PricingViewRealEstate />
		</DefaultPageLayout>
	)
}

export const INDUSTRY_REAL_ESTATE_PATH = '/industry/real-estate' as const

export { IndustryRealEstateView }
