import React from 'react'
import styled from 'styled-components'

import { Color } from '../../theme'

const FieldNumberStyle = styled.div`
	width: 85%;

	input {
		width: 100%;
		box-shadow: 5px 5px 7px 0 rgba(232, 232, 232, 0.77) inset;
		border-radius: 3px;
		border: 0.5px solid rgba(251, 250, 253, 1);
		padding: 20px 15px;
		font-weight: bold;
		font-size: 19px;
		text-align: right;
		background: linear-gradient(0deg, #f6f6f6, #f6f6f6),
			linear-gradient(0deg, #fbfafd, #fbfafd);

		::placeholder {
			color: ${Color.AT_BLACK_1};
			opacity: 0.4;
		}

		:focus {
			outline: none;
		}
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
`

const FieldNumber = ({
	maxValue,
	minValue,
	placeholder,
	setValue,
	size,
	value,
}: {
	minValue?: number | null
	maxValue?: number | null
	placeholder: string | null
	setValue: (newValue: number) => void
	value: number
	size?: number
}) => {
	const validateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseFloat(e.target.value)

		let isValid = true
		if ((maxValue && value > maxValue) || (minValue && value < minValue)) {
			isValid = false
		}

		if (isValid) setValue(value)
	}

	return (
		<FieldNumberStyle>
			<input
				max={maxValue ?? undefined}
				min={minValue ?? undefined}
				placeholder={placeholder ?? undefined}
				size={size ?? 1}
				type="number"
				value={value}
				onChange={validateNumber}
				onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()}
			/>
		</FieldNumberStyle>
	)
}

FieldNumber.defaultProps = {
	placeholder: null,
}

export { FieldNumber }
