import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { BlogEntryType } from '../../../store/modules/blogs'
import { Color, Font, getFont } from '../../../theme'
import { BLOG_DETAIL_PATH } from '../DetailView'

const Section = styled.section<{ type: 'short' | 'long' }>`
	color: ${Color.AT_BLACK_NOT_1};

	padding: 40px;
	background-color: ${Color.AT_WHITE_PRIMARY};
	box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
	border-radius: 10px;

	h2 {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 42px;
		line-height: 144%;
		letter-spacing: 0.01em;
		margin: 0;

		${({ type }) =>
			type === 'short' &&
			css`
				/* https://stackoverflow.com/a/50566101 */
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				cursor: pointer;

				:hover {
					text-decoration: underline ${Color.AT_GREEN_1} 2.5px;
				}

				@supports (-webkit-line-clamp: 2) {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: initial;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			`}
	}

	.meta {
		font-family: Mulish;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 150%;
		letter-spacing: 0.01em;
	}

	.content {
		font-family: Mulish;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		line-height: 143%;
		letter-spacing: 0.01em;

		${getFont(Font.B2_REGULAR)};

		a {
			color: ${Color.AT_GREEN_2};
			text-decoration: underline;

			:hover {
				color: ${Color.AT_OCHRE};
			}
		}

		h4 {
			${getFont(Font.H4_BOLD)};
		}

		blockquote {
			border-left: 1px solid ${Color.AT_BLACK_NOT_1};
			padding-left: 30px;
			${getFont(Font.B0_QUOTATION)};
		}

		hr {
			background-color: ${Color.AT_GREEN_1};
			height: 2px;
			border: none;
			margin: 42px 0;
		}
	}

	> *:not(:first-child) {
		margin-top: 27px;
	}

	img {
		display: block;
		width: 100%;
	}
`

const MoreLink = styled.div`
	cursor: pointer;
	color: ${Color.AT_GREEN_2};

	:hover {
		color: ${Color.AT_OCHRE};
	}
`

const fixLinks = (html: string) =>
	html.replace(/<a/g, '<a target="_blank" rel="noreferrer noopener"')

const BlogEntry = ({
	type,
	...entry
}: BlogEntryType & { type: 'short' | 'long' }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const more = useCallback(
		() =>
			history.push(
				BLOG_DETAIL_PATH.replace(':id', String(entry.id)).replace(
					':slug',
					entry.slug,
				),
			),
		[history, entry.id, entry.slug],
	)

	const introduction = fixLinks(entry.einleitung)

	return (
		<Section {...{ type }}>
			<h2 onClick={more}>{entry.titel}</h2>
			<div className="meta">
				{dayjs(entry.created_at).format('D MMMM YYYY')}
			</div>
			<div
				className="content"
				// eslint-disable-next-line @typescript-eslint/naming-convention
				dangerouslySetInnerHTML={{ __html: introduction }}
			/>
			<figure>
				<img alt={entry.bild_caption ?? undefined} src={entry.bild} />
				{entry.bild_caption && <figcaption>{entry.bild_caption}</figcaption>}
			</figure>
			{type === 'short' && (
				<MoreLink onClick={more}>{t('views.blog.entry.readMore')}</MoreLink>
			)}
			{type === 'long' &&
				entry.paragraphs.map((p) => (
					<div className="content" key={p.id}>
						<h4>{p.titel}</h4>
						{p.bild && <img alt={p.bild_caption ?? undefined} src={p.bild} />}
						<div
							dangerouslySetInnerHTML={{
								// eslint-disable-next-line @typescript-eslint/naming-convention
								__html: fixLinks(p.text),
							}}
						/>
					</div>
				))}
		</Section>
	)
}

export { BlogEntry }
