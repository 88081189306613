import { Locale } from 'locale-enum'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../../components/BlockCutter'
import { Button } from '../../../components/Button'
import { Carousel } from '../../../components/Carousel'
import { RequestSubscriptionOrderingModal } from '../../../components/RequestSubscriptionOrderingModal'
import { formatCurrencyInteger } from '../../../formatters/currency'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, mediaBreakpoint, Font, getFont } from '../../../theme'
import { openInNewTab } from '../../../utilities/open-in-new-tab'
import subscriptionsSavings from '../assets/subscriptions-savings.svg'
import { CardsContainer } from '../components/CardsContainer'
import { CardProps, PricingCard } from '../components/PricingCard'
import { TopContent } from '../components/TopContentGrid'

const Tabs = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 24px;

	button {
		padding: 15px 50px;
		border: 1px solid #8c9fa7;
		background-color: white;
		color: black;
		cursor: pointer;

		&:first-child {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			border-right: none;
		}

		&:last-child {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			border-left: none;
		}

		@media ${mediaBreakpoint.largeMinus} {
			padding: 12px 30px;
		}
	}

	.active-button {
		background-color: black;
		color: white;
	}
`

const TextContent = styled.p`
	text-align: center;
	width: 90%;
	margin: 0 auto 50px auto;

	@media ${mediaBreakpoint.mediumMinus} {
		${getFont(Font.MOBILE_B1_BOLD)}
	}
	@media ${mediaBreakpoint.mediumPlus} {
		${getFont(Font.B0_BOLD)}
	}

	.highlight {
		color: #0d69d4;
		cursor: pointer;
	}
`

const PricingViewSection2Subscriptions = () => {
	const { t, i18n } = useTranslation()
	const { isLarge } = useBreakpoints()
	const [isCardExpanded, setIsCardExpanded] = useState<boolean | null>(false)
	const currentLanguage = i18n.language
	const [activeTab, setActiveTab] = useState(0)

	const changeIsCardExpanded = () => {
		setIsCardExpanded(!isCardExpanded)
	}

	const handleTabChange = (index: number) => {
		setActiveTab(index)
	}

	const getAnnualPrice = (price: number) => {
		if (activeTab === 0) {
			return null
		} else if (activeTab === 1) {
			return t('views.pricing.pricingCard.price1', {
				price: formatCurrencyInteger(price),
			})
		} else {
			return t('views.pricing.pricingCard.price2', {
				price: formatCurrencyInteger(price),
			})
		}
	}

	const packageOptions = [
		{
			label: t('views.pricing.sections.subscriptions.modal.fastFusion3d'),
			value: 'Fast Fusion 3D',
		},
		{
			label: t('views.pricing.sections.subscriptions.modal.proFusion3d'),
			value: 'Pro Fusion AI',
		},
		{
			label: t('views.pricing.sections.subscriptions.modal.maxFusion3d'),
			value: 'Max Fusion AI',
		},
		{
			label: t('views.pricing.sections.subscriptions.modal.ultraFusion3d'),
			value: 'Ultra Fusion AI',
		},
	]

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(
		packageOptions[0].value,
	)

	const includedInPricePink2 = t(
		'views.pricing.pricingCard.includedInPricePink21',
	)

	const subscriptionPricingCards = useCallback((): CardProps[] => {
		return [
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText1'),
				button: (
					<Button
						label={t('views.pricing.pricingCard.tryFastFusion')}
						type="gray"
						onClick={() => {
							if (activeTab === 0)
								openInNewTab('https://buy.stripe.com/3cs8yadWscqjcCsaEG')
							else if (activeTab === 1)
								openInNewTab('https://buy.stripe.com/6oEbKm9Gc1LFeKAfZf')
							else openInNewTab('https://buy.stripe.com/00gaGi8C875ZeKA28q')
						}}
					/>
				),
				color: Color.AT_GRAY_2,
				includedInPrice: [
					t('views.pricing.pricingCard.subscriptionSubtext1'),
					t('views.pricing.pricingCard.includedInPrice1_1', { area: 300 }),
					t('views.pricing.pricingCard.includedInPrice2', { nutzer: 1 }),
					t('views.pricing.pricingCard.includedInPrice7'),
					t('views.pricing.pricingCard.includedInPrice5'),
					t('views.pricing.pricingCard.includedInPrice6'),
				],
				includedInPriceSpecial: [],
				price: {
					amount: activeTab === 0 ? 150 : activeTab === 1 ? 125 : 105,
					amountAnnual: getAnnualPrice(activeTab === 1 ? 1500 : 2500),
					disclaimer: t('views.pricing.pricingCard.priceDisclaimer1'),
					disclaimerSmall: t(
						'views.pricing.pricingCard.priceDisclaimer1-small',
					),
					amountBiennial: t('views.pricing.pricingCard.price1_2'),
					disclaimer2: t('views.pricing.pricingCard.priceDisclaimer1_2'),
					disclaimer2Small: t(
						'views.pricing.pricingCard.priceDisclaimer1_2-small',
					),
					subtext: t('views.pricing.pricingCard.priceSubtext1'),
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext1'),
				title: 'Fast Fusion',
				isCardExpanded: isCardExpanded,
				changeIsCardExpanded: changeIsCardExpanded,
				activeTab,
			},
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText2'),
				button: (
					<Button
						label={t('views.pricing.pricingCard.tryProFusion')}
						type="yellow"
						onClick={() => {
							if (activeTab === 0)
								openInNewTab('https://buy.stripe.com/cN2dSu8C88a36e49AE')
							else if (activeTab === 1)
								openInNewTab('https://buy.stripe.com/6oE29McSo3TNbyo28o')
							else openInNewTab('https://buy.stripe.com/bIYdSu6u03TNfOEbJ1')
						}}
					/>
				),
				color: Color.AT_YELLOW,
				includedInPrice: [
					t('views.pricing.pricingCard.credits', { credits: 5 }),
					t('views.pricing.pricingCard.includedInPrice1', { area: 500 }),
					t('views.pricing.pricingCard.unlimited3dModels'),
					t('views.pricing.pricingCard.includedInPrice23'),
					t('views.pricing.pricingCard.unlimitedUsersAndPilots'),
					t('views.pricing.pricingCard.includedInPrice44'),
					t('views.pricing.pricingCard.includedInPrice5'),
					t('views.pricing.pricingCard.includedInPrice44_2', {
						credits: 5,
						cost: 150,
					}),
					t('views.pricing.pricingCard.includedInPrice8'),
				],
				includedInPriceSpecial: [],
				price: {
					amount: activeTab === 0 ? 350 : activeTab === 1 ? 292 : 250,
					amountAnnual: getAnnualPrice(activeTab === 1 ? 3500 : 6000),
					disclaimer: t('views.pricing.pricingCard.priceDisclaimer2'),
					disclaimerSmall: t(
						'views.pricing.pricingCard.priceDisclaimer2-small',
					),
					amountBiennial: t('views.pricing.pricingCard.price2_2'),
					disclaimer2: t('views.pricing.pricingCard.priceDisclaimer2_2'),
					disclaimer2Small: t(
						'views.pricing.pricingCard.priceDisclaimer2_2-small',
					),
					subtext: t('views.pricing.pricingCard.priceSubtext1'),
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext2'),
				title: 'Pro Fusion',
				isCardExpanded: isCardExpanded,
				changeIsCardExpanded: changeIsCardExpanded,
				activeTab,
			},
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText3'),
				button: (
					<Button
						label={
							activeTab === 0
								? t('views.pricing.pricingCard.tryMaxFusion')
								: t('views.pricing.pricingCard.tryMaxFusion2')
						}
						type="darkgreen"
						onClick={() => {
							if (activeTab === 0)
								openInNewTab('https://buy.stripe.com/dR629M7y4cqj0TK8wB')
							else {
								setSelectedSubscriptionPlan(packageOptions[2].value)
								setIsModalOpen(true)
							}
						}}
					/>
				),
				color: Color.AT_GREEN_2,
				includedInPrice: [
					t('views.pricing.pricingCard.credits', { credits: 50 }),
					t('views.pricing.pricingCard.includedInPrice1', {
						area: 5000,
					}),
					t('views.pricing.pricingCard.unlimited3dModels'),
					t('views.pricing.pricingCard.includedInPrice23'),
					t('views.pricing.pricingCard.unlimitedUsersAndPilots'),
					t('views.pricing.pricingCard.includedInPrice4'),
					t('views.pricing.pricingCard.includedInPrice5'),
					t('views.pricing.pricingCard.includedInPrice44_2', {
						credits: 50,
						cost: 500,
					}),
					t('views.pricing.pricingCard.includedInPrice20'),
				],
				includedInPriceSpecial: [],
				price: {
					amount: activeTab === 0 ? 1200 : activeTab === 1 ? 1000 : 834,
					amountAnnual: getAnnualPrice(activeTab === 1 ? 12000 : 20000),
					disclaimer: t('views.pricing.pricingCard.priceDisclaimer3'),
					disclaimerSmall: t(
						'views.pricing.pricingCard.priceDisclaimer3-small',
					),
					amountBiennial: t('views.pricing.pricingCard.price3_2'),
					disclaimer2: t('views.pricing.pricingCard.priceDisclaimer3_2'),
					disclaimer2Small: t(
						'views.pricing.pricingCard.priceDisclaimer3_2-small',
					),
					subtext: t('views.pricing.pricingCard.priceSubtext1'),
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext3'),
				title: 'Max Fusion',
				isCardExpanded: isCardExpanded,
				changeIsCardExpanded: changeIsCardExpanded,
				activeTab,
			},
			{
				bottomText: t('views.pricing.pricingCard.subscriptionBottomText4'),
				button: (
					<Button
						label={t('views.pricing.pricingCard.tryUltraFusion')}
						type="fushia"
						onClick={() => {
							setSelectedSubscriptionPlan(packageOptions[3].value)
							setIsModalOpen(true)
						}}
					/>
				),
				color: Color.AT_YELLOW_2,
				includedInPrice: [
					t('views.pricing.pricingCard.credits', { credits: 500 }),
					t('views.pricing.pricingCard.includedInPrice1', {
						area: 5000,
					}),
					t('views.pricing.pricingCard.unlimited3dModels'),
					t('views.pricing.pricingCard.includedInPrice23'),

					t('views.pricing.pricingCard.unlimitedUsersAndPilots'),
					t('views.pricing.pricingCard.includedInPrice4'),
					t('views.pricing.pricingCard.includedInPrice5'),
					t('views.pricing.pricingCard.includedInPrice44_2', {
						credits: 500,
						cost: 2500,
					}),
					t('views.pricing.pricingCard.includedInPrice20'),
				],
				includedInPriceSpecial: [includedInPricePink2],
				isNew: true,
				price: {
					amount: activeTab === 0 ? 5000 : activeTab === 1 ? 4167 : 3750,
					amountAnnual: getAnnualPrice(activeTab === 1 ? 50000 : 90000),
					disclaimer: t('views.pricing.pricingCard.priceDisclaimer4'),
					disclaimerSmall: t(
						'views.pricing.pricingCard.priceDisclaimer4-small',
					),
					amountBiennial: t('views.pricing.pricingCard.price4_2'),
					disclaimer2: t('views.pricing.pricingCard.priceDisclaimer4_2'),
					disclaimer2Small: t(
						'views.pricing.pricingCard.priceDisclaimer4_2-small',
					),
					subtext: t('views.pricing.pricingCard.priceSubtext1'),
				},
				subtext: t('views.pricing.pricingCard.subscriptionSubtext33'),
				title: 'Ultra Fusion',
				isCardExpanded: isCardExpanded,
				changeIsCardExpanded: changeIsCardExpanded,
				activeTab,
			},
		]
	}, [t, packageOptions, isCardExpanded, includedInPricePink2])

	const pricingCards = useMemo(
		() =>
			subscriptionPricingCards().map((card) => (
				<PricingCard {...card} key={card.title} />
			)),
		[subscriptionPricingCards],
	)

	const downloadPriceSheetButton = useCallback(() => {
		return (
			<Button
				label={t(
					'views.pricing.sections.subscriptions.downloadPriceSheetButton',
				)}
				onClick={() =>
					openInNewTab(
						currentLanguage === Locale.de_DE
							? '/Airteam Preise - Flatrates - Sep 2024.pdf'
							: '/Airteam Preise - Flatrates - Sep 2024 ENG.pdf',
					)
				}
			></Button>
		)
	}, [t])

	return (
		<BlockCutter
			button={downloadPriceSheetButton()}
			style={{
				paddingLeft: isLarge ? undefined : 0,
				paddingRight: isLarge ? undefined : 0,
			}}
			title={{
				color: Color.AT_BLACK_NOT_1,
				lineColor: Color.AT_YELLOW,
				text: t('views.pricing.sections.subscriptions.title'),
			}}
		>
			<TopContent>
				<img alt="Subscriptions savings" src={subscriptionsSavings} />
				<p>{t('views.pricing.sections.subscriptions.textContent')}</p>
			</TopContent>
			<Tabs>
				<button
					className={activeTab === 0 ? 'active-button' : ''}
					onClick={() => handleTabChange(0)}
				>
					{t('views.pricing.sections.subscriptions.monthly')}
				</button>
				<button
					className={activeTab === 1 ? 'active-button' : ''}
					onClick={() => handleTabChange(1)}
				>
					{t('views.pricing.sections.subscriptions.yearly')}
				</button>
				<button
					className={activeTab === 2 ? 'active-button' : ''}
					onClick={() => handleTabChange(2)}
				>
					{t('views.pricing.sections.subscriptions.biyearly')}
				</button>
			</Tabs>
			{isLarge && <CardsContainer>{pricingCards}</CardsContainer>}
			{!isLarge && <Carousel items={pricingCards} />}
			<TextContent>{t('views.pricing.pricingCard.vatDisclaimer')}</TextContent>
			{isModalOpen && (
				<RequestSubscriptionOrderingModal
					activeTab={activeTab}
					onClose={() => setIsModalOpen(false)}
					selectedTypeValue={selectedSubscriptionPlan}
					title={t('views.pricing.sections.subscriptions.modal.title')}
				/>
			)}
		</BlockCutter>
	)
}

export { PricingViewSection2Subscriptions }
