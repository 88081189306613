import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useBreakpoints } from '../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { AGB_PATH } from '../../views/legal/AGBView'
import { IMPRINT_PATH } from '../../views/legal/ImprintView'
import { PRIVACY_POLICY_PATH } from '../../views/legal/PrivacyPolicyView'

const SectionLegal = styled.section`
	border-top: 1px solid ${Color.AT_GRAY};
	color: ${Color.AT_GRAY};
	padding: 30px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> *:not(:first-child) {
		margin-top: 20px;
	}

	${getFont(Font.B1_REGULAR)}

	@media ${mediaBreakpoint.small} {
		${getFont(Font.MOBILE_B3_REGULAR)}
	}
`

const SectionLegalSeparator = styled.div`
	width: 1px;
	height: 1em;
	margin: 0 20px;
`

const SectionLegalItems = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	color: #6f807a;

	${SectionLegalSeparator} {
		background-color: ${Color.AT_GRAY};
	}
`

const SectionLegalLink = styled.span`
	color: inherit;

	text-decoration: none;

	:hover {
		color: ${Color.CTA_HOVER};
		cursor: pointer;
	}
`

const CopyrightNotice = styled.div`
	text-align: center;
	color: #6f807a;
`

const FooterLegalSection = () => {
	const { isSmall } = useBreakpoints()
	const { t } = useTranslation()
	const history = useHistory()

	const legalContent = useMemo<Array<{ label: string; onClick(): void }>>(
		() => [
			{
				label: t('footer.sectionLegal.privacyPolicy'),
				onClick: () => history.push(PRIVACY_POLICY_PATH),
			},
			{
				label: t('footer.sectionLegal.termsOfService'),
				onClick: () => history.push(AGB_PATH),
			},
			{
				label: t('footer.sectionLegal.imprint'),
				onClick: () => history.push(IMPRINT_PATH),
			},
		],
		[t, history],
	).flatMap((content, index) => {
		const jsx = (
			<SectionLegalLink key={index}>
				<span onClick={() => content.onClick()}>{content.label}</span>
			</SectionLegalLink>
		)

		return index === 0
			? jsx
			: [<SectionLegalSeparator key={`${index}-separator`} />, jsx]
	})

	const copyrightNotice = (
		<CopyrightNotice>{t('footer.sectionLegal.copyright')}</CopyrightNotice>
	)

	return (
		<SectionLegal>
			<div className="container">
				<SectionLegalItems>
					{!isSmall && (
						<>
							{copyrightNotice}
							<SectionLegalSeparator />
						</>
					)}
					{legalContent}
				</SectionLegalItems>
				{isSmall && (
					<>
						<br />
						{copyrightNotice}
					</>
				)}
			</div>
		</SectionLegal>
	)
}

export { FooterLegalSection }
