import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../../components/BlockCutter'
import { Button } from '../../../components/Button'
import { ARROW_HEIGHT_LARGE, ARROW_HEIGHT_MEDIUM } from '../../../data'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'
import result1facade from '../assets/results/1-facade.svg'
import result1realEstate from '../assets/results/1-real-estate.svg'
import result1solar from '../assets/results/1-solar.svg'
import result1surveying from '../assets/results/1-surveying.svg'
import result1 from '../assets/results/1.svg'
import result2facade from '../assets/results/2-facade.svg'
import result2realEstate from '../assets/results/2-real-estate.svg'
import result2solar from '../assets/results/2-solar.svg'
import result2surveying from '../assets/results/2-surveying.svg'
import result2 from '../assets/results/2.svg'
import result3realEstate from '../assets/results/3-real-estate.svg'
import result3roofing from '../assets/results/3-roofing.svg'
import result3solar from '../assets/results/3-solar.svg'
import chiaraAengevelt from '../assets/results/people/chiara-aengevelt.png'
import felixWeisbrich from '../assets/results/people/felix-weisbrich.jpg'
import heikoSowade from '../assets/results/people/heiko-sowade.png'
import tobiasEbelhaeuser from '../assets/results/people/tobias-ebelhaeuser.png'
import { IndustryType } from '../types'
import { formatLang } from '../../../utilities/format-lang'

const PEOPLE: Record<IndustryType, { name: string; image: string }> = {
	[IndustryType.ROOFING]: {
		image: heikoSowade,
		name: 'Heiko Sowade',
	},
	[IndustryType.SOLAR]: {
		image: tobiasEbelhaeuser,
		name: 'Tobias Ebelhaeuser',
	},
	[IndustryType.SURVEYING]: {
		image: felixWeisbrich,
		name: 'Felix Weisbrich',
	},
	[IndustryType.REAL_ESTATE]: {
		image: chiaraAengevelt,
		name: 'Chiara Aengevelt',
	},
	[IndustryType.FACADE]: {
		image: heikoSowade,
		name: 'Heiko Sowade',
	},
}

const Content = styled.div`
		display: flex;
		justify-content: stretch;
		flex-direction: column;

		margin-bottom: 30px;

		@media ${mediaBreakpoint.medium} {
			margin-bottom: 100px;
			align-items: flex-start;
		}

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;
			flex: 1;

			p {
				flex: 1;
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
			}

			@media ${mediaBreakpoint.small} {
				margin-bottom: 20px;
			}
			@media ${mediaBreakpoint.medium} {
				margin-bottom: 40px;

				align-items: center;
				flex-direction: row;
			}
		}

		@media ${mediaBreakpoint.small} {
			flex-direction: column;
			text-align: center;

			${getFont(Font.MOBILE_B2_REGULAR)};

			img {
				max-width: 125px;
			}

			p {
				padding: 10px;
			}

			> * {
				flex-direction: row;
			}
		}

		@media ${mediaBreakpoint.medium} {
			flex-direction: column;

			${getFont(Font.B1_BOLD)};

			div > div {
				display: flex;

				flex-direction: column;
				align-items: flex-start;

				margin-left: 5%;
			}

			img {
				max-width: 200px;
			}

			p {
				margin-bottom: 40px;
			}


			> * {
				flex-direction: row;
				text-align: left;
			}


		}

		@media ${mediaBreakpoint.large} {
			text-align: center;
			flex-direction: row;

			${getFont(Font.B0_BOLD)};

			img {
				height: 450px;
			}

			p {
				padding: 50px;
			}

			> * {
				flex-direction: column;

				> *:not(:first-child) {
					margin-top: 50px;
				}
			}
		}
	}

	button {
		margin: auto;

		@media ${mediaBreakpoint.large} {
			margin-bottom: 85px;
		}
`

const SampleReport = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media ${mediaBreakpoint.small} {
		margin: 25px 0;

		flex-direction: column;

		button {
			width: 285px;
		}

		a:not(:last-of-type) {
			margin-bottom: 20px;
		}
	}
	@media ${mediaBreakpoint.mediumPlus} {
		margin-bottom: 88px;

		button {
			width: 360px;
		}

		a:not(:last-of-type) {
			margin-right: 20px;
		}
	}
`

const Person = styled.section`
	background: ${Color.AT_WHITE_PRIMARY};
	box-shadow: 10px 10px 29px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
	overflow: hidden;

	/* stripes */

	position: relative;
	:after,
	:before {
		position: absolute;
		top: 200px;
		content: '';
		height: 10px;
		background-color: ${Color.AT_GREEN_1};
	}

	@media ${mediaBreakpoint.small} {
		padding: 32px;
		${getFont(Font.MOBILE_B1_BOLD)};

		img {
			margin-bottom: 30px;
			width: 100%;
			border-radius: 3px;
			overflow: hidden;
		}

		.name {
			${getFont(Font.H4_BOLD)};
		}

		.title {
			${getFont(Font.CAPTION_BIG_14)};
			margin-bottom: 30px;
		}

		.content {
			${getFont(Font.MOBILE_QUOTATION)};
			margin-bottom: 40px;
		}

		/* stripes */

		:after,
		:before {
			width: 18px;
		}

		:after {
			right: -18px;
		}

		:before {
			left: -18px;
		}
	}

	@media ${mediaBreakpoint.large} {
		margin: 0 67px ${ARROW_HEIGHT_LARGE}px 67px;

		/* stripes */

		:after,
		:before {
			width: 155px;
		}

		:after {
			right: -155px;
		}

		:before {
			left: -155px;
		}
	}

	@media ${mediaBreakpoint.medium} {
		margin: 0 30px ${ARROW_HEIGHT_MEDIUM}px 30px;

		/* stripes */

		:after,
		:before {
			width: 88px;
		}

		:after {
			right: -88px;
		}

		:before {
			left: -88px;
		}
	}

	@media ${mediaBreakpoint.mediumPlus} {
		display: flex;
		align-items: stretch;

		> article {
			padding: 45px;
			flex: 1;
			display: flex;
			flex-direction: column;

			.name {
				font-family: Manrope;
				font-style: normal;
				font-weight: bold;
				font-size: 27px;
				line-height: 144%;
				letter-spacing: 0.01em;
				color: ${Color.AT_BLACK_NOT_1};
			}

			.title {
				font-family: Mulish;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 194%;
				letter-spacing: 0.01em;
				color: ${Color.AT_BLACK_NOT_1};
			}

			.content {
				padding: 30px 0;
				flex: 1;
				font-family: Mulish;
				font-style: italic;
				font-weight: normal;
				font-size: 23px;
				line-height: 150%;
				letter-spacing: 0.01em;

				color: ${Color.AT_BLACK_TEXT};
			}
		}

		${getFont(Font.B1_BOLD)};
	}
`

const IndustrySection5Results = ({ type }: { type: IndustryType }) => {
	const { i18n, t } = useTranslation()
	const { isLarge } = useBreakpoints()
	const currentLanguage = i18n.language

	// @ts-comment
	// @ts-comment
	// @ts-comment
	return (
		<BlockCutter
			bottom="in"
			title={{
				color: Color.AT_BLACK_NOT_1,
				lineColor: Color.AT_GREEN_1,
				text: t('views.industry.resultsTitle'),
			}}
			top={isLarge ? 'out' : 'in'}
		>
			<Content>
				<div>
					<img
						alt=""
						src={
							{
								[IndustryType.ROOFING]: result1,
								[IndustryType.SOLAR]: result1solar,
								[IndustryType.SURVEYING]: result1surveying,
								[IndustryType.REAL_ESTATE]: result1realEstate,
								[IndustryType.FACADE]: result1facade,
							}[type]
						}
					/>
					<div>
						<p>{t(`views.industry.${type}.sections.results.content1`)}</p>
						{type === IndustryType.SOLAR && (
							<a
								href={`https://ffsolar.airteam.cloud/sample?lang=${formatLang(
									currentLanguage,
								)}`}
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.solar.sections.results.button1`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.ROOFING && (
							<a
								href="https://airteam.cloud/fast-fusion-preview"
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.roofing.sections.results.button1`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.REAL_ESTATE && (
							<a
								href="/pricing-examples/inspection-data.jpg" // TODO CHANGE TO CORRECT PICTURES
								target="noreferrer noopener"
							>
								<Button
									label={t(
										`views.industry.realEstate.sections.results.button1`,
									)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.FACADE && (
							<a
								href="https://airteam.cloud/fast-fusion-preview"
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.facade.sections.results.button1`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
					</div>
				</div>
				<div>
					<img
						alt=""
						src={
							{
								[IndustryType.ROOFING]: result2,
								[IndustryType.SOLAR]: result2solar,
								[IndustryType.SURVEYING]: result2surveying,
								[IndustryType.REAL_ESTATE]: result2realEstate,
								[IndustryType.FACADE]: result2facade,
							}[type]
						}
					/>
					<div>
						<p>{t(`views.industry.${type}.sections.results.content2`)}</p>
						{type === IndustryType.SOLAR && (
							<a
								href="https://airteam.cloud/fast-fusion-thermal-preview"
								target="__blank"
							>
								<Button
									label={t(`views.industry.solar.sections.results.button2`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.ROOFING && (
							<a
								href="/pricing-examples/inspection-data.jpg"
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.roofing.sections.results.button2`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.REAL_ESTATE && (
							<a
								href="/pricing-examples/inspection-data.jpg"
								target="noreferrer noopener"
							>
								<Button
									label={t(
										`views.industry.realEstate.sections.results.button2`,
									)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.FACADE && (
							<a
								href="/pricing-examples/inspection-data.jpg"
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.facade.sections.results.button2`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
					</div>
				</div>
				<div>
					<img
						alt=""
						src={
							{
								[IndustryType.ROOFING]: result3roofing,
								[IndustryType.SOLAR]: result3solar,
								[IndustryType.SURVEYING]: result1,
								[IndustryType.REAL_ESTATE]: result3realEstate,
								[IndustryType.FACADE]: result3realEstate,
							}[type]
						}
					/>
					<div>
						<p>{t(`views.industry.${type}.sections.results.content3`)}</p>
						<br />
						{type === IndustryType.SOLAR && (
							<a href="/sunny.pdf" target="noreferrer noopener">
								<Button
									label={t(
										`views.industry.sections.sunnyProIntegration.button`,
									)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.ROOFING && (
							<a
								href="/Airteam_Muster_Vermessungsbericht_Flachdach_Pro_Dachvermessung_mit_Drohne.pdf"
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.roofing.sections.results.button3`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.REAL_ESTATE && (
							<a
								href="https://airteam.cloud/fast-fusion-preview"
								target="noreferrer noopener"
							>
								<Button
									label={t(
										`views.industry.realEstate.sections.results.button3`,
									)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
						{type === IndustryType.FACADE && (
							<a
								href="/Sample_Fassade_Projekt.pdf"
								target="noreferrer noopener"
							>
								<Button
									label={t(`views.industry.facade.sections.results.button3`)}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						)}
					</div>
				</div>
			</Content>
			{type !== IndustryType.SOLAR &&
				type !== IndustryType.ROOFING &&
				type !== IndustryType.REAL_ESTATE &&
				type !== IndustryType.FACADE && (
					<SampleReport>
						<a
							href={
								{
									[IndustryType.ROOFING]:
										'/Airteam_Muster_Vermessungsbericht_Standard_Dachvermessung_mit_Drohne.pdf',
									[IndustryType.SOLAR]:
										'/Airteam_Muster_Vermessungsbericht_Standard_Solarplanung_3D_Dachvermessung_mit_Drohne.pdf',
									[IndustryType.SURVEYING]: '/Orthofoto_Pop-Up-Fahrradwege.jpg',
									[IndustryType.REAL_ESTATE]:
										'/Orthofoto_Pop-Up-Fahrradwege.jpg', // TODO CHANGE TO CORRECT CASE STUDY
									[IndustryType.FACADE]: '/Orthofoto_Pop-Up-Fahrradwege.jpg', // TODO CHANGE TO CORRECT CASE STUDY
								}[type]
							}
							target="noreferrer noopener"
						>
							<Button
								label={
									type === IndustryType.SURVEYING
										? t('views.industry.surveryingResultsButton')
										: // @ts-comment
										type === IndustryType.ROOFING
										? t('views.industry.steepResultsButton')
										: t('views.industry.resultsButton')
								}
								type="yellow"
								onClick={() => undefined}
							/>
						</a>
					</SampleReport>
				)}
			{type !== 'facade' && (
				<Person>
					<img alt={PEOPLE[type].name} src={PEOPLE[type].image} />
					<article>
						<div className="name">{PEOPLE[type].name}</div>
						<div className="title">
							{t(`views.industry.${type}.results.personTitle`)}
						</div>
						<div className="content">
							{t(`views.industry.${type}.results.personQuote`)}
						</div>
						<div className="button">
							<a
								href={
									{
										[IndustryType.ROOFING]:
											'/case-studies/Airteam_Fallstudie_Dach_-_Dachvermessung_mit_Drohne.pdf',
										[IndustryType.SOLAR]:
											'/case-studies/Airteam_Fallstudie_Solar_-_Dachvermessung_fuer_eine_Photovoltaikanlage_mit_Drohne.pdf',
										[IndustryType.SURVEYING]:
											'/case-studies/Airteam_Fallstudie_Vermessung_-_Berliner_Fahrradwege_per_Drohne_vermessen.pdf',
										[IndustryType.REAL_ESTATE]:
											'/case-studies/2022_03_01_Airteam Case study Immobillien.pdf',
										[IndustryType.FACADE]:
											'/case-studies/Airteam_Fallstudie_Dach_-_Dachvermessung_mit_Drohne.pdf',
									}[type]
								}
								rel="noopener noreferrer"
								target="_blank"
							>
								<Button
									label={t('views.industry.resultsReadCaseStudy')}
									type="yellow"
									onClick={() => undefined}
								/>
							</a>
						</div>
					</article>
				</Person>
			)}
		</BlockCutter>
	)
}

export { IndustrySection5Results }
