import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { INDUSTRY_CARPENTER_URL } from '../../../data'
import { ABOUT_PATH } from '../../../views/about/View'
import { CONTACT_PATH } from '../../../views/contact/View'
import { INDUSTRY_FACADE_PATH } from '../../../views/industry/FacadeView'
import { INDUSTRY_REAL_ESTATE_PATH } from '../../../views/industry/RealEstateView'
import { INDUSTRY_ROOFING_PATH } from '../../../views/industry/RoofingView'
import { INDUSTRY_SOLAR_PATH } from '../../../views/industry/SolarView'
import { INDUSTRY_SURVEYING_PATH } from '../../../views/industry/SurveyingView'
import { PRICING_PATH } from '../../../views/pricing/View'
import { PRODUCT_PATH } from '../../../views/product/View'
import { Menu } from '../types'
import { PARTNER_PATH } from '../../../views/partner/View'

export const useMenu = () => {
	const { t } = useTranslation()

	return {
		menu: useMemo(
			(): Menu => [
				{
					label: t('header.menu.product'),
					to: PRODUCT_PATH,
					type: 'link',
				},
				{
					children: [
						{
							label: t('header.menu.industries.roofing'),
							to: INDUSTRY_ROOFING_PATH,
							type: 'link',
						},
						{
							label: t('header.menu.industries.solar'),
							to: INDUSTRY_SOLAR_PATH,
							type: 'link',
						},
						{
							label: t('header.menu.industries.facade'),
							to: INDUSTRY_FACADE_PATH,
							type: 'link',
						},
						{
							label: t('header.menu.industries.surveying'),
							to: INDUSTRY_SURVEYING_PATH,
							type: 'link',
						},
						{
							label: t('header.menu.industries.realEstate'),
							to: INDUSTRY_REAL_ESTATE_PATH,
							type: 'link',
						},
						{
							label: t('header.menu.industries.carpenter'),
							to: INDUSTRY_CARPENTER_URL,
							type: 'external',
						},
					],
					label: t('header.menu.industries.label'),
					type: 'nested',
				},
				{
					label: t('header.menu.prices'),
					to: PRICING_PATH,
					type: 'link',
				},
				{
					label: t('header.menu.partner'),
					to: PARTNER_PATH,
					type: 'external',
				},
				{
					label: t('header.menu.about'),
					to: ABOUT_PATH,
					type: 'link',
				},
				{
					label: t('header.menu.contact'),
					to: CONTACT_PATH,
					type: 'link',
				},
			],
			[t],
		),
	}
}
