import React from 'react'
import { KNOWLEDGE_BASE_URL } from '../../data'
import { createGlobalStyle } from 'styled-components'

const KnowledgeBaseGlobalStyles = createGlobalStyle`
  #root {
		zoom: 100% !important;
		overflow: hidden;
	}

`
const KnowledgeBaseView = () => {
	return (
		<>
			<KnowledgeBaseGlobalStyles />
			<div style={{ height: '100vh', width: '100%' }}>
				<iframe
					src={KNOWLEDGE_BASE_URL}
					style={{ border: 'none', width: '100%', height: '100%' }}
					title="Knowledge Base Page"
				/>
			</div>
		</>
	)
}

export const KNOWLEDGE_BASE_PATH = '/helpcenter' as const

export { KnowledgeBaseView }
