import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Color, Font, getFont, mediaBreakpoint } from '../theme'

import { Button } from './Button'

export type ImageCardProps = {
	buttonLabel: string
	image: string
	path?: string | null
	text: string
	title: string
}

const ImageCardStyle = styled.section`
	position: relative;
	box-shadow: 3px 6px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: ${Color.AT_WHITE_PRIMARY};
	overflow: hidden;

	h2 {
		color: ${Color.AT_OCHRE};
		margin: 0;
	}

	> .image-card__image {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		background-size: cover;
		background-position: center center;
		clip-path: polygon(0 0, 100% 45%, 100% 100%, 0 100%);
	}

	> .image-card__content {
		color: ${Color.AT_BLACK_2};
	}

	.read-more {
		position: absolute;
		bottom: 30px;
		left: 30px;
		background-color: ${Color.AT_YELLOW_4};
	}
	@media ${mediaBreakpoint.small} {
		button {
			width: 165px;
		}
	}
	@media ${mediaBreakpoint.medium} {
		button {
			width: 200px;
		}
	}

	@media ${mediaBreakpoint.mediumMinus} {
		margin: 0 40px;
		width: 100%;

		h2 {
			${getFont(Font.MOBILE_H0_BOLD)};
			margin-bottom: 20px;
		}

		.image-card__content {
			${getFont(Font.MOBILE_B1_REGULAR)};
			padding: 28px;
			margin-bottom: 180px;
		}

		.image-card__content__facade {
			${getFont(Font.MOBILE_B1_REGULAR)};
			padding: 28px;
			margin-bottom: 180px;
		}

		.image-card__image {
			height: 180px;
		}
	}

	@media ${mediaBreakpoint.large} {
		h2 {
			margin-top: 8px;
			margin-bottom: 26px;
		}

		.image-card__content {
			${getFont(Font.B0_REGULAR)};
			padding: 36px;
			margin-bottom: 250px;
		}

		.image-card__content__facade {
			${getFont(Font.B0_REGULAR)};
			padding: 0px 35px;
			margin-bottom: 250px;
		}

		.image-card__image {
			height: 250px;
		}

		button {
			width: 180px;
		}
	}

	.sign-new {
		background-color: ${Color.AT_GREEN_1};
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 6px;
		width: 78px;
		border-radius: 8px;
		margin: 8px 0px 0px 32px;
	}
`

const ImageCard = ({
	buttonLabel,
	image,
	path,
	text,
	title,
}: ImageCardProps) => {
	const history = useHistory()
	const { t } = useTranslation()

	const imageCardClassName =
		title ===
		t('views.home.sections.servicesAdaptedToYourIndustry.facade.title')
			? 'image-card__content__facade'
			: 'image-card__content'

	return (
		<ImageCardStyle>
			{title ===
			t('views.home.sections.servicesAdaptedToYourIndustry.facade.title') ? (
				<div className="sign-new">
					{t('views.home.sections.servicesAdaptedToYourIndustry.new')}
				</div>
			) : null}
			<div className={imageCardClassName}>
				<h2>{title}</h2>
				<div className="card-text">{text}</div>
			</div>
			<div
				className="image-card__image"
				style={{ backgroundImage: `url(${image})` }}
			/>
			<Button
				className="read-more"
				disabled={!path}
				label={buttonLabel}
				onClick={path ? () => history.push(path) : () => undefined}
			/>
		</ImageCardStyle>
	)
}

export const ImageCardContainer = styled.div`
	display: flex;

	> ${ImageCardStyle} {
		flex: 1;
	}

	> *:not(:first-child) {
		margin-left: 30px;
	}
`

export { ImageCard }
