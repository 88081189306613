import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import precise from '../assets/icons/precise.svg'
import fast from '../assets/icons/fast.png'
import costEffective from '../assets/icons/cost_effective.svg'
import { mediaBreakpoint } from '../theme'
import LaptopGif from './LaptopGif'
import { Advantages } from './Advantages'

const FlexCenter = styled.div`
	display: flex;
	justify-content: center;

	position: relative;

	@media ${mediaBreakpoint.large} {
		margin-bottom: -50px;
	}

	.fast-fusion-devices {
		display: flex;
		flex-direction: row;
		margin-top: -120px;
		margin-bottom: 10px;
		margin-left: -300px;
		margin-right: 80px;
	}

	.fast-fusion-gif {
		width: 460px;
		height: 285px;
		margin-left: -835px;
		margin-top: 130px;
	}

	.new-badge-container {
		display: flex;
		justify-content: center;
		align-items: center;

		margin-left: auto;
		width: 100%;

		position: absolute;
		height: 250px;

		@media ${mediaBreakpoint.small} {
			top: -240px;
			left: 50px;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			top: -210px;
			left: 20px;
		}

		.new-badge {
			@media ${mediaBreakpoint.small} {
				margin-top: -20px;
				margin-left: 60%;

				animation-duration: 1s;
				animation-name: make-bigger;
				animation-iteration-count: infinite;
			}
			@media ${mediaBreakpoint.mediumPlus} {
				margin-top: -20px;
				margin-left: 60%;

				animation-duration: 2s;
				animation-name: make-bigger;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
			}

			@keyframes make-bigger {
				0% {
					width: 15%;
				}

				50% {
					width: 18%;
				}
				100% {
					width: 15%;
				}
			}
		}
	}
`

const FastFusionSolarAdvantages = () => {
	const { t } = useTranslation()

	return (
		<>
			<FlexCenter>
				<LaptopGif />
			</FlexCenter>
			<Advantages>
				<div>
					<div className="img-container">
						<img alt="Fast" src={fast} />
					</div>
					<h4 style={{ whiteSpace: 'pre-wrap' }}>
						{t('views.home.sections.fastFusionSolar.fast.title')}
					</h4>
					<p>{t('views.home.sections.fastFusionSolar.fast.text')}</p>
				</div>
				<div>
					<div className="img-container">
						<img alt="Precise" src={precise} />
					</div>
					<h4>{t('views.home.sections.fastFusionSolar.precise.title')}</h4>
					<p>{t('views.home.sections.fastFusionSolar.precise.text')}</p>
				</div>
				<div>
					<div className="img-container">
						<img alt="Cost-effective" src={costEffective} />
					</div>
					<h4>{t('views.home.sections.fastFusionSolar.cost.title')}</h4>
					<p>{t('views.home.sections.fastFusionSolar.cost.text')}</p>
				</div>
			</Advantages>
		</>
	)
}

export { FastFusionSolarAdvantages }
