import { Locale } from 'locale-enum'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import fastFusionDevices from '../assets/fast-fusion-devices.png'
import fastFusionSolarGif from '../assets/fast-fusion-solar.gif'
import fastFusionThermal from '../assets/fast-fusion-thermal.svg'
import costEffective from '../assets/icons/cost-effective.svg'
import easy from '../assets/icons/easy.svg'
import fast from '../assets/icons/fast.png'
import flexible from '../assets/icons/flexible.svg'
import newDe from '../assets/icons/new/de.png'
import newEn from '../assets/icons/new/en.png'
import optimize from '../assets/icons/optimize.svg'
import precise from '../assets/icons/precise.svg'
import laptopSolar from '../assets/laptop-solar.svg'
import phoneSolar from '../assets/phone-solar.svg'
import tabletSolar from '../assets/tablet-solar.svg'
import { useBreakpoints } from '../hooks/use-breakpoints'
import { mediaBreakpoint } from '../theme'

import { Advantages } from './Advantages'
import { YoutubeVideoModal } from './YoutubeVideoModal'
import { YoutubeVideoPlayIcon } from './YoutubeVideoPlayIcon'

const FlexCenter = styled.div`
	display: flex;
	justify-content: center;

	position: relative;

	@media ${mediaBreakpoint.large} {
		margin-bottom: -50px;
	}

	.fast-fusion-devices {
		@media ${mediaBreakpoint.small} {
			width: 75%;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			width: 60%;
		}

		margin-bottom: 100px;
	}

	.fast-fusion-thermal-img {
		@media ${mediaBreakpoint.small} {
			width: 75%;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			width: 40%;
		}

		margin-top: -60px;
	}

	.solar-devices {
		display: flex;
		flex-direction: row;
		margin-top: -120px;
		margin-bottom: 10px;
		margin-left: -300px;
	}

	.laptop-solar {
		margin-right: -180px;

		@media ${mediaBreakpoint.small} {
			margin-top: 45px;
			margin-left: 220px;
			width: 500px;
		}
	}

	.phone-solar {
		margin-right: -80px;
		margin-bottom: -80px;
	}

	.tablet-solar {
		margin-right: -100px;
		margin-bottom: -140px;
	}

	.solar-gif {
		margin-left: -835px;
		margin-top: 130px;
		width: 470px;
		height: 300px;

		@media ${mediaBreakpoint.small} {
			margin-left: -215px;
			width: 300px;
			height: 190px;
		}
	}

	.new-badge-container {
		display: flex;
		justify-content: center;
		align-items: center;

		margin-left: auto;
		width: 100%;

		position: absolute;
		height: 250px;

		top: 80px;

		@media ${mediaBreakpoint.small} {
			top: -100px;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			top: -60px;
		}

		.new-badge {
			@media ${mediaBreakpoint.small} {
				margin-top: -20px;
				margin-left: 60%;

				animation-duration: 1s;
				animation-name: make-bigger;
				animation-iteration-count: infinite;
			}
			@media ${mediaBreakpoint.mediumPlus} {
				margin-top: -20px;
				margin-left: 60%;

				animation-duration: 2s;
				animation-name: make-bigger;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
			}

			@keyframes make-bigger {
				0% {
					width: 15%;
				}

				50% {
					width: 18%;
				}
				100% {
					width: 15%;
				}
			}
		}
	}
`

export type FastFusionAdvantagesProps = {
	type: string
}

const FastFusionAdvantages = ({ type }: FastFusionAdvantagesProps) => {
	const { t } = useTranslation()
	const { i18n } = useTranslation()
	const { isSmall } = useBreakpoints()
	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
	const currentLanguage = i18n.language

	return (
		<>
			{type === 'solar' && (
				<>
					<FlexCenter>
						<div className="solar-devices">
							{!isSmall && (
								<img
									alt="phone solar"
									className="phone-solar"
									src={phoneSolar}
								/>
							)}
							<img
								alt="laptop solar"
								className="laptop-solar"
								src={laptopSolar}
							/>
							{!isSmall && (
								<img
									alt="tablet solar"
									className="tablet-solar"
									src={tabletSolar}
								/>
							)}
							<img
								alt="solar gif"
								className="solar-gif"
								src={fastFusionSolarGif}
							/>
						</div>
					</FlexCenter>
					<Advantages>
						<div>
							<div className="img-container">
								<img alt="Fast" src={fast} />
							</div>
							<h4 style={{ whiteSpace: 'pre-wrap' }}>
								{t('views.home.sections.fastFusion.fast.title')}
							</h4>
							<p>{t('views.home.sections.fastFusion.fast.text')}</p>
						</div>
						<div>
							<div className="img-container">
								<img alt="precise" src={precise} />
							</div>
							<h4>{t('views.home.sections.fastFusion.easy.title')}</h4>
							<p>{t('views.home.sections.fastFusion.easy.text')}</p>
						</div>
						<div>
							<div className="img-container">
								<img alt="costEffective" src={costEffective} />
							</div>
							<h4>{t('views.home.sections.fastFusion.flexible.title')}</h4>
							<p>{t('views.home.sections.fastFusion.flexible.text')}</p>
						</div>
					</Advantages>
				</>
			)}

			{type === 'thermal' && (
				<>
					<FlexCenter>
						<img
							alt="fast fusion thermal"
							className="fast-fusion-thermal-img"
							src={fastFusionThermal}
						/>
					</FlexCenter>
					<Advantages>
						<div>
							<div className="img-container">
								<img alt="Fast" src={fast} />
							</div>
							<h4 style={{ whiteSpace: 'pre-wrap' }}>
								{t('views.home.sections.fastFusion.fast.title')}
							</h4>
							<p>{t('views.product.sections.thermal.fast')}</p>
						</div>
						<div>
							<div className="img-container">
								<img alt="optimize" src={optimize} />
							</div>
							<h4>{t('views.product.sections.thermal.optimizeTitle')}</h4>
							<p>{t('views.product.sections.thermal.optimize')}</p>
						</div>
						<div>
							<div className="img-container">
								<img alt="costEffective" src={costEffective} />
							</div>
							<h4>{t('views.home.sections.fastFusion.flexible.title')}</h4>
							<p>{t('views.product.sections.thermal.costEffective')}</p>
						</div>
					</Advantages>
				</>
			)}

			{type === 'ff3d' && (
				<>
					<FlexCenter>
						<img
							alt="fast fusion 3d"
							className="fast-fusion-devices"
							src={fastFusionDevices}
						/>
						<div className="new-badge-container">
							<img
								alt="New"
								className="new-badge"
								src={currentLanguage === Locale.de_DE ? newDe : newEn}
							/>
						</div>
						<YoutubeVideoPlayIcon onClick={() => setIsVideoModalOpen(true)} />
					</FlexCenter>
					<Advantages>
						<div>
							<div className="img-container">
								<img alt="Fast" src={fast} />
							</div>
							<h4 style={{ whiteSpace: 'pre-wrap' }}>
								{t('views.home.sections.fastFusion.ff3d.fast.title')}
							</h4>
							<p>{t('views.home.sections.fastFusion.ff3d.fast.text')}</p>
						</div>
						<div>
							<div className="img-container">
								<img alt="Easy" src={easy} />
							</div>
							<h4>{t('views.home.sections.fastFusion.ff3d.easy.title')}</h4>
							<p>{t('views.home.sections.fastFusion.ff3d.easy.text')}</p>
						</div>
						<div>
							<div className="img-container">
								<img alt="Flexible" src={flexible} />
							</div>
							<h4>{t('views.home.sections.fastFusion.ff3d.flexible.title')}</h4>
							<p>{t('views.home.sections.fastFusion.ff3d.flexible.text')}</p>
						</div>
					</Advantages>
					{isVideoModalOpen && (
						<YoutubeVideoModal
							iFrameTitle="FastFusion3D Youtube iFrame"
							isPlayList={false}
							videoOrPlayListId="sEDQvYoC1io"
							onClose={() => setIsVideoModalOpen(false)}
						/>
					)}
				</>
			)}
		</>
	)
}

export { FastFusionAdvantages }
