import React from 'react'
import { useTranslation } from 'react-i18next'

import { BlockCutter } from '../../components/BlockCutter'
import { PageHeader } from '../../components/PageHeader'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { useStore } from '../../store'

import titleBackground from './assets/title-background.jpg'
import { BlogEntries } from './components/BlogEntries'
import { BlogEntry } from './components/BlogEntry'

const BlogView = () => {
	useScrollToTop()
	document.title = 'Blog | AIRTEAM'
	const { t } = useTranslation()
	const { blogEntries } = useStore()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.blog.title')}
			/>
			<BlockCutter top="in">
				<BlogEntries>
					{blogEntries.map((entry, index) => (
						<BlogEntry key={index} type="short" {...entry} />
					))}
				</BlogEntries>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const BLOG_PATH = '/blog' as const

export { BlogView }
