import styled from 'styled-components'

import { Color, Font, getFont, mediaBreakpoint } from '../theme'

export const QuoteHeadline = styled.p<{ color: Color; lineColor: Color }>`
	text-align: center;
	color: ${({ color }) => color};

	position: relative;

	:after,
	:before {
		position: absolute;
		top: 45px;
		content: '';
		height: 10px;
		background-color: ${({ lineColor }) => lineColor};
	}

	@media ${mediaBreakpoint.small} {
		margin: 38px 0;
		padding: 0 15px;

		${getFont(Font.MOBILE_B1_BOLD)};

		:after,
		:before {
			width: 18px;
		}

		:after {
			right: -26px;
		}

		:before {
			left: -26px;
		}
	}

	@media ${mediaBreakpoint.mediumPlus} {
		margin: 20px 0 50px 0;
		padding: 0 50px;

		${getFont(Font.H4_MEDIUM)};

		:after,
		:before {
			width: 80px;
		}

		:after {
			right: -88px;
		}

		:before {
			left: -88px;
		}
	}
`
