import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import error from '../../assets/icons/error.svg'
import thumbsUp from '../../assets/icons/thumbs-up.svg'
import { Loader } from '../../components/Loader'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { blogsApi } from '../../utilities/axios-api'

const TextBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: #fbfafd;
	box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
	border-radius: 3px;

	flex: 1;

	color: ${Color.AT_BLACK_1};
	text-align: center;

	min-height: 40vh;
	@media ${mediaBreakpoint.mediumMinus} {
		padding: 92px 20px;
	}

	@media ${mediaBreakpoint.mediumPlus} {
		margin: 150px 0;
		padding: 92px 20%;
	}

	& div {
		margin-top: 40px;
	}

	.title {
		@media ${mediaBreakpoint.mediumMinus} {
			${getFont(Font.H4_BOLD)};
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B0_BOLD)};
		}
	}

	.message {
		@media ${mediaBreakpoint.mediumMinus} {
			${getFont(Font.B1_REGULAR)};
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B1_REGULAR)};
		}
	}
`

type TokenQuery = {
	token?: string
}

type NewsletterConfirmationData = {
	token: string
}

const NewsletterConfirmationView = () => {
	useScrollToTop()
	document.title = 'Newsletter | AIRTEAM'

	useTranslation()
	const location = useLocation()
	const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState<
		boolean | null
	>(null)
	const [isWaitingOnServer, setIsWaitingOnServer] = useState(true)

	useEffect(() => {
		const query = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		}) as TokenQuery
		const token = query.token

		if (token === undefined) throw Error('token missing from query params')

		const data: NewsletterConfirmationData = {
			token,
		}
		blogsApi
			.post('/api/newsletter-registration-confirmation/', data)
			.then(() => {
				setIsRegistrationSuccessful(true)
			})
			.catch(() => {
				setIsRegistrationSuccessful(false)
			})
			.finally(() => {
				setIsWaitingOnServer(false)
			})
	}, [location.search])

	return (
		<DefaultPageLayout withBoxShadow={false}>
			<TextBlock>
				{isWaitingOnServer ? (
					<Loader />
				) : (
					<>
						<img
							alt={isRegistrationSuccessful ? 'Thumbs up' : 'Error'}
							src={isRegistrationSuccessful ? thumbsUp : error}
						/>
						<div className="title">
							<Trans
								i18nKey={
									isRegistrationSuccessful
										? 'footer.newsletter.confirmationSuccess.title'
										: 'footer.newsletter.subscriptionFailure.title'
								}
							/>
						</div>
						<div className="message">
							<Trans
								i18nKey={
									isRegistrationSuccessful
										? 'footer.newsletter.confirmationSuccess.message'
										: 'footer.newsletter.subscriptionFailure.message'
								}
							/>
						</div>
					</>
				)}
			</TextBlock>
		</DefaultPageLayout>
	)
}

export const NEWSLETTER_CONFIRMATION_PATH =
	'/confirm-newsletter-subscription' as const

export { NewsletterConfirmationView }
