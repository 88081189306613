import { useCallback } from 'react'

export function useFieldValidators() {
	const validateEmail = useCallback((value: string) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(value)
	}, [])

	const validateE164PhoneNumber = useCallback((value: string) => {
		const re = /^\+[1-9]\d{10,14}$/
		return re.test(value)
	}, [])

	return {
		validateE164PhoneNumber,
		validateEmail,
	}
}
