import { useCallback, useRef, useState } from 'react'
import Reaptcha from 'reaptcha'

import { useFieldValidators } from '../../hooks/use-field-validators'
import { blogsApi } from '../../utilities/axios-api'

type Contact = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	agree_to_terms: boolean
	email: string
	/**
	 * last name
	 */
	nachname: string
	/**
	 * message
	 */
	nachricht: string
	recaptcha: string
	/**
	 * first name
	 */
	vorname: string
}

export const useContactForm = () => {
	const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false)
	const [email, setEmail] = useState<string | null>(null)
	const [firstName, setFirstName] = useState<string | null>(null)
	const [googleRecaptchaResponse, setGoogleRecaptchaResponse] = useState<
		string | null
	>(null)
	const [lastName, setLastName] = useState<string | null>(null)
	const [message, setMessage] = useState<string | null>(null)
	const reaptchaRef = useRef<Reaptcha>(null)
	const { validateEmail } = useFieldValidators()

	const resetForm = useCallback(async () => {
		setAgreeToTerms(false)
		setEmail(null)
		setFirstName(null)
		setGoogleRecaptchaResponse(null)
		setLastName(null)
		setMessage(null)
		if (!reaptchaRef.current) throw new Error('Reaptcha Ref Missing')
		await reaptchaRef.current.reset()
	}, [
		setAgreeToTerms,
		setEmail,
		setFirstName,
		setGoogleRecaptchaResponse,
		setLastName,
		setMessage,
		reaptchaRef,
	])

	const isFormValid = useCallback(() => {
		return (
			email !== null &&
			validateEmail(email) &&
			firstName !== null &&
			googleRecaptchaResponse !== null &&
			lastName !== null &&
			message !== null &&
			agreeToTerms
		)
	}, [
		email,
		firstName,
		googleRecaptchaResponse,
		lastName,
		message,
		agreeToTerms,
		validateEmail,
	])

	const submit = useCallback(async () => {
		if (agreeToTerms === false) throw new Error('Must agree to terms')

		if (
			email === null ||
			firstName === null ||
			googleRecaptchaResponse === null ||
			lastName === null ||
			message === null
		)
			throw new Error('form not filled')

		const data: Contact = {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			agree_to_terms: agreeToTerms,
			email,
			nachname: lastName,
			nachricht: message,
			recaptcha: googleRecaptchaResponse,
			vorname: firstName,
		}

		await blogsApi.post('/api/contacts/', data)
	}, [
		agreeToTerms,
		email,
		firstName,
		googleRecaptchaResponse,
		lastName,
		message,
	])

	return {
		agreeToTerms,
		email,
		firstName,
		googleRecaptchaResponse,
		isFormValid,
		lastName,
		message,
		reaptchaRef,
		resetForm,
		setAgreeToTerms,
		setEmail,
		setFirstName,
		setGoogleRecaptchaResponse,
		setLastName,
		setMessage,
		submit,
	}
}
