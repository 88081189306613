import deFlag from 'flagpack/flags/1x1/de.svg'
import gbFlag from 'flagpack/flags/1x1/gb.svg'
import { Locale } from 'locale-enum'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Flags = styled.div`
	display: inline-flex;

	> img {
		border-radius: 50%;
		transform: translateZ(0);
		will-change: transform;
		filter: brightness(50%);
		cursor: pointer;
		border: 1px solid #373737;

		height: 20px;
		width: 20px;

		&.active,
		&:active {
			filter: unset !important;
		}

		&:hover:not(:active) {
			filter: brightness(80%);
		}

		&:not(:first-child) {
			margin-left: 12px;
		}
	}
`

const HeaderLocaleSwitcher = ({
	postLocaleChange,
}: {
	postLocaleChange?: () => void
}) => {
	const { i18n } = useTranslation()

	const currentLanguage = i18n.language

	return (
		<Flags>
			<img
				alt="German Flag"
				className={currentLanguage === Locale.de_DE ? 'active' : undefined}
				src={deFlag}
				onClick={async () => {
					await i18n.changeLanguage(Locale.de_DE)
					postLocaleChange?.()
				}}
			/>
			<img
				alt="United Kingdom Flag"
				className={currentLanguage === Locale.en_US ? 'active' : undefined}
				src={gbFlag}
				onClick={async () => {
					await i18n.changeLanguage(Locale.en_US)
					postLocaleChange?.()
				}}
			/>
		</Flags>
	)
}

export { HeaderLocaleSwitcher }
