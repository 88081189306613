import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import deliverablesIcon from '../../../assets/icons/deliverables.svg'
import drone from '../../../assets/icons/drone.svg'
import droneGreen from '../../../assets/icons/drone_green.svg'
import ruler from '../../../assets/icons/ruler.svg'
import { Carousel } from '../../../components/Carousel'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, mediaBreakpoint } from '../../../theme'
import { IndividualPricingCard } from './IndividualPricingCard'

const Card = styled.div`
	justify-content: center;
	background-color: ${Color.AT_WHITE_PRIMARY};
	padding: 20px 30px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	margin-bottom: 20px;

	@media ${mediaBreakpoint.largeMinus} {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.pricing {
		display: flex;

		&__container {
			width: 82%;
			flex-direction: row;
		}
	}

	.features__container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 40px;

		@media ${mediaBreakpoint.largeMinus} {
			display: flex;
			flex-direction: column;
			justify-content: start;
			margin-top: 0;
			padding-bottom: 16px;
		}
	}

	.pricing__legend {
		width: 23%;
		display: flex;
		flex-direction: column;
		margin-top: 9%;
		margin-right: 10px;
	}

	.badge {
		border-radius: 5px;
		margin-top: 0.7em;
		margin-bottom: 1em;
		display: flex;
		padding: 8px 9px;
		align-items: center;
		font-size: 19px;
		font-style: normal;
		font-weight: 600;
		line-height: 25px;
		letter-spacing: 0.23px;

		@media ${mediaBreakpoint.largeMinus} {
			margin: 0 16px;
			border-radius: 0;
			background: #f2f2f2;
		}

		&--light {
			background-color: #f2f2f2;
		}

		&--dark {
			background-color: #2f313e;
			color: white;
		}
	}
`

const IndividualPricingContainer = () => {
	const { t } = useTranslation()
	const { isLarge } = useBreakpoints()

	const pricingCards = [
		<IndividualPricingCard
			classification="S"
			color={Color.AT_GRAY_2}
			deliverables={1}
			key="S"
			maximumArea={300}
			price={75}
			priceWithDrone={250}
		/>,
		<IndividualPricingCard
			classification="M"
			color={Color.AT_YELLOW}
			deliverables={3}
			key="M"
			maximumArea={750}
			price={125}
			priceWithDrone={500}
		/>,
		<IndividualPricingCard
			classification="L"
			color={Color.AT_GREEN_2}
			deliverables={5}
			key="L"
			maximumArea={1500}
			price={250}
			priceWithDrone={750}
		/>,
		<IndividualPricingCard
			classification="XL"
			color={Color.AT_YELLOW_2}
			deliverables={10}
			key="XL"
			maximumArea={5000}
			price={375}
			priceWithDrone={1500}
		/>,
	]

	return (
		<Card>
			{isLarge ? (
				<div className="pricing">
					<div className="pricing__legend">
						<div className="badge badge--light">
							<img
								alt="Ruler"
								src={ruler}
								style={{
									marginRight: '10px',
								}}
							/>
							{t('views.pricing.sections.singlePrices.size')}
						</div>
						<div className="badge badge--light">
							<img
								alt="Deliverables"
								src={deliverablesIcon}
								style={{
									marginRight: '10px',
								}}
							/>
							{t('views.pricing.sections.singlePrices.deliverablesBadge')}
						</div>
						<div className="badge badge--dark">
							<img
								alt="Drone"
								src={drone}
								style={{
									marginRight: '10px',
								}}
							/>
							{t('views.pricing.sections.singlePrices.customerPilot')}
						</div>
						<div className="badge badge--dark">
							<img
								alt="Green Drone"
								src={droneGreen}
								style={{
									marginRight: '10px',
								}}
							/>
							{t('views.pricing.sections.singlePrices.airteamPilot')}
						</div>
					</div>
					<div className="pricing pricing__container">{pricingCards}</div>
				</div>
			) : (
				<Carousel items={pricingCards} />
			)}

			{!isLarge && (
				<div className="features__container">
					<span className="badge">
						<img
							alt="Ruler"
							src={ruler}
							style={{
								marginRight: '10px',
							}}
						/>
						{t('views.pricing.sections.singlePrices.size')}
					</span>
					<span className="badge">
						<img
							alt="Deliverables"
							src={deliverablesIcon}
							style={{
								marginRight: '10px',
							}}
						/>
						{t('views.pricing.sections.singlePrices.deliverablesBadge')}
					</span>
					<span className="badge badge--dark">
						<img
							alt="Drone"
							src={drone}
							style={{
								marginRight: '10px',
							}}
						/>
						{t('views.pricing.sections.singlePrices.customerPilot')}
					</span>
					<span className="badge badge--dark">
						<img
							alt="Green Drone"
							src={droneGreen}
							style={{
								marginRight: '10px',
							}}
						/>
						{t('views.pricing.sections.singlePrices.airteamPilot')}
					</span>
				</div>
			)}
		</Card>
	)
}

export { IndividualPricingContainer }
