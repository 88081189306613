import axios, { AxiosRequestConfig } from 'axios'

import { i18n } from '../i18n'

const injectAcceptLanguageHeaderRequestInterceptor = (
	config: AxiosRequestConfig,
) => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	config.headers = config.headers || {}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	config.headers['Accept-Language'] = i18n.language
	return config
}

const blogsApi = axios.create({
	baseURL:
		process.env.REACT_APP_ENV === 'development'
			? 'https://blog.staging.airteam.cloud/'
			: 'https://blog.airteam.cloud/',
})
const webappApi = axios.create({
	baseURL:
		process.env.REACT_APP_ENV === 'development'
			? 'https://afp.staging.airteam.cloud/'
			: 'https://afp.airteam.cloud/',
})

blogsApi.interceptors.request.use(injectAcceptLanguageHeaderRequestInterceptor)
webappApi.interceptors.request.use(injectAcceptLanguageHeaderRequestInterceptor)

export { blogsApi, webappApi }
