import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Locale } from 'locale-enum'

import { REGISTRATION_PATH } from '../views/registration/View'

import { Button } from './Button'

const TryForFree = ({ type }: { type: 'primary' | 'yellow' }) => {
	const { i18n, t } = useTranslation()
	const history = useHistory()
	console.log(i18n.language)

	return (
		<Button
			style={{ fontSize: i18n.language === 'de-DE' ? '14px' : '' }}
			label={t('buttons.tryForFree')}
			onClick={() => history.push(REGISTRATION_PATH)}
			{...{ type }}
		/>
	)
}

TryForFree.defaultProps = {
	type: 'primary',
}

export { TryForFree }
