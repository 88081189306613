import React from 'react'
import styled from 'styled-components'

import { Color, makePattern, mediaBreakpoint } from '../../theme'

import { FooterLegalSection } from './LegalSection'
import { FooterLinksSection } from './LinksSection'

const Container = styled.footer`
	border-top: 4px solid ${Color.AT_GREEN_1};
	background-color: ${Color.DARK_DOTTED_BACKGROUNDS};
	color: ${Color.AT_WHITE_PRIMARY};
	font-family: 'Muli';

	@media ${mediaBreakpoint.small} {
		background-image: url(${makePattern(Color.AT_GRAY, 4)});
		background-size: 4px;
	}

	@media ${mediaBreakpoint.medium} {
		background-image: url(${makePattern(Color.AT_GRAY, 6)});
		background-size: 6px;
	}

	@media ${mediaBreakpoint.large} {
		background-image: url(${makePattern(Color.AT_GRAY, 10)});
		background-size: 10px;
	}
`

const Footer = () => (
	<Container>
		<FooterLinksSection />
		<FooterLegalSection />
	</Container>
)

export { Footer }
