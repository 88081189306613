import { Locale } from 'locale-enum'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import blackCheckmark from '../../../assets/icons/black-check.svg'
import greenCheckmark from '../../../assets/icons/green-round-check.svg'
import newDe from '../../../assets/icons/new/de.png'
import newEn from '../../../assets/icons/new/en.png'
import purpleCheckmark from '../../../assets/icons/purple-round-check.svg'
import yellowCheckmark from '../../../assets/icons/yellow-round-check.svg'
import { formatCurrencyInteger } from '../../../formatters/currency'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'

export interface CardProps {
	bottomText: string
	buttonRequest: JSX.Element
	buttonBuy: JSX.Element
	color:
		| Color.AT_YELLOW
		| Color.AT_BLACK_1
		| Color.AT_GREEN_2
		| Color.AT_YELLOW_2
		| Color.PURPLE
	isNew?: boolean
	subtext: string
	title: string
	price: {
		amount: number
		disclaimer?: string | null
		disclaimerSmall?: string | null
		disclaimer2?: string | null
		disclaimer2Small?: string | null
		pretext?: string | null
		subtext?: string | null
	}
	includedInPrice: string[]
}

const Card = styled.div<Pick<CardProps, 'color'>>`
	display: flex;
	flex-direction: column;

	position: relative;

	background-color: ${Color.AT_WHITE_PRIMARY};
	padding: 26px;
	width: 20%;

	background: ${Color.AT_WHITE_PRIMARY};
	box-shadow: 10px 10px 29px rgba(0, 0, 0, 0.07), inset 3px 3px 4px #ffffff;
	border-radius: 10px;

	@media ${mediaBreakpoint.small} {
		box-sizing: border-box;
		margin-inline: 20px;
		width: 100%;
		padding: 28px;
	}
	@media ${mediaBreakpoint.medium} {
		padding: 30px;
	}

	h4 {
		margin: 0 0 20px 0;
		color: ${(props) => props.color};

		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_H0_BOLD)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			font-family: Manrope;
			font-size: 34px;
			font-style: normal;
			font-weight: 700;
			line-height: 55px;
			letter-spacing: 0.01em;
		}
	}

	p {
		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B1_BOLD)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B0_BOLD)}
		}

		color: ${(props) => props.color};
	}

	.price {
		text-align: center;
		&__pretext {
			font-family: Manrope;
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: 40px;
			letter-spacing: 0.01em;

			margin-right: 15px;

			color: ${Color.AT_BLACK_1};
		}
		&__amount {
			@media ${mediaBreakpoint.small} {
				${getFont(Font.H2_SEMI_BOLD)}
			}
			@media ${mediaBreakpoint.mediumPlus} {
				font-family: Manrope;
				font-size: 40px;
				font-style: normal;
				font-weight: 600;
				line-height: 60px;
				text-align: center;
			}
			@media ${mediaBreakpoint.medium} {
				font-size: 54px;
			}

			color: ${(props) => props.color};
		}
		&__subtext {
			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B1_BOLD)}
			}
			@media ${mediaBreakpoint.mediumPlus} {
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				font-size: 21px;
				text-align: center;
				line-height: 144%;
				letter-spacing: 0.01em;
			}

			color: ${Color.AT_BLACK_1};
		}
		&__disclaimer {
			@media ${mediaBreakpoint.mediumPlus} {
				${getFont(Font.B1_REGULAR)};
			}
			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B3_REGULAR)}
				width: 75%;
			}

			width: 15rem;
			color: ${Color.AT_BLACK_1};

			&--small {
				font-weight: bold;
			}

			&--first {
				margin-top: 15px;
			}
		}
	}

	button {
		margin: auto;
		@media ${mediaBreakpoint.medium} {
			margin: 20px;
		}
	}

	.included-in-price {
		min-height: 650px;
		@media ${mediaBreakpoint.medium} {
			width: 70%;
			min-height: auto;
		}
		@media ${mediaBreakpoint.small} {
			width: 70%;
			min-height: auto;
		}

		h4 {
			color: ${Color.AT_BLACK_1};

			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B1_BOLD)}
				margin-bottom: 40px;
			}
			@media ${mediaBreakpoint.medium} {
				${getFont(Font.B0_BOLD)}
				margin-bottom: 0;
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.B0_BOLD)}
				margin-bottom: 40px;
			}
		}
	}

	ul {
		color: ${Color.AT_BLACK_TEXT};
		list-style: none;
		padding: 0;
		margin-bottom: 70px;
		width: 14rem;

		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B3_REGULAR)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B1_REGULAR)}
		}

		&:nth-child(4) {
			height: 3.2rem;
		}

		li {
			display: flex;
			align-items: flex-start;

			margin-bottom: 20px;

			:nth-child(4) {
				height: 3rem;
			}

			img {
				max-width: 27px;
				margin-right: 10px;
			}
		}
	}

	.bottom-text {
		padding: 25px 25px;

		@media ${mediaBreakpoint.small} {
			margin: auto auto 0 auto;
			/* width: 235px; */
		}
		@media ${mediaBreakpoint.medium} {
			margin-bottom: 60px;
			width: 330px;
		}
		@media ${mediaBreakpoint.large} {
			margin-top: auto;
			margin-bottom: 0;
			align-self: center;
			width: 220px;
			text-align: center;
		}
	}
`
const PricingCardOld = ({
	bottomText,
	buttonBuy,
	buttonRequest,
	color,
	includedInPrice,
	isNew,
	price,
	subtext,
	title,
}: CardProps) => {
	const { isMedium } = useBreakpoints()
	const { i18n, t } = useTranslation()

	const currentLanguage = i18n.language

	const withoutVat = t('views.pricing.pricingCard.withoutVat')

	const firstPart = useMemo(() => {
		return (
			<>
				{isNew && (
					<img
						alt="New"
						src={currentLanguage === Locale.de_DE ? newDe : newEn}
						style={{
							position: 'absolute',
							right: '0',
							top: '-60px',
							transform: 'rotate(-14.2deg)',
							width: '35%',
						}}
					/>
				)}
				<h4>{title}</h4>
				<p
					style={{
						fontSize: '18px',
						height: i18n.language === Locale.de_DE ? '10rem' : '8rem',
					}}
				>
					{subtext}
				</p>
				<div className="price">
					{price.pretext && (
						<span className="price__pretext">{price.pretext}</span>
					)}
					{price.pretext && isMedium && <br />}
					<span className="price__amount">
						{formatCurrencyInteger(price.amount)}
					</span>
					<div className="price__subtext">{price.subtext}</div>
					<div className="price__disclaimer price__disclaimer--first">
						{price.disclaimer}{' '}
						<span className="price__disclaimer--small">
							{price.disclaimerSmall}
						</span>
					</div>
					<div className="price__disclaimer">
						{price.disclaimer2}
						<span className="price__disclaimer--small">
							{price.disclaimer2Small}
						</span>
					</div>
				</div>
				<div
					className="netto"
					style={{
						color: color,
						display: 'flex',
						fontSize: '20px',
						justifyContent: 'center',
					}}
				>
					{withoutVat}
				</div>
			</>
		)
	}, [
		currentLanguage,
		price.amount,
		price.disclaimer,
		price.disclaimerSmall,
		price.disclaimer2,
		price.disclaimer2Small,
		price.pretext,
		price.subtext,
		subtext,
		title,
		isNew,
		isMedium,
		i18n.language,
	])
	const secondPart = useMemo(() => {
		return (
			<>
				<div className="included-in-price">
					<h4 style={{ marginTop: '50px' }}>
						{t('views.pricing.pricingCard.includedInPrice')}
					</h4>
					<ul>
						{includedInPrice.map((element, index) => (
							<li key={element}>
								<img
									alt="Checkmark"
									src={
										color === Color.AT_BLACK_1
											? blackCheckmark
											: color === Color.AT_YELLOW
											? yellowCheckmark
											: color == Color.AT_GREEN_2
											? greenCheckmark
											: purpleCheckmark
									}
								/>
								{element}
							</li>
						))}
					</ul>
				</div>
				<div>{buttonBuy}</div>
				{!isMedium && (
					<div className="bottom-text">
						<div>{bottomText}</div>
					</div>
				)}
				<div>{buttonRequest}</div>
			</>
		)
	}, [
		isMedium,
		buttonRequest,
		buttonBuy,
		bottomText,
		color,
		includedInPrice,
		t,
	])

	return (
		<>
			{isMedium ? (
				<>
					<Card
						color={color}
						style={{
							boxSizing: 'border-box',
							marginLeft: '40px',
							marginRight: '10px',
							width: '33.3%',
						}}
					>
						{firstPart}
					</Card>
					<Card
						color={color}
						style={{
							boxSizing: 'border-box',
							marginRight: '40px',
							width: '66.7%',
						}}
					>
						{secondPart}
					</Card>
				</>
			) : (
				<Card color={color}>
					{firstPart}
					{secondPart}
				</Card>
			)}
		</>
	)
}

export { PricingCardOld }
