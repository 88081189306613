import { useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

import { COOKIE_BANNER_SHOW_BY_DEFAULT } from '../../data'
import { loadState, saveState } from '../../utilities/cache'

export enum CookieState {
	ACCEPTED = 'accepted',
	REJECTED = 'rejected',
	UNKNOWN = 'unknown',
}

export const useCookies = () => {
	const [didAcceptCookies, setDidAcceptCookies] = useState(
		loadState<CookieState>('didAcceptCookies', CookieState.UNKNOWN, (x) =>
			Object.values<string>(CookieState).includes(x)
				? (x as CookieState)
				: CookieState.UNKNOWN,
		),
	)

	useEffect(
		() => saveState('didAcceptCookies', didAcceptCookies, (x) => x),
		[didAcceptCookies],
	)

	return {
		acceptCookies: function () {
			setDidAcceptCookies(CookieState.ACCEPTED)
			TagManager.initialize({
				gtmId: 'GTM-M4PXRG8',
			})
			ReactPixel.grantConsent()
			ReactPixel.pageView()
			const hubspotCookiesConfirmationButton: HTMLAnchorElement | null =
				document.querySelector('#hs-eu-confirmation-button')
			if (hubspotCookiesConfirmationButton !== null) {
				hubspotCookiesConfirmationButton.click()
			}
		},
		didAcceptCookies,
		rejectCookies: () => setDidAcceptCookies(CookieState.REJECTED),
		showCookieBanner:
			didAcceptCookies === CookieState.UNKNOWN && COOKIE_BANNER_SHOW_BY_DEFAULT,
	}
}
