import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BlockCutter } from '../../components/BlockCutter'
import { PageHeader } from '../../components/PageHeader'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { useStore } from '../../store'

import titleBackground from './assets/title-background.jpg'
import { BlogEntries } from './components/BlogEntries'
import { BlogEntry } from './components/BlogEntry'

const BlogDetailView = () => {
	useScrollToTop()
	const { t } = useTranslation()
	const { id } = useParams<{ id: string; slug: string }>()

	const { blogEntries } = useStore()

	const entry = blogEntries.find((x) => x.id === parseInt(id, 10)) ?? null

	if (entry != null) {
		document.title = entry.titel
	}

	if (entry === null) return null

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.blog.title')}
			/>
			<BlockCutter top="in">
				<BlogEntries>
					<BlogEntry type="long" {...entry} />
				</BlogEntries>
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const BLOG_DETAIL_PATH = '/blog/:id/:slug' as const

export { BlogDetailView }
