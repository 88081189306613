import { Locale } from 'locale-enum'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

declare global {
	interface Window {
		hbspt: {
			forms: {
				create: (config: HubSpotFormConfig) => void
			}
		}
	}
}

interface HubSpotFormConfig {
	region: string
	portalId: string
	formId: string
	target: string
}

const HubspotContactForm: React.FC = () => {
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language

	useEffect(() => {
		const script: HTMLScriptElement = document.createElement('script')
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		document.body.appendChild(script)

		const formId =
			currentLanguage === Locale.de_DE
				? 'e9ca22a5-8409-410e-90d2-87280426cdd2'
				: '1c5d123f-1a0c-41ec-b2af-21d7e98ec296'

		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					formId: formId,
					portalId: '3797785',
					region: 'na1',
					target: '#hubspotForm',
				})
			}
		})
		return () => {
			document.body.removeChild(script)
		}
	}, [currentLanguage])
	return <div id="hubspotForm"></div>
}

export { HubspotContactForm }
