import React from 'react'
import styled from 'styled-components'

import {
	ARROW_HEIGHT_LARGE,
	ARROW_HEIGHT_MEDIUM,
	ARROW_HEIGHT_SMALL,
} from '../data'
import { mediaBreakpoint } from '../theme'

import { Heading, HeadingProps } from './Heading'

type Direction = 'out' | 'in'

const generatePolygon = (
	arrowHeight: number,
	{
		bottom,
		top,
	}: {
		bottom: Direction | null
		top: Direction | null
	},
) => {
	const result: string[] = []

	switch (top) {
		case 'in':
			result.push('0% 0%', `50% calc(0% + ${arrowHeight}px)`, '100% 0%')
			break

		case 'out':
			result.push(
				`0% calc(0% + ${arrowHeight}px)`,
				'50% 0%',
				`100% calc(0% + ${arrowHeight}px)`,
			)
			break

		case null:
			result.push('0% 0%', '100% 0%')
			break
	}

	result.push('100% 50%')

	switch (bottom) {
		case 'in':
			result.push('100% 100%', `50% calc(100% - ${arrowHeight}px)`, '0% 100%')
			break

		case 'out':
			result.push(
				`100% calc(100% - ${arrowHeight}px)`,
				'50% 100%',
				`0% calc(100% - ${arrowHeight}px)`,
			)
			break

		case null:
			result.push('100% 100%', '0% 100%')
			break
	}

	result.push('0% 50%')

	return `polygon(${result.join(', ')})`
}

type BlockCutterType = {
	bottom: Direction | null
	top: Direction | null
}

const generatePadding = (
	arrowHeight: number,
	{ bottom, top }: Pick<BlockCutterType, 'bottom' | 'top'>,
	sidePadding: number,
) =>
	`${
		{
			in: arrowHeight + 70,
			null: 70,
			out: arrowHeight,
		}[top ?? 'null']
	}px ${sidePadding}px ${
		{
			in: arrowHeight + 70,
			null: 70,
			out: arrowHeight,
		}[bottom ?? 'null']
	}px ${sidePadding}px`

export const blockCutterSidePaddingSmallInPx = 18
export const blockCutterSidePaddingMediumInPx = 58
export const blockCutterSidePaddingLargeInPx = 88

const BlockCutterStyle = styled.div<BlockCutterType>`
	@media ${mediaBreakpoint.small} {
		clip-path: ${({ bottom, top }) =>
			generatePolygon(ARROW_HEIGHT_SMALL, { bottom, top })};
		padding: ${({ bottom, top }) =>
			generatePadding(
				ARROW_HEIGHT_SMALL,
				{ bottom, top },
				blockCutterSidePaddingSmallInPx,
			)};
		margin-bottom: ${({ bottom }) =>
			bottom === null ? -1 : -ARROW_HEIGHT_SMALL}px;
	}

	@media ${mediaBreakpoint.medium} {
		clip-path: ${({ bottom, top }) =>
			generatePolygon(ARROW_HEIGHT_MEDIUM, { bottom, top })};
		padding: ${({ bottom, top }) =>
			generatePadding(
				ARROW_HEIGHT_MEDIUM,
				{ bottom, top },
				blockCutterSidePaddingMediumInPx,
			)};
		margin-bottom: ${({ bottom }) =>
			bottom === null ? -1 : -ARROW_HEIGHT_MEDIUM}px;
	}

	@media ${mediaBreakpoint.large} {
		clip-path: ${({ bottom, top }) =>
			generatePolygon(ARROW_HEIGHT_LARGE, { bottom, top })};
		padding: ${({ bottom, top }) =>
			generatePadding(
				ARROW_HEIGHT_LARGE,
				{ bottom, top },
				blockCutterSidePaddingLargeInPx,
			)};
		margin-bottom: ${({ bottom }) =>
			bottom === null ? -1 : -ARROW_HEIGHT_LARGE}px;
	}

	.content-block__button {
		display: flex;
		justify-content: center;
		margin-top: 40px;
		margin-bottom: 25px;
	}
`

const BlockCutter = ({
	bottom,
	button,
	buttonContainerStyle,
	children,
	className,
	style,
	title,
	top,
}: React.PropsWithChildren<
	BlockCutterType & {
		button: JSX.Element | null
		buttonContainerStyle?: React.CSSProperties
		title: HeadingProps | null
		style?: React.CSSProperties
		className?: string
	}
>) => (
	<BlockCutterStyle
		{...{
			bottom,
			className,
			style,
			top,
		}}
	>
		{title !== null && <Heading {...title} />}
		{children}
		{button !== null && (
			<div className="content-block__button" style={buttonContainerStyle}>
				{button}
			</div>
		)}
	</BlockCutterStyle>
)

BlockCutter.defaultProps = {
	bottom: null,
	button: null,
	title: null,
	top: null,
}

export { BlockCutter }
