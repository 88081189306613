import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from '../../components/Button'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { Color } from '../../theme'
import { HOME_PATH } from '../home/View'

const NotFoundStyle = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: ${Color.AT_WHITE_PRIMARY};
	background-color: ${Color.AT_GRAY};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	> *:not(:first-child) {
		margin-top: 20px;
	}
`

const NotFoundView = () => {
	useScrollToTop()
	const history = useHistory()
	const { t } = useTranslation()

	return (
		<NotFoundStyle>
			<div>404 Not Found</div>
			<Button
				label={t('views.notFound.goBack')}
				type="yellow"
				onClick={() => history.push(HOME_PATH)}
			/>
		</NotFoundStyle>
	)
}

export { NotFoundView }
