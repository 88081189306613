import React from 'react'
import styled from 'styled-components'

import { Font, getFont, mediaBreakpoint } from '../../theme'

const FieldToggleStyle = styled.label`
	@media ${mediaBreakpoint.small} {
		width: 90%;
		padding-left: 20px;

		${getFont(Font.CAPTION_SMALL_12)};
	}

	@media ${mediaBreakpoint.mediumPlus} {
		width: 100%;
		padding-left: 30px;

		${getFont(Font.CAPTION_BIG_14)};
	}

	display: block;
	position: relative;
	cursor: pointer;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		left: 0;

		box-sizing: border-box;
		box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.18);
		border-radius: 3px;
		border: 0;
		background-color: #fff;
		top: 4px;

		@media ${mediaBreakpoint.small} {
			height: 12px;
			width: 12px;
		}

		@media ${mediaBreakpoint.mediumPlus} {
			height: 18px;
			width: 18px;
		}
	}

	input:checked ~ .checkmark {
		background-color: #2196f3;
	}

	.checkmark:after {
		content: '✓';
		position: absolute;
		display: none;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: #fff;
	}

	input:checked ~ .checkmark:after {
		display: flex;
	}
`

const FieldToggle = ({
	label,
	setValue,
	value,
}: {
	label: JSX.Element | string
	setValue: (newValue: boolean) => void
	value: boolean
}) => (
	<FieldToggleStyle>
		{label}
		<input
			checked={value}
			size={1}
			type="checkbox"
			onChange={(e) => setValue(e.target.checked)}
		/>
		<div className="checkmark" />
	</FieldToggleStyle>
)

export { FieldToggle }
