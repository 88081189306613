import { AxiosError } from 'axios'
import React, { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Reaptcha from 'reaptcha'
import styled from 'styled-components'

import customerSupport from '../../assets/icons/customer-support.svg'
import error from '../../assets/icons/error.svg'
import thumbsUp from '../../assets/icons/thumbs-up.svg'
import { Button } from '../../components/Button'
import { FieldRadioGroup } from '../../components/fields/RadioGroup'
import { FieldSingleSelect } from '../../components/fields/SingleSelect'
import { FieldText } from '../../components/fields/Text'
import { FieldToggle } from '../../components/fields/Toggle'
import { Loader } from '../../components/Loader'
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../data'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { AGB_PATH } from '../legal/AGBView'
import { PRIVACY_POLICY_PATH } from '../legal/PrivacyPolicyView'

import {
	RegistrationServerResponseErrorCodes,
	useRegistrationForm,
} from './hooks'

const Form = styled.form`
	background: ${Color.AT_WHITE_PRIMARY};
	box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);

	padding: 65px;

	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin-top: 0;
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 42px;
		line-height: 144%;
		text-align: center;
		letter-spacing: 0.01em;
	}

	> div {
		display: flex;
		flex-direction: column;
		max-width: 720px;

		> * {
			flex: 1;
		}

		> *:not(:first-child) {
			margin-top: 20px;
		}

		margin-bottom: 65px;
	}

	.feedback-icon {
		padding-bottom: 50px;
	}
	.feedback-title {
		text-align: center;

		color: ${Color.AT_BLACK_1};

		@media ${mediaBreakpoint.mediumMinus} {
			${getFont(Font.MOBILE_H4_BOLD)};
		}

		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B0_BOLD)};
		}
	}
	.feedback-message {
		padding-bottom: 40px;

		text-align: center;
		color: ${Color.AT_BLACK_1};

		@media ${mediaBreakpoint.mediumMinus} {
			${getFont(Font.MOBILE_B1_REGULAR)};
		}

		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B0_REGULAR)};
		}

		a {
			display: contents;
			color: ${Color.AT_GREEN_2};

			@media ${mediaBreakpoint.mediumMinus} {
				${getFont(Font.MOBILE_H4_MEDIUM)};
			}

			@media ${mediaBreakpoint.mediumPlus} {
				${getFont(Font.H4_MEDIUM)};
			}
		}
	}

	a {
		color: unset;
		text-decoration: underline;
		cursor: pointer;

		:hover {
			color: ${Color.AT_OCHRE};
		}
	}
`

enum Operator {
	YES = 'YES',
	NO = 'NO',
	NULL = 'NULL',
}

const RegistrationForm = () => {
	const { t } = useTranslation()
	const registrationForm = useRegistrationForm()

	const [isWaitingOnServer, setIsWaitingOnServer] = useState(false)
	const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState<
		boolean | null
	>(null)
	const [emailAlreadyExistsError, setEmailAlreadyExistsError] = useState(false)
	const [companyAlreadyExistsError, setCompanyAlreadyExistsError] =
		useState(false)

	const shouldRenderForm = useCallback(() => {
		return !isWaitingOnServer && isRegistrationSuccessful === null
	}, [isWaitingOnServer, isRegistrationSuccessful])

	const shouldRenderFormFeedback = useCallback(() => {
		return !isWaitingOnServer && isRegistrationSuccessful !== null
	}, [isWaitingOnServer, isRegistrationSuccessful])

	const agreeToTermsToggleLabel = (
		<Trans
			components={{
				agb: <Link rel="noreferrer noopener" target="_blank" to={AGB_PATH} />,
				privacyPolicy: (
					<Link
						rel="noreferrer noopener"
						target="_blank"
						to={PRIVACY_POLICY_PATH}
					/>
				),
			}}
			i18nKey="views.registrationForm.form.agreeToTerms"
		/>
	)

	const form = useCallback(() => {
		return (
			<>
				<h2>{t('views.registration.form.title')}</h2>
				<div>
					<FieldText
						placeholder={t('views.registration.form.firstName')}
						setValue={registrationForm.setFirstName}
						value={registrationForm.firstName}
					/>
					<FieldText
						placeholder={t('views.registration.form.lastName')}
						setValue={registrationForm.setLastName}
						value={registrationForm.lastName}
					/>
					<FieldText
						placeholder={t('views.registration.form.email')}
						setValue={registrationForm.setEmail}
						validationError={t(
							'views.registration.form.emailInvalidValidationError',
						)}
						validator={registrationForm.validateEmail}
						value={registrationForm.email}
					/>
					<FieldText
						placeholder={t('views.registration.form.company')}
						setValue={registrationForm.setCompany}
						value={registrationForm.company}
					/>
					<FieldText
						placeholder={t('views.registration.form.phone')}
						setValue={registrationForm.setPhone}
						validationError={t(
							'views.registration.form.phoneNotInInternationalFormatValidatinError',
						)}
						validator={registrationForm.validateE164PhoneNumber}
						value={registrationForm.phone}
					/>
					<FieldSingleSelect
						options={registrationForm.industryOptions}
						placeholder={t('views.registration.form.industryPlaceholder')}
						required
						setValue={registrationForm.setIndustry}
						value={registrationForm.industry}
					/>
					<p>{t('views.registrationForm.form.questionAboutDrone')}</p>
					<FieldRadioGroup<Operator>
						options={[
							{
								label: t('views.registrationForm.form.yes'),
								value: Operator.YES,
								subtext: t('views.registrationForm.form.yesSubtext'),
							},
							{
								label: t('views.registrationForm.form.no'),
								value: Operator.NO,
								subtext: t('views.registrationForm.form.noSubtext'),
							},
						]}
						setValue={registrationForm.setHasDrone}
						value={registrationForm.hasDrone}
					/>
					<div style={{ display: 'flex', width: '100%' }}>
						<Reaptcha
							ref={registrationForm.reaptchaRef}
							sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
							onVerify={registrationForm.setGoogleRecaptchaResponse}
						/>
					</div>
					<FieldToggle
						label={t('views.registrationForm.form.subscribeToNewsletter')}
						setValue={registrationForm.setSubscribeToNewsletter}
						value={registrationForm.subscribeToNewsletter}
					/>
					<FieldToggle
						label={agreeToTermsToggleLabel}
						setValue={registrationForm.setAgreeToTerms}
						value={registrationForm.agreeToTerms}
					/>
				</div>
				<Button
					disabled={!registrationForm.isFormValid() || isWaitingOnServer}
					label={t('buttons.tryForFree')}
					type="yellow"
					onClick={async (e) => {
						e.preventDefault()
						setIsWaitingOnServer(true)
						try {
							await registrationForm.submit()
							setIsRegistrationSuccessful(true)
						} catch (e) {
							setIsRegistrationSuccessful(false)
							const err = e as AxiosError
							if (err.response?.data) {
								if (
									err.response.status ===
									RegistrationServerResponseErrorCodes.EMAIL_ALREADY_EXISTS
								) {
									setEmailAlreadyExistsError(true)
								}
								if (
									err.response.status ===
									RegistrationServerResponseErrorCodes.COMPANY_ALREADY_EXISTS
								) {
									setCompanyAlreadyExistsError(true)
								}
							}
						}
						setIsWaitingOnServer(false)
						await registrationForm.resetForm()
					}}
				/>
			</>
		)
	}, [isWaitingOnServer, registrationForm, agreeToTermsToggleLabel, t])

	const formFeedback = () => {
		return (
			<>
				<img
					alt={isRegistrationSuccessful ? 'Thumbs up' : 'Error'}
					className="feedback-icon"
					src={
						isRegistrationSuccessful
							? thumbsUp
							: companyAlreadyExistsError
							? customerSupport
							: error
					}
				/>
				<div className="feedback-title">
					<Trans
						i18nKey={
							isRegistrationSuccessful
								? 'views.registration.formFeedback.success.title'
								: emailAlreadyExistsError
								? 'views.registration.formFeedback.failure.emailAlreadyExists.title'
								: companyAlreadyExistsError
								? 'views.registration.formFeedback.failure.companyAlreadyExists.title'
								: 'views.registration.formFeedback.failure.genericError.title'
						}
					/>
				</div>
				<div className="feedback-message">
					<Trans
						i18nKey={
							isRegistrationSuccessful
								? 'views.registration.formFeedback.success.message'
								: emailAlreadyExistsError
								? 'views.registration.formFeedback.failure.emailAlreadyExists.message'
								: companyAlreadyExistsError
								? 'views.registration.formFeedback.failure.companyAlreadyExists.message'
								: 'views.registration.formFeedback.failure.genericError.message'
						}
					>
						<a
							href="https://airteam.cloud/"
							rel="noopener noreferrer"
							target="_blank"
						>
							content that gets replaced
						</a>
					</Trans>
				</div>
			</>
		)
	}

	return (
		<Form>
			{shouldRenderForm() && form()}
			{isWaitingOnServer && <Loader />}
			{shouldRenderFormFeedback() && formFeedback()}
		</Form>
	)
}

export { RegistrationForm }
