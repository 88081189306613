import React from 'react'
import fastFusionSolarDeviceGif from '../assets/ff_solar.gif'
import laptopSolar from '../assets/laptop-solar.svg'
import styled from 'styled-components'
import { mediaBreakpoint } from '../theme'

const FlexCenter = styled.div`
	display: flex;
	justify-content: center;

	position: relative;

	.solar-devices_k {
		display: flex;
		flex-direction: row;
		margin-top: -120px;
		margin-bottom: 10px;
		margin-left: -180px;

		@media ${mediaBreakpoint.small} {
			margin-left: -100px;
			margin-top: -100px;
		}
	}

	.laptop-solar_k {
		margin-right: -20px;

		@media ${mediaBreakpoint.small} {
			width: 500px;
		}
	}

	.solar-gif_k {
		margin-left: -600px;
		margin-top: 132px;
		width: 472px;
		height: 300px;

		@media ${mediaBreakpoint.small} {
			width: 303px;
			height: 188px;
			margin-left: -374px;
			margin-top: 86px;
		}
	}
`

export default function LaptopGif() {
	return (
		<FlexCenter>
			<div className="solar-devices_k">
				<img alt="laptop solar" className="laptop-solar_k" src={laptopSolar} />
				<img
					alt="solar gif"
					className="solar-gif_k"
					src={fastFusionSolarDeviceGif}
				/>
			</div>
		</FlexCenter>
	)
}
