import styled from 'styled-components'

import { Font, getFont, mediaBreakpoint } from '../../../theme'

const TopContent = styled.div`
	display: grid;

	align-items: center;

	@media ${mediaBreakpoint.small} {
		grid-template-columns: 1fr;
		justify-content: center;
		margin: 0 20px 30px 20px;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		grid-template-columns: 1fr 1fr;
		margin-bottom: 70px;
	}
	img {
		margin: auto;
		@media ${mediaBreakpoint.large} {
			width: 75%;
		}
		@media ${mediaBreakpoint.medium} {
			width: 50%;
			margin-right: 60px;
		}
		@media ${mediaBreakpoint.small} {
			width: 68%;
			margin-bottom: 20px;
		}
	}

	p {
		@media ${mediaBreakpoint.large} {
			${getFont(Font.H4_MEDIUM)}

			width: 80%;
		}
		@media ${mediaBreakpoint.medium} {
			${getFont(Font.MOBILE_H4_MEDIUM)}
			width: 80%;
		}
		@media ${mediaBreakpoint.small} {
			text-align: center;
		}
	}
`
const TopContentRealEstatePricing = styled.div`
	align-items: center;
	align: center;

	@media ${mediaBreakpoint.small} {
		grid-template-columns: 1fr;
		justify-content: center;
		margin: 0 20px 30px 20px;
	}
	@media ${mediaBreakpoint.mediumPlus} {
		grid-template-columns: 1fr 1fr;
		margin-bottom: 70px;
	}

	p {
		text-align: center;
		align: center;
		@media ${mediaBreakpoint.large} {
			${getFont(Font.H4_MEDIUM)}
			align: center;
			width: 80%;
			margin-left: 10%;
		}
		@media ${mediaBreakpoint.medium} {
			${getFont(Font.MOBILE_H4_MEDIUM)}
			align: center;
			width: 80%;
		}
	}
`

export { TopContent, TopContentRealEstatePricing }
