import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { BlockCutter } from '../../components/BlockCutter'
import { Button } from '../../components/Button'
import { IsThePriceRight } from '../../components/is-the-price-right/Component'
import { PageHeader } from '../../components/PageHeader'
import { PriceCalculator } from '../../components/price-calculator/Component'
import { QuoteHeadline } from '../../components/QuoteHeadline'
import { useScrollToElementId } from '../../hooks/use-scroll-to-element-id'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Color, mediaBreakpoint } from '../../theme'

import titleBackground from './assets/title-background.jpg'
import { PricingViewSection2Subscriptions } from './sections/Section2Subscriptions'
import { PricingViewSection3DronePackage } from './sections/Section3DronePackage'
import { PricingViewSection5FrequentlyAskedQuestions } from './sections/Section5FrequentlyAskedQuestions'

const HeadlineButtons = styled.div`
	display: flex;
	justify-content: space-between;

	@media ${mediaBreakpoint.large} {
		margin-inline: 65px;
	}
	@media ${mediaBreakpoint.mediumMinus} {
		align-items: center;
		flex-direction: column;
	}

	button {
		@media ${mediaBreakpoint.mediumPlus} {
			width: 350px;
		}
		@media ${mediaBreakpoint.mediumMinus} {
			:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		@media ${mediaBreakpoint.small} {
			width: 300px;
		}
	}
`

export const DRONE_PACKAGE_ELEMENT_ID = 'drone-package' as const
const SUBSCRIPTIONS_ELEMENT_ID = 'subscriptions'
const SINGLE_PRICES_ELEMENT_ID = 'single-prices'

const PricingView = () => {
	useScrollToTop()
	useScrollToElementId()
	document.title = 'Preise | AIRTEAM'

	const { t } = useTranslation()
	const history = useHistory()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				text={t('views.pricing.pageHeader')}
			/>
			<BlockCutter bottom="out" top="in">
				<QuoteHeadline color={Color.AT_BLACK_1} lineColor={Color.AT_GREEN_1}>
					{t('views.pricing.heading')}
				</QuoteHeadline>
				<HeadlineButtons>
					<Button
						label={t('views.pricing.buttons.flatRate')}
						type="yellow"
						onClick={() => history.push({ hash: SUBSCRIPTIONS_ELEMENT_ID })}
					/>
					<Button
						label={t('views.pricing.buttons.einzelpreise')}
						type="yellow"
						onClick={() => history.push({ hash: SINGLE_PRICES_ELEMENT_ID })}
					/>
					<Button
						label={t('views.pricing.buttons.dronePackages')}
						type="yellow"
						onClick={() => history.push({ hash: DRONE_PACKAGE_ELEMENT_ID })}
					/>
				</HeadlineButtons>
			</BlockCutter>
			<div id={SUBSCRIPTIONS_ELEMENT_ID} />
			<PricingViewSection2Subscriptions />
			<div id={DRONE_PACKAGE_ELEMENT_ID} />
			<PricingViewSection3DronePackage />
			<div id={SINGLE_PRICES_ELEMENT_ID} />
			<PriceCalculator />
			<PricingViewSection5FrequentlyAskedQuestions />
			<IsThePriceRight />
		</DefaultPageLayout>
	)
}

export const PRICING_PATH = '/pricing' as const

export { PricingView }
