import constate from 'constate'

import { useBlogs } from './modules/blogs'
import { useProjectClassifications } from './modules/classifications'
import { useCookies } from './modules/cookies'
import { useBanner } from './modules/banner'

export const [StoreProvider, useStore] = constate(function useStore() {
	return {
		...useBlogs(),
		...useCookies(),
		...useProjectClassifications(),
		...useBanner(),
	}
})
