import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ClampLines from 'react-clamp-lines'

import { BlogEntryType } from '../../../store/modules/blogs'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'
import { BLOG_DETAIL_PATH } from '../../blog/DetailView'

const Section = styled.section`
	h4 {
		color: ${Color.AT_BLACK_NOT_1};
		margin: 0;
	}

	.meta {
		color: ${Color.AT_BLACK_2};
	}

	.content {
		&,
		& * {
			color: ${Color.AT_BLACK_2};
			${getFont(Font.B0_REGULAR)}
		}
	}

	> *:not(:first-child) {
		margin-top: 15px;
	}

	img {
		width: 100%;
	}

	@media ${mediaBreakpoint.small} {
		margin: 0 40px;

		h4 {
			${getFont(Font.MOBILE_H2_BOLD)}
		}

		.meta {
			${getFont(Font.MOBILE_CAPTION_REGULAR)}
		}

		.content {
			&,
			& * {
				${getFont(Font.MOBILE_B2_REGULAR)}
			}
		}
	}

	@media ${mediaBreakpoint.mediumPlus} {
		h4 {
			${getFont(Font.H4_BOLD)}
		}

		.meta {
			${getFont(Font.CAPTION_BIG_14)}
		}

		.content {
			&,
			& * {
				${getFont(Font.B0_REGULAR)}
			}
		}
	}
	.clamp-lines__button {
		display: none;
	}
`

const MoreLink = styled.div`
	cursor: pointer;
	color: ${Color.AT_GREEN_2};

	:hover {
		color: ${Color.AT_OCHRE};
	}
`

const Image = styled.div<{ backgroundImage: string }>`
	width: 100%;
	padding-bottom: 72.5%;
	background-size: cover;
	background-position: center center;
	background-image: url(${({ backgroundImage }) => backgroundImage});
`

const html2text = (html: string) => {
	const tag = document.createElement('div')
	tag.innerHTML = html
	return tag.innerText.trim()
}

const BlogEntry = (entry: BlogEntryType) => {
	const history = useHistory()
	const { t } = useTranslation()

	const introduction = html2text(entry.einleitung)
	const title = html2text(entry.titel)

	return (
		<Section>
			<Image
				backgroundImage={entry.bild}
				role="img"
				title={entry.bild_caption ?? undefined}
			/>
			{entry.bild_caption && (
				// eslint-disable-next-line @typescript-eslint/naming-convention
				<p dangerouslySetInnerHTML={{ __html: entry.bild_caption }} />
			)}
			<div className="meta">
				{dayjs(entry.created_at).format('D MMMM YYYY')}
			</div>
			<ClampLines id="clamp-title" text={title} innerElement="h4" lines={2} />
			<ClampLines
				id="clamp-intro"
				text={introduction}
				innerElement="div"
				lines={4}
			/>
			<MoreLink
				onClick={() =>
					history.push(
						BLOG_DETAIL_PATH.replace(':id', String(entry.id)).replace(
							':slug',
							entry.slug,
						),
					)
				}
			>
				{t('views.blog.entry.readMore')}
			</MoreLink>
		</Section>
	)
}

export { BlogEntry }
