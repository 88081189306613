import { Locale } from 'locale-enum'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import newDe from '../assets/icons/new/de.png'
import newEn from '../assets/icons/new/en.png'
import { Color, Font, getFont, mediaBreakpoint } from '../theme'

const HEIGHT_LINE = 10
const DISTANCE_LINE = 14
const REAL_MARGIN_BOTTOM = 50

const HeadingStyle = styled.h1<{ color: Color; lineColor: Color }>`
	color: ${({ color }) => color};
	text-align: center;
	position: relative;
	margin-bottom: ${HEIGHT_LINE + DISTANCE_LINE + REAL_MARGIN_BOTTOM}px;

	font-family: Manrope;
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 144%;
	letter-spacing: 0.01em;

	:after {
		content: '';
		position: absolute;
		bottom: -${HEIGHT_LINE + DISTANCE_LINE}px;
		left: 50%;
		transform: translateX(-50%);
		height: ${HEIGHT_LINE}px;
		width: 200px;
		background-color: ${({ lineColor }) => lineColor};
	}

	@media ${mediaBreakpoint.mediumMinus} {
		${getFont(Font.MOBILE_H1_BOLD)}
	}

	@media ${mediaBreakpoint.large} {
		${getFont(Font.H1)}
	}

	.new-badge {
		@media ${mediaBreakpoint.small} {
			position: absolute;
			top: -20px;

			animation-duration: 1s;
			animation-name: make-bigger;
			animation-iteration-count: infinite;
		}
		@media ${mediaBreakpoint.mediumPlus} {
			position: absolute;
			top: -60px;

			animation-duration: 2s;
			animation-name: make-bigger;
			animation-iteration-count: infinite;
			animation-timing-function: ease-in-out;
		}

		@keyframes make-bigger {
			0% {
				width: 15%;
			}

			50% {
				width: 18%;
			}
			100% {
				width: 15%;
			}
		}
	}
`

export type HeadingProps = {
	text: string
	color: Color
	lineColor: Color
}

const Heading = ({ color, lineColor, text }: HeadingProps) => {
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language
	const { t } = useTranslation()

	return (
		<HeadingStyle {...{ color, lineColor }}>
			<div>
				{text}
				{text === t('views.home.sections.ff3d.title') ||
				text === t('views.product.sections.thermal.title') ? (
					<img
						alt="New"
						className="new-badge"
						src={currentLanguage === Locale.de_DE ? newDe : newEn}
					/>
				) : null}
			</div>
		</HeadingStyle>
	)
}
export { Heading }
