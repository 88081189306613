import React from 'react'
import styled from 'styled-components'

import play from '../assets/icons/play.svg'
import { mediaBreakpoint } from '../theme'

const Style = styled.div`
	position: absolute;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	.play-icon {
		cursor: pointer;

		@media ${mediaBreakpoint.small} {
			width: 95px;
		}
		@media ${mediaBreakpoint.large} {
			width: 155px;
		}
	}
`

const YoutubeVideoPlayIcon = ({ onClick }: { onClick: () => void }) => {
	return (
		<Style>
			<img alt="play" className="play-icon" src={play} onClick={onClick} />
		</Style>
	)
}

export { YoutubeVideoPlayIcon }
