import { Locale } from 'locale-enum'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import emptyGrayCheck from '../../../assets/icons/empty-gray-check.svg'
import emptyGreenCheck from '../../../assets/icons/empty-green-check.svg'
import emptyPinkCheck from '../../../assets/icons/empty-pink-check.svg'
import emptyYellowCheck from '../../../assets/icons/empty-yellow-check.svg'
import grayPricingIcon from '../../../assets/icons/gray-pricing-icon.svg'
import greenPricingIcon from '../../../assets/icons/green-pricing-icon.svg'
import pinkPricingIcon from '../../../assets/icons/pink-pricing-icon.svg'
import yellowPricingIcon from '../../../assets/icons/yellow-pricing-icon.svg'
import { formatCurrencyInteger } from '../../../formatters/currency'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'
import { REGISTRATION_PATH } from '../../registration/View'

const FAST_FUSION = 'Fast Fusion'
const PRO_FUSION = 'Pro Fusion'
const MAX_FUSION = 'Max Fusion'
const ULTRA_FUSION = 'Ultra Fusion'

export interface CardProps {
	bottomText: string
	button: JSX.Element
	color:
		| Color.AT_YELLOW
		| Color.AT_BLACK_1
		| Color.AT_GREEN_2
		| Color.AT_YELLOW_2
		| Color.AT_GRAY_2
	isNew?: boolean
	subtext: string
	title: string
	price: {
		amount: number
		amountAnnual?: string | null
		disclaimer?: string | null
		disclaimerSmall?: string | null
		amountBiennial?: string | null
		disclaimer2?: string | null
		disclaimer2Small?: string | null
		pretext?: string | null
		subtext?: string | null
	}
	activeTab: number
	includedInPrice: string[]
	includedInPriceSpecial: string[]
	isCardExpanded?: boolean | null
	changeIsCardExpanded: () => void
}

const Card = styled.div<Pick<CardProps, 'color'>>`
	display: flex;
	flex-direction: column;

	position: relative;

	background-color: ${Color.AT_WHITE_PRIMARY};
	padding: 20px;
	padding-bottom: 0px;

	background: ${Color.AT_WHITE_PRIMARY};
	box-shadow: 10px 10px 29px rgba(0, 0, 0, 0.07), inset 3px 3px 4px #ffffff;
	border-radius: 10px;
	margin: 22px;
	width: 240px;

	&.most-requested {
		border-style: solid;
		border-width: 40px 5px 5px 5px;
		border-color: #23e7a5;

		&::before {
			position: absolute;
			top: -28px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			width: 100%;
			font-size: 18px;
		}

		&--en {
			&::before {
				content: '👌 Most Requested';
			}
		}

		&--de {
			&::before {
				content: '👌 Beliebt';
			}
		}
	}

	&.normal-card {
		margin-top: 62px;
	}

	@media ${mediaBreakpoint.largeMinus} {
		box-sizing: border-box;
		margin-inline: 20px;
		width: 100%;
		padding: 28px;
	}

	h4 {
		margin: 0;
		color: ${(props) => props.color};

		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_H0_BOLD)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			font-family: Manrope;
			font-size: 32px;
			font-style: normal;
			font-weight: 700;
			line-height: 36px;
			letter-spacing: 0.01em;
		}
	}

	.price {
		margin-top: 12px;
		color: ${(props) => props.color};

		@media ${mediaBreakpoint.small} {
			${getFont(Font.H2_SEMI_BOLD)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			font-family: Manrope;
			font-size: 39px;
			font-style: normal;
			font-weight: 600;
			line-height: 60px;
		}
		@media ${mediaBreakpoint.medium} {
			font-size: 44px;
		}

		&__container {
			min-height: 100px;
		}

		&__period {
			position: absolute;
			top: 115px;
			font-size: 21px;
			line-height: 20px;
		}

		&__annual {
			font-size: 14px;
			display: inline-block;
			background-color: ${(props) => props.color};
			color: white;
			border-radius: 999px;
			padding: 4px 8px;
			margin-top: 8px;
		}
	}

	button {
		margin: 0 0 50px 0;
		font-size: 14px;
		width: 100%;
	}

	.button-container {
		margin-top: 30px;
	}

	.included-in-price {
		@media ${mediaBreakpoint.medium} {
			width: 70%;
		}

		h4 {
			color: ${Color.AT_BLACK_1};

			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B1_BOLD)}
				margin-bottom: 40px;
			}
			@media ${mediaBreakpoint.medium} {
				${getFont(Font.B0_BOLD)}
				margin-bottom: 0;
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.B0_BOLD)}
				margin-bottom: 40px;
			}
		}

		&__list {
			height: 155px;
			@media ${mediaBreakpoint.small} {
				height: auto;
			}

			&--ff {
				@media ${mediaBreakpoint.mediumMinus} {
					height: 80px;
				}
			}
		}
	}

	ul {
		color: ${Color.AT_BLACK_TEXT};
		list-style: none;
		padding: 0;
		width: 14rem;

		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B3_REGULAR)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B1_REGULAR)}
		}

		li {
			display: flex;
			align-items: flex-start;
			min-height: 50px;

			margin-bottom: 20px;

			img {
				max-width: 27px;
				margin-right: 10px;
			}
		}
	}

	.bottom-text {
		padding: 10px 25px;
		background-color: rgba(9, 148, 131, 0.13);
		border-radius: 60px;

		@media ${mediaBreakpoint.small} {
			margin: auto auto 0 auto;
			/* width: 235px; */
		}
		@media ${mediaBreakpoint.medium} {
			margin-bottom: 60px;
			width: 330px;
		}
		@media ${mediaBreakpoint.large} {
			margin-top: auto;
			margin-bottom: 0;
			align-self: center;
			width: 220px;
		}

		div {
			color: ${Color.AT_GREEN_2};
			margin: auto;
			text-align: center;

			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B1_BOLD)}
			}
			@media ${mediaBreakpoint.mediumPlus} {
				font-family: Manrope;
				font-size: 22px;
				font-style: normal;
				font-weight: 700;
				line-height: 33px;
				letter-spacing: -0.04em;
			}
		}
	}

	.included__button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid ${Color.AT_GRAY_2};
		height: 48px;
		border-radius: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 153%;
		letter-spacing: 0.05em;
		background-color: ${Color.AT_WHITE_PRIMARY};
	}
`
const PricingCard = ({
	activeTab,
	bottomText,
	button,
	changeIsCardExpanded,
	color,
	includedInPrice,
	includedInPriceSpecial,
	isCardExpanded,
	isNew,
	price,
	subtext,
	title,
}: CardProps) => {
	const { isLarge, isMedium, isSmall } = useBreakpoints()
	const { i18n, t } = useTranslation()
	const currentLanguage = i18n.language
	const history = useHistory()

	const withoutVat = t('views.pricing.pricingCard.withoutVat')
	const priceOnRequest = t('views.pricing.pricingCard.priceOnRequest')

	return (
		<>
			<Card
				className={
					title === MAX_FUSION
						? currentLanguage === Locale.en_US
							? 'most-requested most-requested--en'
							: 'most-requested most-requested--de'
						: 'normal-card'
				}
				color={color}
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div style={{ marginBottom: 'auto' }}>
					<div
						style={{
							display: 'flex',
						}}
					>
						<div>
							<h4>{title}</h4>
							<span
								style={{
									fontSize: '16px',
									color: '#4E6169',
									fontWeight: 'bold',
								}}
							>
								{bottomText}
							</span>
						</div>
						<img
							alt="Checkmark"
							src={
								(title === FAST_FUSION && grayPricingIcon) ||
								(title === PRO_FUSION && yellowPricingIcon) ||
								(title === MAX_FUSION && greenPricingIcon) ||
								(title === ULTRA_FUSION && pinkPricingIcon) ||
								''
							}
							style={{
								width: '12%',
								position: 'absolute',
								top: '20px',
								right: '15px',
							}}
						/>
					</div>

					<div className={activeTab > 0 ? 'price__container' : ''}>
						<div className="price">
							{formatCurrencyInteger(price.amount)}
							<span className="price__period">
								/{t('views.pricing.sections.subscriptions.monthly')}
							</span>
						</div>
						{price.amountAnnual && (
							<div className="price__annual">{price.amountAnnual}</div>
						)}
					</div>
					<hr style={{ width: '100%', color: 'black', marginTop: '20px' }} />
					<p>{t('views.pricing.pricingCard.includes')}:</p>
					<div className="included-in-price">
						<ul>
							{includedInPrice.map((element, index) => (
								<li
									key={element}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									{element && (
										<div
											style={{
												display: 'flex',
												alignItems: 'flex-start',
												fontSize: '16px',
												lineHeight: '146%',
											}}
										>
											<img alt="Checkmark" src={emptyGrayCheck} />
											{element}
										</div>
									)}
								</li>
							))}
						</ul>
						<ul>
							{includedInPriceSpecial.map((element, index) => (
								<li
									key={element}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'flex-start',
											fontSize: '16px',
											lineHeight: '146%',
										}}
									>
										<img
											alt="Checkmark"
											src={
												(color === Color.AT_YELLOW && emptyYellowCheck) ||
												(color === Color.AT_GREEN_2 && emptyGreenCheck) ||
												(color === Color.AT_YELLOW_2 && emptyPinkCheck) ||
												emptyGrayCheck
											}
										/>
										<div
											dangerouslySetInnerHTML={{
												// eslint-disable-next-line @typescript-eslint/naming-convention
												__html: element,
											}}
										/>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="button-container">
					{title === FAST_FUSION && (
						<p
							style={{
								textAlign: 'center',
								color: '#0D69D4',
								cursor: 'pointer',
							}}
							onClick={() => history.push(REGISTRATION_PATH)}
						>
							{t('views.pricing.pricingCard.freeTrial')}
						</p>
					)}
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						{button}
					</div>
				</div>
			</Card>
		</>
	)
}

export { PricingCard }
