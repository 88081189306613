import styled from 'styled-components'

import { Color, Font, getFont, mediaBreakpoint } from '../../theme'

const TextBlock = styled.div`
	text-align: left;
	white-space: 'pre-wrap';

	${getFont(Font.B0_REGULAR)};

	color: ${Color.AT_BLACK_1};
	opacity: 0.8;

	@media ${mediaBreakpoint.small} {
		margin: 0 10px;
		${getFont(Font.MOBILE_B1_REGULAR)};
	}

	@media ${mediaBreakpoint.medium} {
		margin: 0 45px;
		${getFont(Font.B0_REGULAR)};
	}

	@media ${mediaBreakpoint.large} {
		margin: 0 85px;
		${getFont(Font.B0_REGULAR)};
	}
`

export { TextBlock }
