import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import blackCheckmark from '../../../assets/icons/black-check.svg'
import { formatCurrencyInteger } from '../../../formatters/currency'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'

export interface RealEstateCardProps {
	title: string
	color: Color.AT_BLACK_1
	subtext1: string
	subtext2: string
	price: {
		amount: number
	}
	specialWishes: string[]
	bottomText1: string
	bottomText2: string
}

const RealEstateCard = styled.div<Pick<RealEstateCardProps, 'color'>>`
	display: flex;
	flex-direction: column;

	position: relative;

	background-color: ${Color.AT_WHITE_PRIMARY};
	padding: 45px;

	background: ${Color.AT_WHITE_PRIMARY};
	box-shadow: 10px 10px 29px rgba(0, 0, 0, 0.07), inset 3px 3px 4px #ffffff;
	border-radius: 10px;

	@media ${mediaBreakpoint.small} {
		box-sizing: border-box;
		margin-inline: 20px;
		width: 100%;
		padding: 28px;
	}
	@media ${mediaBreakpoint.medium} {
		padding: 30px;
	}

	h4 {
		margin: 0 0 20px 0;
		color: ${(props) => props.color};

		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_H0_BOLD)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			font-family: Manrope;
			font-size: 38px;
			font-style: normal;
			font-weight: 700;
			line-height: 55px;
			letter-spacing: 0.01em;
		}
	}

	.price {
		margin-top: -2rem;
		margin-bottom: 1rem;
		&__amount {
			@media ${mediaBreakpoint.small} {
				${getFont(Font.H2_SEMI_BOLD)}
			}
			@media ${mediaBreakpoint.mediumPlus} {
				font-family: Manrope;
				font-size: 60px;
				font-style: normal;
				font-weight: 600;
				line-height: 86px;
			}
			@media ${mediaBreakpoint.medium} {
				font-size: 54px;
			}

			color: ${Color.AT_YELLOW};
		}
	}

	.subtext {
		height: 13rem;
		@media ${mediaBreakpoint.mediumMinus} {
			height: 20rem;
		}
		@media ${mediaBreakpoint.small} {
			height: 11rem;
		}
		ul {
			color: ${Color.AT_BLACK_TEXT};
			list-style: none;
			padding: 0;
			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B3_REGULAR)}
			}
			@media ${mediaBreakpoint.mediumPlus} {
				${getFont(Font.B1_REGULAR)}
			}

			li {
				display: flex;
				align-items: flex-start;
				font-family: 'Manrope';
				font-style: normal;
				font-weight: 600;
				font-size: 19px;
				line-height: 144%;
				margin-bottom: 20px;
				@media ${mediaBreakpoint.small} {
					${getFont(Font.MOBILE_B3_REGULAR)}
				}
			}
		}
	}

	hr {
		@media ${mediaBreakpoint.large} {
			background-color: ${(props) => props.color};
			height: 1px;
			width: 95%;
		}
		@media ${mediaBreakpoint.small} {
			background-color: ${(props) => props.color};
			height: 1px;
			width: 95%;
		}
	}

	.special-wishes {
		margin-top: 20px;
		@media ${mediaBreakpoint.medium} {
			width: 70%;
		}

		h4 {
			color: ${Color.AT_BLACK_1};

			@media ${mediaBreakpoint.small} {
				${getFont(Font.MOBILE_B1_BOLD)}
				margin-bottom: 40px;
			}
			@media ${mediaBreakpoint.medium} {
				${getFont(Font.B0_BOLD)}
				margin-bottom: 0;
			}
			@media ${mediaBreakpoint.large} {
				${getFont(Font.B0_BOLD)}
				margin-bottom: 40px;
			}
		}
	}

	ul {
		color: ${Color.AT_BLACK_TEXT};
		list-style: none;
		padding: 0;
		margin-bottom: 120px;

		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B3_REGULAR)}
		}
		@media ${mediaBreakpoint.mediumPlus} {
			${getFont(Font.B1_REGULAR)}
		}

		li {
			display: flex;
			align-items: flex-start;

			margin-bottom: 20px;

			img {
				max-width: 27px;
				margin-right: 10px;
			}
		}
	}
	.bottom-text {
		position: absolute;
		bottom: 0;

		.bottom-text1 {
			p {
				@media ${mediaBreakpoint.small} {
					font-family: Manrope;
					font-size: 50px !important;
					font-style: normal;
					font-weight: 600;
					line-height: 71px;
					letter-spacing: 15%;
				}
				@media ${mediaBreakpoint.mediumPlus} {
					font-family: Manrope;
					font-size: 50px !important;
					font-style: normal;
					font-weight: 600;
					line-height: 71px;
					letter-spacing: 15%;
				}
				color: ${(props) => props.color};
				margin: 0 auto;
			}
		}
		.bottom-text2 {
			margin-top: -30px;
			p {
				@media ${mediaBreakpoint.small} {
					font-family: Manrope;
					font-size: 28px !important;
					font-style: normal;
					font-weight: 600;
					line-height: 40px;
					letter-spacing: 1%;
				}
				@media ${mediaBreakpoint.mediumPlus} {
					font-family: Manrope;
					font-size: 28px !important;
					font-style: normal;
					font-weight: 600;
					line-height: 40px;
					letter-spacing: 1%;
				}
				color: ${(props) => props.color};
			}
		}
	}
`
const RealEstatePricingCard = ({
	bottomText1,
	bottomText2,
	color,
	price,
	specialWishes,
	subtext1,
	subtext2,
	title,
}: RealEstateCardProps) => {
	const { isMedium } = useBreakpoints()
	const { i18n, t } = useTranslation()

	const firstPart = useMemo(() => {
		return (
			<>
				<h4>{title}</h4>
				<div className="price">
					<span className="price__amount">
						{formatCurrencyInteger(price.amount)}
					</span>
				</div>
				<div className="subtext">
					<ul>
						<li key={subtext1}>{subtext1}</li>
						<li key={subtext2}>{subtext2}</li>
					</ul>
				</div>
				<hr></hr>
			</>
		)
	}, [price.amount, subtext1, subtext2, title])
	const secondPart = useMemo(() => {
		return (
			<>
				<div className="special-wishes">
					<h4>{t('views.pricing.realEstatePricingCard.specialWishes')}</h4>
					<ul>
						{specialWishes.map((element, index) => (
							<li key={element}>
								<img alt="Checkmark" src={blackCheckmark} />
								{element}
							</li>
						))}
					</ul>
				</div>
				<div className="bottom-text">
					<div className="bottom-text1">
						<p>{bottomText1}</p>
					</div>
					<div className="bottom-text2">
						<p>{bottomText2}</p>
					</div>
				</div>
			</>
		)
	}, [bottomText1, bottomText2, specialWishes, t])

	return (
		<>
			{isMedium ? (
				<>
					<RealEstateCard
						color={color}
						style={{
							boxSizing: 'border-box',
							marginLeft: '40px',
							marginRight: '10px',
							width: '33.3%',
						}}
					>
						{firstPart}
					</RealEstateCard>
					<RealEstateCard
						color={color}
						style={{
							boxSizing: 'border-box',
							marginRight: '40px',
							width: '66.7%',
						}}
					>
						{secondPart}
					</RealEstateCard>
				</>
			) : (
				<RealEstateCard color={color}>
					{firstPart}
					{secondPart}
				</RealEstateCard>
			)}
		</>
	)
}

export { RealEstatePricingCard }
