import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { BlockCutter } from '../../../components/BlockCutter'
import { Button } from '../../../components/Button'
import { Heading } from '../../../components/Heading'
import {
	ARROW_HEIGHT_LARGE,
	ARROW_HEIGHT_MEDIUM,
	ARROW_HEIGHT_SMALL,
} from '../../../data'
import { useBreakpoints } from '../../../hooks/use-breakpoints'
import { Color, makePattern, mediaBreakpoint } from '../../../theme'
import { PRODUCT_PATH } from '../../product/View'
import optionsBackground from '../assets/options-background.jpg'
import option1 from '../assets/options/1.png'
import option2 from '../assets/options/2.png'

const Options = styled.section`
	display: flex;

	> * {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;

		.content {
			display: flex;
			align-items: center;
			flex-direction: column;

			.title {
				font-family: Manrope;
				font-style: normal;
				font-weight: 600;
				font-size: 34px;
				line-height: 144%;
				text-align: center;
				letter-spacing: 0.01em;
				color: ${Color.AT_WHITE_PRIMARY};
			}

			.description {
				font-family: Mulish;
				font-style: normal;
				font-weight: normal;
				font-size: 23px;
				line-height: 150%;
				text-align: center;
				letter-spacing: 0.01em;
				color: ${Color.AT_GRAY_3};
			}

			> *:not(:first-child) {
				margin-top: 20px;
			}
		}
	}
`

const OptionsStyle = styled.div`
	.options {
		position: relative;

		&__yellow {
			background-image: url(${optionsBackground});
			background-position: center center;
			background-size: cover;
			height: 650px;
		}

		&__black {
			background-color: ${Color.AT_BLACK_1};
			background-image: url(${makePattern(Color.AT_GRAY, 10, 1)});
			background-size: 10px 10px;
			height: 650px;
		}

		&__content {
			padding: 70px 88px;
			position: absolute;

			@media ${mediaBreakpoint.small} {
				top: ${ARROW_HEIGHT_SMALL}px;
			}

			@media ${mediaBreakpoint.medium} {
				top: ${ARROW_HEIGHT_MEDIUM}px;
			}

			@media ${mediaBreakpoint.large} {
				top: ${ARROW_HEIGHT_LARGE}px;
			}

			left: 0;
			right: 0;

			&__button {
				display: flex;
				justify-content: center;
				margin-top: 100px;
			}
		}
	}
`

const IndustrySection4Options = () => {
	const { t } = useTranslation()
	const { isLarge } = useBreakpoints()
	const history = useHistory()

	if (!isLarge) return null

	return (
		<OptionsStyle>
			<BlockCutter
				bottom="in"
				className="options"
				style={{ padding: 0 }}
				top="in"
			>
				<div className="options__yellow" />
				<div className="options__black" />
				<div className="options__content">
					<Heading
						color={Color.AT_WHITE_PRIMARY}
						lineColor={Color.AT_YELLOW}
						text={t('views.industry.optionsTitle')}
					/>
					<Options>
						<div>
							<img alt="" src={option1} />
							<div className="content">
								<div className="title">{t('views.industry.optionIFly')}</div>
								<div className="description">
									{t('views.industry.optionIFlyDescription')}
								</div>
							</div>
						</div>
						<div>
							<img alt="" src={option2} />
							<div className="content">
								<div className="title">
									{t('views.industry.optionAirteamFlies')}
								</div>
								<div className="description">
									{t('views.industry.optionAirteamFliesDescription')}
								</div>
							</div>
						</div>
					</Options>
					<div className="options__content__button">
						<Button
							label={t('views.industry.optionsLearnMore')}
							type="yellow"
							onClick={() => history.push(PRODUCT_PATH)}
						/>
					</div>
				</div>
			</BlockCutter>
		</OptionsStyle>
	)
}

export { IndustrySection4Options }
