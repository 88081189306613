import React from 'react'

import { Color } from '../theme'

const Logo = ({
	color,
	style,
}: {
	color: Color
	style?: React.CSSProperties
}) => (
	<svg fill="none" height="42" viewBox="0 0 206 42" width="206" {...{ style }}>
		<g fill={color}>
			<path d="M6.91734 6.58207V7.78103L7.23849 6.62489L7.10289 7.13159L7.24562 6.62489L7.11003 7.13159L7.25276 6.62489C6.99504 6.55081 6.74236 6.46022 6.49627 6.35369C6.09365 6.18195 5.70268 5.98408 5.32586 5.76135C4.65831 5.37582 4.06986 4.86728 3.59166 4.26266C3.35807 3.96254 3.17267 3.62786 3.04213 3.27066C2.9059 2.88578 2.83827 2.47997 2.84231 2.0717H1.65762V3.27066H21.9186L35.0143 27.9349H37.869L23.3245 0.879883H0.437256V2.0717C0.437642 2.69411 0.533927 3.31274 0.722722 3.90582C1.02792 4.82011 1.53811 5.65251 2.21428 6.33942C3.0374 7.17239 4.00456 7.8494 5.06894 8.33769C5.55889 8.57569 6.06724 8.77378 6.58905 8.93003L6.74606 8.97285H21.3334L20.0416 6.58207" />
			<path d="M22.9885 12.4769H11.1559V13.6687L11.4771 12.5197L11.3272 13.0478L11.4771 12.5197L11.3272 13.0478L11.4771 12.5197C11.4771 12.5197 11.1702 12.427 10.7634 12.2414C10.3618 12.0697 9.97305 11.8693 9.60013 11.6419C8.92868 11.2411 8.33808 10.7183 7.85879 10.1004C7.61712 9.78656 7.42018 9.4407 7.27358 9.07272C7.13503 8.66113 7.0651 8.22955 7.06662 7.79526H5.86766V8.98709H18.785V6.58203H4.6687V7.78099C4.66918 8.41741 4.76541 9.05014 4.95417 9.65793C5.25368 10.5942 5.76116 11.4506 6.43859 12.1629C7.25476 13.0188 8.22271 13.7157 9.29325 14.2183C9.78671 14.4633 10.3 14.6663 10.8276 14.8249L10.9846 14.8677H24.2802" />
			<path d="M24.0964 5.84781H30.0056L36.6641 18.287L38.7694 17.1594L31.4401 3.45703H24.0964V5.84781Z" />
			<path d="M32.011 19.2843H47.8401V27.2132L52.9927 25.1863V33.8431L47.6545 31.8234V39.3526H15.9963V19.4699H26.6727L25.381 17.0791H13.5984V41.7434H50.0524V35.2847L55.3906 37.3115V21.6751L50.2308 23.7019V16.8936H32.011V19.2843Z" />
		</g>
		<path
			d="M201.803 38.0386H205.438V21.3214H202.139L197.506 28.9168L196.725 30.8702H196.597L195.734 28.9622L190.819 21.3214H187.512V38.0386H190.928V29.3438L190.401 26.0458H190.537L191.691 28.6988L195.834 34.9495H196.988L200.904 28.7169L202.04 25.9913H202.194L201.803 29.3166V38.0386ZM172.067 28.0083L172.612 25.3553H172.748L173.302 27.981L174.665 31.706H170.722L172.067 28.0083ZM176.927 38.0023H180.707L174.084 21.1579H171.431L164.798 38.0386H168.433L169.823 34.3408H175.474L176.927 38.0023ZM147.418 38.0023H158.439V35.1222H151.007V31.0428H157.657V28.1264H151.007V24.2287H158.275V21.3214H147.372L147.418 38.0023ZM140.268 21.3214H125.94V24.2287H131.328V38.0386H134.962V24.2287H140.268V21.3214ZM115.092 24.6012C115.335 24.8061 115.529 25.0642 115.656 25.3557C115.784 25.6472 115.843 25.9642 115.828 26.282C115.862 26.6745 115.796 27.0691 115.638 27.4296C115.479 27.79 115.232 28.1047 114.919 28.3444C114.203 28.7862 113.37 29.0015 112.53 28.9622H111.003V24.1106C111.275 24.0524 111.552 24.022 111.83 24.0198C112.182 24.0198 112.545 24.0198 112.921 24.0198C113.687 23.9648 114.449 24.1802 115.074 24.6285L115.092 24.6012ZM107.442 38.0386H111.076V31.1791L112.966 31.4971L117.145 38.0386H121.297L116.527 30.534L115.801 30.9156C116.91 30.6271 117.897 29.9902 118.617 29.0985C119.351 28.143 119.724 26.9589 119.671 25.7551C119.7 24.9439 119.492 24.1419 119.071 23.4474C118.68 22.8392 118.139 22.3418 117.5 22.0028C116.798 21.6418 116.044 21.3934 115.265 21.2669C114.427 21.1214 113.579 21.0484 112.73 21.0488H111.521C111.085 21.0488 110.613 21.0488 110.168 21.1215C109.722 21.1942 109.259 21.1942 108.778 21.2487C108.296 21.3032 107.869 21.3668 107.478 21.4304L107.442 38.0386ZM95.0586 38.0386H98.6927V21.3214H95.0586V38.0386ZM79.2136 28.0446L79.7678 25.3917H79.895L80.4492 28.0174L81.8211 31.7424H77.878L79.2136 28.0446ZM84.0743 38.0386H87.8629L81.2124 21.1942H78.5594L71.9634 38.0386H75.5976L76.9967 34.3408H82.6388L84.0743 38.0386Z"
			fill={Color.AT_WHITE_PRIMARY}
		/>
	</svg>
)

export { Logo }
