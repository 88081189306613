import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

declare global {
	interface Window {
		hbspt: {
			forms: {
				create: (config: HubSpotFormConfig) => void
			}
		}
	}
}

interface HubSpotFormConfig {
	region: string
	portalId: string
	formId: string
	target: string
}

interface HubspotRequestSubscriptionFormProps {
	activeTab: number
	packageOption: string
}

const formIds: Record<string, Record<number, Record<string, string>>> = {
	'en-US': {
		0: {
			ultra: 'adebe747-01d9-497a-9baa-7a73010ab70c',
		},
		1: {
			max: '403eb0ce-7015-4d2e-b93c-27b8128a908c',
			ultra: 'ef13e4df-87b6-4bb5-bd89-9525eab6bc64',
		},
		2: {
			max: 'fe56df41-eaa5-40f0-bbec-0a1f36ecf9d0',
			ultra: '9a0344a9-6332-47bd-98a9-b4dfab496390',
		},
	},
	'de-DE': {
		0: {
			ultra: 'e37c3884-ac13-4b25-a5fb-527f20a8f2f7',
		},
		1: {
			max: '8267b266-b565-45e3-9fd2-7367e23b94d2',
			ultra: 'eb9bc947-4c4a-413b-81c0-c23f869ae456',
		},
		2: {
			max: '3bb88ddc-e1f9-4679-aab8-cf1a6545fb9f',
			ultra: 'fd911547-34cf-43b4-857b-1f4a1cd62369',
		},
	},
}

const HubspotRequestSubscriptionForm: React.FC<
	HubspotRequestSubscriptionFormProps
> = ({ activeTab, packageOption }) => {
	const { i18n } = useTranslation()
	const currentLanguage = i18n.language

	useEffect(() => {
		const script: HTMLScriptElement = document.createElement('script')
		script.src = '//js.hsforms.net/forms/embed/v2.js'
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		document.body.appendChild(script)

		console.log(currentLanguage)
		console.log(activeTab)
		const subscriptionType =
			packageOption === 'Ultra Fusion AI' ? 'ultra' : 'max'
		const formId = formIds[currentLanguage]?.[activeTab]?.[subscriptionType]

		console.log(formId)

		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					formId: formId,
					portalId: '3797785',
					region: 'na1',
					target: '#hubspotForm',
				})
			}
		})
		return () => {
			document.body.removeChild(script)
		}
	}, [activeTab, packageOption, currentLanguage])

	return <div id="hubspotForm"></div>
}

export { HubspotRequestSubscriptionForm }
