import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Color, Font, getFont } from '../../theme'

import { useMenu } from './hooks/use-menu'
import { LinkEntry, ExternalEntry, NestedEntry } from './types'

const HeaderLinksStyle = styled.div`
	${getFont(Font.B1_REGULAR)}
	color: ${Color.AT_WHITE_PRIMARY};

	display: flex;
	align-items: center;

	> * {
		cursor: pointer;

		&.active {
			color: ${Color.AT_GREEN_1};
			font-weight: bold;
		}

		:hover {
			color: ${Color.AT_GREEN_1};
		}

		&:not(:first-child) {
			margin-left: 40px;
		}
	}
`

const Link = ({ label, to }: LinkEntry) => {
	const history = useHistory()
	const location = useLocation()

	return (
		<div
			className={location.pathname === to ? 'active' : ''}
			onClick={() =>
				to.startsWith('/') ? history.push(to) : (window.location.href = to)
			}
		>
			{label}
		</div>
	)
}

const External = ({ label, to }: ExternalEntry) => {
	const handleClick = () => {
		window.open(to, '_blank', 'noopener,noreferrer')
	}

	return (
		<div style={{ cursor: 'pointer' }} onClick={handleClick}>
			{label}
		</div>
	)
}

const NestedStyle = styled.div`
	position: relative;

	svg {
		fill: ${Color.AT_WHITE_PRIMARY};

		&.active {
			fill: ${Color.AT_GREEN_1};
		}
	}

	:hover {
		color: ${Color.AT_GREEN_1};

		svg {
			fill: ${Color.AT_GREEN_1};
		}

		> .nested-children {
			display: unset;
		}
	}

	> .nested-content {
		display: flex;
		align-items: center;

		&.active {
			color: ${Color.AT_GREEN_1};
			font-weight: bold;
		}

		> *:not(:first-child) {
			margin-left: 5px;
		}
	}

	> .nested-children {
		display: none;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateY(100%);
		z-index: 100;
		padding-top: 32px;
		min-width: 210px;

		> * {
			background-color: ${Color.AT_BLACK_1};
			color: ${Color.AT_GRAY_2};
			padding: 14px 18px;

			&.active {
				color: ${Color.AT_GREEN_1};
				font-weight: bold;
			}

			:hover {
				background-color: ${Color.AT_BLACK_2};
				color: ${Color.AT_GREEN_1};
			}

			&:not(:first-child) {
				border-top: 2px solid #253335;
			}
		}
	}
`

const Nested = ({ children, label }: NestedEntry) => {
	const location = useLocation()

	return (
		<NestedStyle>
			<div
				className={`nested-content ${
					children.some((child) => location.pathname === child.to)
						? 'active'
						: ''
				}`}
			>
				<span>{label}</span>
				<svg
					className={
						children.some((child) => location.pathname === child.to)
							? 'active'
							: ''
					}
					height="7"
					viewBox="0 0 12 7"
					width="12"
				>
					<path d="M6.21436 6.13574L0.70459 0.625977H11.7241L6.21436 6.13574Z" />
				</svg>
			</div>
			<div className="nested-children">
				{children.map((child, index) => {
					switch (child.type) {
						case 'link':
							return <Link key={index} {...child} />

						case 'external':
							return <External key={index} {...child} />

						default:
							throw new Error('unexpected link type')
					}
				})}
			</div>
		</NestedStyle>
	)
}

const HeaderDesktopLinks = () => {
	const { menu } = useMenu()

	return (
		<HeaderLinksStyle>
			{menu.map((item, index) => {
				switch (item.type) {
					case 'link':
						return <Link key={index} {...item} />

					case 'nested':
						return <Nested key={index} {...item} />

					case 'external':
						return <External key={index} {...item} />

					default:
						throw new Error('unexpected link type')
				}
			})}
		</HeaderLinksStyle>
	)
}

export { HeaderDesktopLinks }
