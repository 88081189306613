import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import fastFusionDevices from '../../assets/fast-fusion-devices.png'
import check from '../../assets/icons/green-check.svg'
import { BlockCutter } from '../../components/BlockCutter'
import { PageHeader } from '../../components/PageHeader'
import { QuoteHeadline } from '../../components/QuoteHeadline'
import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'

import titleBackground from './assets/title-background.jpg'
import { RegistrationForm } from './RegistrationForm'

const Benefits = styled.div`
	display: flex;
	flex-direction: column;

	> *:not(:first-child) {
		margin-top: 40px;
	}
`

const Benefit = styled.div`
	display: flex;
	align-items: center;

	> img {
		@media ${mediaBreakpoint.mediumMinus} {
			width: 20px;
			height: 20px;
		}

		@media ${mediaBreakpoint.large} {
			width: 25px;
			height: 25px;
		}
	}

	> div {
		flex: 1;

		font-family: Mulish;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		line-height: 143%;
		letter-spacing: 0.01em;

		color: ${Color.AT_BLACK_NOT_1};
	}

	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
		align-items: center;
		${getFont(Font.MOBILE_B2_REGULAR)};

		> *:not(:first-child) {
			margin-top: 15px;
		}
	}

	@media ${mediaBreakpoint.large} {
		flex-direction: row;

		> *:not(:first-child) {
			margin-left: 40px;
		}
	}
`

const BenefitsContainer = styled.div`
	position: relative;

	h3 {
		color: ${Color.AT_BLACK_NOT_1};
	}

	.bolder-text {
		text-shadow: 1px 0 #888888;
		letter-spacing: 1px;
		font-weight: bolder;
	}

	@media ${mediaBreakpoint.mediumMinus} {
		text-align: center;

		.laptop {
			height: 350px;
		}

		h3 {
			${getFont(Font.H3_BOLD)}
		}
	}

	@media ${mediaBreakpoint.large} {
		margin-left: 100px;
		padding-right: 700px;

		h3 {
			${getFont(Font.H2_SEMI_BOLD)}
		}

		.laptop {
			position: absolute;
			right: 65px;
			top: 0;
			width: 550px;
		}
	}
`

const RegistrationView = () => {
	useScrollToTop()
	document.title = 'Registrierung | AIRTEAM'
	const { t } = useTranslation()

	return (
		<DefaultPageLayout>
			<PageHeader
				backgroundImage={titleBackground}
				hideCta
				text={t('views.registration.text')}
			/>
			<BlockCutter
				title={{
					color: Color.AT_BLACK_NOT_1,
					lineColor: Color.AT_GREEN_1,
					text: t('views.registration.title'),
				}}
				top="in"
			>
				<QuoteHeadline
					color={Color.AT_BLACK_NOT_1}
					lineColor={Color.AT_GREEN_1}
				>
					{t('views.registration.registerText')}
				</QuoteHeadline>
				<BenefitsContainer>
					<img alt="Laptop Mockup" className="laptop" src={fastFusionDevices} />
					<h3>{t('views.registration.yourAdvantages')}</h3>
					<Benefits>
						<Benefit>
							<img alt="Checkmark" src={check} />
							<div>
								{t('views.registration.benefits.tryForFree1')}
								<span className="bolder-text">
									{t('views.registration.benefits.tryForFree2')}
								</span>
								{t('views.registration.benefits.tryForFree3')}
							</div>
						</Benefit>
						<Benefit>
							<img alt="Checkmark" src={check} />
							<div>{t('views.registration.benefits.onePlatform')}</div>
						</Benefit>
						<Benefit>
							<img alt="Checkmark" src={check} />
							<div>{t('views.registration.benefits.freeVideos')}</div>
						</Benefit>
					</Benefits>
				</BenefitsContainer>
				<div style={{ height: '100px' }} />
				<RegistrationForm />
			</BlockCutter>
		</DefaultPageLayout>
	)
}

export const REGISTRATION_PATH = '/registration' as const

export { RegistrationView }
