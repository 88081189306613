import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

import { Color, getFont, Font } from '../../theme'

const RangeSliderWrapper = styled.div`
	position: relative;

	input[type='range'] {
		appearance: none;
		margin: 20px 0;
		width: 100%;
		outline: none;
		transform: translateZ(0);
		will-change: transform;
	}
	input[type='range']:focus {
		outline: none;
	}

	input[type='range']::-webkit-slider-thumb {
		appearance: none;
		height: 24px;
		width: 24px;
		border-radius: 50%;
		background: ${Color.AT_BLACK_3};
		border: 3px solid ${Color.AT_GRAY_5};
		cursor: pointer;
		margin-top: -7px;
	}
	input[type='range']::-moz-range-thumb {
		appearance: none;
		height: 24px;
		width: 24px;
		border-radius: 50%;
		border-color: ${Color.AT_GRAY_5};
		background: ${Color.AT_BLACK_3};
		cursor: pointer;
		margin-top: -7px;
	}

	input[type='range']::-webkit-slider-runnable-track {
		width: 100%;
		height: 12px;
		cursor: pointer;
		background: ${Color.AT_BLACK_3};
		border: 2px solid ${Color.AT_GRAY_5};
		border-radius: 10px;
	}
	input[type='range']::-moz-range-track {
		width: 100%;
		height: 12px;
		cursor: pointer;
		border-radius: 10px;
		background: ${Color.AT_BLACK_3};
	}

	.range-value {
		position: absolute;
		top: -55%;

		&__bubble {
			display: block;
			position: absolute;
			left: 50%;
			top: -15px;
			transform: translate(-50%, 0);

			width: 90px;
			border-radius: 80px;
			text-align: center;

			${getFont(Font.B1_REGULAR)};
			color: ${Color.AT_BLACK_1};

			padding: 2px 15px;
			margin: 1em 0 3em;
			border: 1px solid ${Color.AT_GRAY_3};
			background: ${Color.AT_WHITE_PRIMARY};
		}

		&__bubble:before {
			content: '';
			position: absolute;
			bottom: -7px;
			left: 54px;
			border-width: 6px 6px 0;
			border-style: solid;
			border-color: ${Color.AT_GRAY_3} transparent;
			display: block;
			width: 0;
		}

		&__bubble:after {
			content: '';
			position: absolute;
			bottom: -6px;
			left: 54px;
			border-width: 6px 6px 0;
			border-style: solid;
			border-color: ${Color.AT_WHITE_PRIMARY} transparent;
			display: block;
			width: 0;
		}
	}
`

export type RangeSliderProps = {
	min: number
	max: number
	value: number
	setValue: (newValue: number) => void
}

const RangeSlider = ({ max, min, setValue, value }: RangeSliderProps) => {
	const rangeValueRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (rangeValueRef.current) {
			if (value < min || Number.isNaN(value)) {
				rangeValueRef.current.style.left = `0%`
			} else if (value > max) {
				rangeValueRef.current.style.left = `100%`
			} else {
				rangeValueRef.current.style.left = `100%`
				const newValuePercentage = ((value - min) * 100) / (max - min)
				const newPosition = 10 - newValuePercentage * 0.2
				rangeValueRef.current.style.left = `calc(${newValuePercentage}% + (${newPosition}px))`
			}
		}
	}, [value, min, max, rangeValueRef])

	return (
		<RangeSliderWrapper>
			<div className="range-value" ref={rangeValueRef}>
				<span className="range-value__bubble">
					{Number.isNaN(value) ? 0 : value} m²
				</span>
			</div>
			<input
				id="range"
				max={max}
				min={min}
				step="1"
				type="range"
				value={Number.isNaN(value) ? 0 : value}
				onChange={(e) => setValue(parseFloat(e.target.value))}
			/>
		</RangeSliderWrapper>
	)
}

export { RangeSlider }
