import React from 'react'

import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'

import { AbsLockBoxSection } from './components/AbsLockBoxSection'
import { IndustryHeader } from './components/Header'
import { IndustrySection1Quote2OldNew } from './components/Section1Quote2OldNew'
import { IndustrySection3Advantages } from './components/Section3Advantages'
import { IndustrySection4Options } from './components/Section4Options'
import { IndustrySection5Results } from './components/Section5Results'
import { IndustryType } from './types'

const IndustryRoofingView = () => {
	useScrollToTop()
	document.title = 'Dach | AIRTEAM'
	return (
		<DefaultPageLayout>
			<IndustryHeader type={IndustryType.ROOFING} />
			<IndustrySection1Quote2OldNew type={IndustryType.ROOFING} />
			<AbsLockBoxSection />
			<IndustrySection3Advantages type={IndustryType.ROOFING} />
			<IndustrySection4Options />
			<IndustrySection5Results type={IndustryType.ROOFING} />
		</DefaultPageLayout>
	)
}

export const INDUSTRY_ROOFING_PATH = '/industry/roofing' as const

export { IndustryRoofingView }
