import React from 'react'
import styled from 'styled-components'

import { useScrollToTop } from '../../hooks/use-scroll-to-top'
import { DefaultPageLayout } from '../../layouts/DefaultPage'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import euLogo from '../home/assets/eu-logo.jpg'

const TextBlock = styled.div`
	background-color: #fbfafd;
	box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.1);
	border-radius: 3px;

	display: flex;
	justify-content: space-between;

	@media ${mediaBreakpoint.mediumPlus} {
		flex-direction: row;
		align-items: flex-start;

		padding: 100px;
		margin: 150px 0;
	}
	@media ${mediaBreakpoint.mediumMinus} {
		flex-direction: column;
		align-items: center;

		padding: 40px;
		margin: 10px;
	}

	img {
		@media ${mediaBreakpoint.mediumPlus} {
			width: 35%;
			max-width: 400px;
		}
		@media ${mediaBreakpoint.mediumMinus} {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	div {
		@media ${mediaBreakpoint.mediumPlus} {
			width: 60%;
		}

		@media ${mediaBreakpoint.mediumMinus} {
			text-align: center;
		}

		font-family: Manrope;
		font-size: 17px;
		font-style: normal;
		font-weight: bold;
		line-height: 26px;
		letter-spacing: 0.01em;
		text-align: left;

		div:first-child {
			width: 90%;
			${getFont(Font.B1_BOLD)}
			color: ${Color.AT_GREEN_2};
		}
		div + div {
			font-weight: normal;

			width: 90%;

			@media ${mediaBreakpoint.mediumPlus} {
				margin-top: 20px;
			}
			@media ${mediaBreakpoint.mediumMinus} {
				margin-top: 25px;
			}
		}
	}
`
const EuropeanUnionView = () => {
	useScrollToTop()
	document.title = 'EU | AIRTEAM'

	return (
		<DefaultPageLayout withBoxShadow={false}>
			<TextBlock>
				<img alt="EU Logo" src={euLogo} />
				<div>
					<div>
						Airteam Aerial Intelligence ermöglicht es jedem Bauunternehmen
						bessere Entscheidungen zu treffen, mit Hilfe von Drohnendaten,
						moderner Software und künstlicher Intelligenz.
					</div>
					<div>
						Dieses Projekt wird kofinanziert durch den Europäischen Fonds für
						regionale Entwicklung [EFRE] und wird mit Mitteln des Landes Berlin
						im Rahmen des Programms zur Förderung von Forschung, Innovationen
						und Technologien - Pro FIT - unter der Antragsnummer 10170075
						gefördert. Die Verantwortung für den Inhalt dieser Veröffentlichung
						liegt beim Autor.
					</div>
				</div>
			</TextBlock>
		</DefaultPageLayout>
	)
}

export const EUROPEAN_UNION_PATH = '/european-union' as const

export { EuropeanUnionView }
