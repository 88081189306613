import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Color, mediaBreakpoint } from '../theme'

type CarouselContainerType = {
	currentIndex: number
	total: number
	alignButtons: string
}
const CarouselContainer = styled.section<CarouselContainerType>`
	position: relative;

	.carousel__container {
		box-sizing: border-box;
		width: 100%;
		overflow: hidden;

		@media ${mediaBreakpoint.large} {
			overflow: hidden;
		}

		&__content {
			margin-left: ${({ currentIndex }) => currentIndex * -100}%;
			transition: all 0.4s ease-in-out;
			width: ${({ total }) => total * 100}%;
			display: flex;

			transform: translateZ(0);
			will-change: transform;

			> * {
				width: 100%;
				display: flex;
			}
		}
	}

	.button-wrapper {
		position: absolute;
		top: 40%;
		z-index: 1;

		transform: translateZ(0);
		will-change: transform;
		filter: drop-shadow(0.782197px 3.12879px 19.5549px rgba(0, 0, 0, 0.3));

		&--is-left {
			left: 10px;
		}
		&--is-right {
			right: 10px;
		}
	}

	.button {
		border: 0;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		height: 40px;
		width: 40px;
		background-color: ${Color.AT_WHITE_PRIMARY};
		transform: translateY(-50%);

		@supports (clip-path: circle(20px at center)) {
			clip-path: circle(20px at center);
			border-radius: 0px;
		}

		transition: all 0.4s ease-in-out;
		cursor: pointer;

		&:focus {
			outline: 0;
		}

		@media (hover: hover) {
			&:hover {
				background-color: ${Color.AT_GREEN_2};
			}
		}

		&--is-left {
			svg {
				margin-right: 3px;
			}
		}

		&--is-right {
			svg {
				transform: scale(-1, 1);
				margin-left: 3px;
			}
		}
	}

	.dots {
		display: flex;
		align-items: center;
		justify-content: ${({ alignButtons }) => alignButtons};

		@media ${mediaBreakpoint.mediumMinus} {
			margin: 25px 0;
		}
		@media ${mediaBreakpoint.large} {
			margin-bottom: 25px;
		}

		.dot {
			background-color: ${Color.AT_GRAY_2};
			transition: all 0.4s ease-in-out;
			height: 6px;
			border-radius: 3px;
			width: 6px;
			margin: 0 8px;
			cursor: pointer;

			&--is-active {
				background-color: ${Color.AT_GREEN_1};
				width: 18px;
				margin: 0 2px;
			}
		}
	}
`

const Carousel = ({
	alignButtons = 'center',
	autoRotate,
	items,
	showButtons,
	onChange,
}: {
	autoRotate?: boolean
	items: JSX.Element[]
	showButtons?: boolean
	alignButtons?: string
	onChange?: (newIndex: number) => void
}) => {
	const [currentIndex, setCurrentIndex] = useState(0)

	const [goingForwards, setGoingForwards] = useState(true)

	const [touchStart, setTouchStart] = React.useState(0)
	const [touchEnd, setTouchEnd] = React.useState(0)

	const goToNext = useCallback(() => {
		setCurrentIndex((x) => (x + 1 + items.length) % items.length)
		if (onChange) {
			onChange((currentIndex + 1 + items.length) % items.length)
		}
	}, [setCurrentIndex, items.length, onChange])
	const goToPrevious = useCallback(() => {
		setCurrentIndex((x) => (x - 1 + items.length) % items.length)
		if (onChange) {
			onChange((currentIndex - 1 + items.length) % items.length)
		}
	}, [setCurrentIndex, items.length, onChange])

	useEffect(() => {
		if (autoRotate) {
			const timer = setInterval(() => {
				if (currentIndex === items.length - 1 && goingForwards) {
					setGoingForwards(false)
					goToPrevious()
				} else {
					if (currentIndex === 0 && !goingForwards) {
						setGoingForwards(true)
						goToNext()
					} else {
						if (goingForwards) {
							goToNext()
						} else {
							goToPrevious()
						}
					}
				}
			}, 6000)
			return () => clearInterval(timer)
		}
	}, [
		autoRotate,
		goToNext,
		goToPrevious,
		items.length,
		currentIndex,
		goingForwards,
	])

	const handleTouchStart = useCallback(
		(e: React.TouchEvent<HTMLDivElement>) => {
			setTouchStart(e.targetTouches[0].clientX)
			setTouchEnd(e.targetTouches[0].clientX)
		},
		[],
	)
	const handleTouchMove = useCallback(
		(e: React.TouchEvent<HTMLDivElement>) =>
			setTouchEnd(e.targetTouches[0].clientX),
		[],
	)
	const handleTouchEnd = useCallback(() => {
		if (Math.abs(touchStart - touchEnd) > (10 / 100) * window.innerWidth) {
			if (touchStart > touchEnd) {
				setCurrentIndex((x) => (x + 1 + items.length) % items.length)
			} else {
				setCurrentIndex((x) => (x - 1 + items.length) % items.length)
			}
		}
	}, [touchEnd, touchStart, items.length])

	const handlePreviousClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			if (e.detail > 1) return // ignore double-click
			goToPrevious()
		},
		[goToPrevious],
	)

	const handleNextClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			if (e.detail > 1) return // ignore double-click
			goToNext()
		},
		[goToNext],
	)

	return (
		<CarouselContainer
			total={items.length}
			{...{ currentIndex }}
			alignButtons={alignButtons}
		>
			{showButtons && (
				<span className="button-wrapper button-wrapper--is-left">
					<button
						className="button button--is-left"
						onClick={handlePreviousClick}
						onDoubleClick={() => undefined}
					>
						<svg
							fill="none"
							height="18"
							stroke={Color.AT_GREEN_1}
							strokeWidth="2.34659"
							viewBox="0 0 11 18"
							width="11"
						>
							<path d="M10.0515 1.17578L2.22949 8.99769L10.0515 16.8197" />
						</svg>
					</button>
				</span>
			)}
			<div className="carousel__container">
				<div
					className="carousel__container__content"
					onTouchEnd={() => !autoRotate && handleTouchEnd()}
					onTouchMove={(e) => !autoRotate && handleTouchMove(e)}
					onTouchStart={(e) => !autoRotate && handleTouchStart(e)}
				>
					{items.map((item, index) => (
						<div key={index}>{item}</div>
					))}
				</div>
			</div>
			{showButtons && (
				<span className="button-wrapper button-wrapper--is-right">
					<button className="button button--is-right" onClick={handleNextClick}>
						<svg
							fill="none"
							height="18"
							stroke={Color.AT_GREEN_1}
							strokeWidth="2.34659"
							viewBox="0 0 11 18"
							width="11"
						>
							<path d="M10.0515 1.17578L2.22949 8.99769L10.0515 16.8197" />
						</svg>
					</button>
				</span>
			)}
			<div className="dots">
				{items.map((_, index) => (
					<div
						className={currentIndex === index ? 'dot dot--is-active' : 'dot'}
						key={index}
						onClick={() => setCurrentIndex(index)}
					/>
				))}
			</div>
		</CarouselContainer>
	)
}

Carousel.defaultProps = {
	autoRotate: false,
	showButtons: true,
}

export { Carousel }
