import React, { useMemo } from 'react'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Reaptcha from 'reaptcha'
import styled from 'styled-components'
import { Locale } from 'locale-enum'

import { Logo } from '../../assets/Logo'
import {
	AIRTEAM_EMAIL,
	GOOGLE_RECAPTCHA_SITE_KEY,
	JOB_POSTINGS,
} from '../../data'
import { useBreakpoints } from '../../hooks/use-breakpoints'
import { Color, Font, getFont, mediaBreakpoint } from '../../theme'
import { openInNewTab } from '../../utilities/open-in-new-tab'
import { BLOG_PATH } from '../../views/blog/View'
import { CAREER_PATH } from '../../views/career/View'
import { CONTACT_PATH } from '../../views/contact/View'
import { FieldText } from '../fields/Text'
import { FormFeedbackModal } from '../FormFeedbackModal'

import { useNewsletterForm } from './hooks/use-newsletter-form'
import { FooterSocialLinks } from './SocialLinks'
import { REFERRAL_PATH_DE, REFERRAL_PATH_EN } from '../../views/referral'
import { KNOWLEDGE_BASE_URL } from '../../data'

const FooterLinksLeft = styled.div`
	flex: 18;

	> *:not(:first-child) {
		margin-top: 30px;
	}

	@media ${mediaBreakpoint.small} {
		flex: unset;
	}
`

const FooterLinksCenter = styled.div`
	display: flex;
	flex-direction: column;
	flex: 10;
	${getFont(Font.B1_REGULAR)}

	@media ${mediaBreakpoint.small} {
		border-top: 1px solid ${Color.AT_GRAY};
		margin-top: 15px;
		padding-top: 15px;
		flex: unset;
		${getFont(Font.MOBILE_B1_BOLD)}
	}
`

const FooterLinksRight = styled.div`
	display: flex;
	flex-direction: column;
	flex: 14;
	color: ${Color.AT_WHITE_PRIMARY};

	.title {
		${getFont(Font.B0_REGULAR)}
		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B1_BOLD)}
		}
	}

	.email-input {
		width: 75%;

		@media ${mediaBreakpoint.small} {
			margin: auto;
		}

		div input {
			padding: 10px 8px;
			color: ${Color.AT_BLACK_1};
		}

		div input:placeholder {
			color: ${Color.AT_BLACK_1};
			opacity: 0.4;
		}
	}

	.caption {
		color: ${Color.AT_GRAY_4};

		${getFont(Font.CAPTION_BIG_14)}
		@media ${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B3_REGULAR)}
			width: 70%;
			margin: auto;
		}
	}

	.cta {
		cursor: pointer;

		color: ${Color.AT_GREEN_1};

		${getFont(Font.B1_REGULAR)}
		@media
			${mediaBreakpoint.small} {
			${getFont(Font.MOBILE_B1_REGULAR)}
		}
	}

	.g-recaptcha {
		display: flex;
		justify-content: center;
		margin-top: 5px;
	}

	> *:not(:first-child) {
		margin-top: 10px;
	}

	@media ${mediaBreakpoint.small} {
		border-top: 1px solid ${Color.AT_GRAY};
		margin-top: 15px;
		padding-top: 15px;
		flex: unset;

		> *:not(:first-child) {
			margin-top: 15px;
		}
	}
`

const FooterLinksSectionContainer = styled.div`
	display: flex;
	padding: 70px 70px 70px 70px;
	line-height: 1.5;

	@media ${mediaBreakpoint.small} {
		flex-direction: column;
		padding: 30px 0 0 0;
		text-align: center;

		${FooterLinksLeft} {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 0 20px;
		}
	}
`

const FooterSiteLinks = styled.div`
	flex: 1;

	> div {
		display: flex;
		flex-direction: column;

		> div {
			margin: 5px;

			> span {
				cursor: pointer;

				&:active {
					color: ${Color.AT_GREEN_1};
				}

				&:hover:not(:active) {
					color: ${Color.CTA_HOVER};
				}
			}
		}
	}

	@media ${mediaBreakpoint.small} {
		margin-bottom: 20px;
	}
`

const CompanyMission = styled.div`
	color: #1dd69b; /* FIXME: This color does not exist in the theme */
	max-width: 530px;
	${getFont(Font.B1_BOLD)}

	@media ${mediaBreakpoint.small} {
		${getFont(Font.MOBILE_B1_BOLD)}
	}
`

const FooterLinksSection = () => {
	const { t, i18n } = useTranslation()
	const currentLanguage = i18n.language
	const history = useHistory()
	const { isSmall } = useBreakpoints()
	const alert = useAlert()

	const newsletterForm = useNewsletterForm()

	const links = useMemo<Array<{ label: string; onClick(): void }>>(
		() => [
			{
				label: t('footer.links.referralProgram'),
				onClick: () =>
					history.push(
						currentLanguage === Locale.de_DE
							? REFERRAL_PATH_DE
							: REFERRAL_PATH_EN,
					),
			},
			{
				label: t('footer.links.helpSection'),
				onClick: () => (window.location.href = KNOWLEDGE_BASE_URL),
			},
			{
				label: t('footer.links.blog'),
				onClick: () => history.push(BLOG_PATH),
			},
			{
				label: t('footer.links.becomeAPilot'),
				onClick: () => history.push(CAREER_PATH),
			},
			{
				label: t('footer.links.contact'),
				onClick: () => history.push(CONTACT_PATH),
			},
			{
				label: t('footer.links.career'),
				onClick: () => openInNewTab(JOB_POSTINGS),
			},
		],
		[t, history],
	)

	const newsletterSection = (
		<FooterLinksRight>
			<div className="title">{t('footer.newsletter.title')}</div>
			<div className="email-input">
				<FieldText
					placeholder={t('footer.newsletter.emailPlaceholder')}
					setValue={newsletterForm.setEmail}
					value={newsletterForm.email}
				/>
			</div>
			<div className="caption">{t('footer.newsletter.caption')}</div>
			<div className="cta">
				<span
					onClick={() => {
						if (newsletterForm.isFormValid()) {
							if (!newsletterForm.isWaitingOnServer) {
								newsletterForm.setShowGoogleRecaptcha(true)
								alert.info(t('footer.newsletter.solveRecaptcha'))
							}
						} else {
							alert.error(t('footer.newsletter.enterEmail'))
						}
					}}
				>
					{t('footer.newsletter.cta')} &gt;&gt;
				</span>
				{newsletterForm.showGoogleRecaptcha && (
					<Reaptcha
						ref={newsletterForm.reaptchaRef}
						sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
						onVerify={newsletterForm.setGoogleRecaptchaResponse}
					/>
				)}
			</div>
		</FooterLinksRight>
	)

	return (
		<div className="container">
			{newsletterForm.showModal && (
				<FormFeedbackModal
					isLoading={newsletterForm.isWaitingOnServer}
					message={
						newsletterForm.isSignupSuccessful
							? t('footer.newsletter.subscriptionSuccess.message')
							: t('footer.newsletter.subscriptionFailure.message')
					}
					title={
						newsletterForm.isSignupSuccessful
							? t('footer.newsletter.subscriptionSuccess.title')
							: t('footer.newsletter.subscriptionFailure.title')
					}
					onClose={() => newsletterForm.setShowModal(false)}
				/>
			)}
			<FooterLinksSectionContainer>
				<FooterLinksLeft>
					<div>
						<Logo
							color={Color.AT_WHITE_PRIMARY}
							style={{ marginLeft: '-10px', width: '140px' }}
						/>
					</div>
					<div>
						<a href="tel:+493037580830">+49 30 37 580 830</a>
					</div>
					<div>
						<a href={`mailto:${AIRTEAM_EMAIL}`}>{AIRTEAM_EMAIL}</a>
					</div>
					<CompanyMission>{t('footer.companyMission')}</CompanyMission>
				</FooterLinksLeft>
				{isSmall && newsletterSection}
				<FooterLinksCenter>
					{isSmall && <FooterSocialLinks />}
					<FooterSiteLinks>
						<div>
							{links.map((link, index) => (
								<div key={index}>
									<span onClick={() => link.onClick()}>{link.label}</span>
								</div>
							))}
						</div>
					</FooterSiteLinks>
					{!isSmall && <FooterSocialLinks />}
				</FooterLinksCenter>
				{!isSmall && newsletterSection}
			</FooterLinksSectionContainer>
		</div>
	)
}

export { FooterLinksSection }
