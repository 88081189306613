import React from 'react'
import CountUp from 'react-countup'
import { InView } from 'react-intersection-observer'

export type TickerProps = {
	start?: number
	end: number
	duration?: number
	separator?: string
	suffix?: string
	delay?: number
}

const Ticker = ({
	delay,
	duration,
	end,
	separator,
	start,
	suffix,
}: TickerProps) => {
	return (
		<CountUp {...{ delay, duration, end, separator, start, suffix }}>
			{({ countUpRef, start }) => (
				<InView triggerOnce onChange={start}>
					<span ref={countUpRef} />
				</InView>
			)}
		</CountUp>
	)
}
Ticker.defaultProps = {
	delay: 0.3,
	start: 0,
}

export default Ticker
